import React, { useState, useEffect } from 'react';
import { Header } from "../components/header.js";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import "../styles/about.css";
import "../styles/global.css";
import { Colors } from '../styles/colors';
import Fade from 'react-reveal/Fade';

import img1 from "../assets/images/about/1.jpg";
import img2 from "../assets/images/about/2.jpg";
import img3 from "../assets/images/about/3.jpg";

import dylanO from "../assets/images/about/dylanO.jpg";
import emmaM from "../assets/images/about/emmaM.jpg";
import jessR from "../assets/images/about/jessR.jpg";
import breeM from "../assets/images/about/breeM.jpg";
import kateC from "../assets/images/about/kateC.jpg";
import brettB from "../assets/images/about/brettB.jpg";
import emmaMk from "../assets/images/about/emmaMk.jpg";
import sherryG from "../assets/images/about/sherryG.jpg";

const { DEVICE_TYPES } = require('../constants.js');

const Testimonial = (props) => {
    const [isRight, setIsRight] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientImage, setClientImage] = useState("");
    const [testimonial, setTestimonial] = useState("");

    useEffect(() => {
        setClientName(props.clientName);
        setClientImage(props.clientImage);
        setTestimonial(props.clientTestimonial);

        if (props.isRight) {
            setIsRight(true);
        }
    }, [props]);

    return (
        <div style={{ display: "flex", flexDirection: isRight ? 'row-reverse' : 'row' }}>
            <img
                alt={clientName}
                src={clientImage}
                style={{
                    width: "5vw",
                    top: "-2.5vw",
                    height: "5vw",
                    position: 'relative',
                    borderRadius: "100%",
                    left: !isRight ? "-1vw" : '1vw',
                }}
            />

            <MessageBox
                titleColor='white'
                text={testimonial}
                title={clientName}
                position={isRight ? "right" : "left"}
            />
        </div>
    )
};

const FadeWrapper = (props) => {
    const [isRight, setIsRight] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientImage, setClientImage] = useState("");
    const [testimonial, setTestimonial] = useState("");
    const [fadeDirection, setFadeDirection] = useState('Left');

    useEffect(() => {
        setClientName(props.clientName);
        setClientImage(props.clientImage);
        setTestimonial(props.clientTestimonial);

        if (props.isRight) {
            setIsRight(true);
            setFadeDirection('Right');
        }
    }, [props]);

    if (fadeDirection === 'Left') {
        return (
            <div style={{ width: "80%", marginBottom: "2vw" }}>
                <Fade left>
                    <Testimonial
                        isRight={isRight}
                        clientName={clientName}
                        clientImage={clientImage}
                        clientTestimonial={testimonial}
                    />
                </Fade>
            </div >
        )
    } else {
        return (
            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                <Fade right >
                    <Testimonial
                        isRight={isRight}
                        clientName={clientName}
                        clientImage={clientImage}
                        clientTestimonial={testimonial}
                    />
                </Fade>
            </div>
        )
    }
};

const About = (props) => {
    const pink = "#d64cA8";
    const [t1Size, setT1Size] = useState("5vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [pSize, setPSize] = useState("1.25vw");
    const [topOffset, setTopOffset] = useState("20vh");
    const [block1Size, setBlock1Size] = useState("50vh");
    const [block2Size, setBlock2Size] = useState("30vh");


    useEffect(() => {
        if (props.deviceType === DEVICE_TYPES.tablet) {
            setT1Size("8vw");
            setT2Size("4vw");
            setPSize("2.5vw");
            setTopOffset("5vh")
        } else if (props.deviceType === DEVICE_TYPES.mobile) {
            setT1Size("10vw");
            setT2Size("5vw");
            setPSize("3vw");
            setTopOffset("5vh")
        }
    }, [props.deviceType]);

    if (props.deviceType === "desktop") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                }}
            >

                <div
                    style={{
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.offwhite,
                        backgroundColor: Colors.darkerOffBlack,
                        fontSize: pSize,
                        fontFamily: "bebas",
                    }}
                >
                    <div
                        style={{
                            width: "70vw",
                            marginTop: topOffset,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Fade left>
                                    <h1 style={{ fontWeight: 200, marginTop: 0, marginBottom: 0, fontSize: t1Size }}>Who are we?</h1>
                                </Fade>

                                <Fade left>
                                    <h3 style={{ fontWeight: 200, marginBottom: 0, fontSize: t2Size }}>Trainers Who Care</h3>
                                </Fade>

                                <Fade left>
                                    <p style={{ fontFamily: "RobotoSlab" }}>
                                        Everybody starts their fitness journey somewhere. Yours starts here. Our coaches use their knowledge, experience and skills to help you bridge the gap between where you are now and where you want to be. Our Boss coaches help you set goals that align with your values, desires and priorities- in fitness, nutrition and general health. If you are ready to take the first step in your fitness journey, create an account and book online today.
                                    </p>
                                </Fade>

                                <Fade left>
                                    <div
                                        className={"pinkSquare"}
                                        style={{
                                            alignSelf: "center",
                                            width: "20vw",
                                            height: "20vw",
                                            marginTop: "3vw",
                                            backgroundColor: pink,
                                        }}
                                    >
                                        <img
                                            src={img2}
                                            style={{
                                                position: "relative",
                                                top: "0.75vw",
                                                left: "0.75vw",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                </Fade>

                                <Fade left>
                                    <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: "5vw", fontSize: t2Size }}>THE RIGHT TOOLS FOR THE JOB</h3>
                                </Fade>

                                <Fade left>
                                    <p style={{ fontFamily: "RobotoSlab" }}>
                                        Our trainers take your goals seriously. We understand that losing weight and gaining muscle can be challenging. our aim is to help you overcome hurdles to reach your goals. we analyse your body type, BMI and metabolism to create a personal fitness plan.
                                    </p>
                                </Fade>


                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "right"
                                }}
                            >
                                <Fade right>
                                    <div
                                        className={"pinkSquare"}
                                        style={{
                                            alignSelf: "center",
                                            width: "20vw",
                                            height: "20vw",
                                            marginTop: "8vw",
                                            backgroundColor: pink,
                                        }}
                                    >
                                        <img
                                            src={img1}
                                            style={{
                                                position: "relative",
                                                top: "0.75vw",
                                                left: "0.75vw",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                </Fade>

                                <Fade right>
                                    <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: "7.5vw", fontSize: t2Size }}>YOUR SUCCESS IS OUR GOAL</h3>
                                </Fade>

                                <Fade right>
                                    <p
                                        style={{
                                            fontFamily: "RobotoSlab",
                                        }}
                                    >
                                        We understand that adapting new fitness habits is difficult and can often fail. Our goal is to change your view on fitness so it becomes a part of your lifestyle and who you are. We strongly believe that if you practise healthy habits, you will be able to obtain a healthy lifestyle.
                                    </p>
                                </Fade>

                                <Fade right>
                                    <div
                                        className={"pinkSquare"}
                                        style={{
                                            alignSelf: "center",
                                            width: "20vw",
                                            height: "20vw",
                                            marginTop: "3.5vw",
                                            backgroundColor: pink,
                                        }}
                                    >
                                        <img
                                            src={img3}
                                            style={{
                                                position: "relative",
                                                top: "0.75vw",
                                                left: "0.75vw",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                        </div>

                        <Fade left>
                            <h1 style={{ fontWeight: 200, marginBottom: 0, fontSize: t1Size }}>Testimonials</h1>
                        </Fade>

                        <div
                            style={{
                                color: "white",
                                fontFamily: "RobotoSlab",
                                display: "flex",
                                width: "95%",
                                alignSelf: "center",
                                marginTop: "5vh",
                                flexDirection: "column"
                            }}
                        >

                            <FadeWrapper
                                clientName="Katelyn C"
                                clientImage={kateC}
                                clientTestimonial="Kate is one of the best trainers I have ever worked with. She is always so positive and so encouraging. She makes me feel very comfortable, especially with exercises that I struggle with. She is always correcting my technique which I love but goes about it in a way that doesn’t make you feel discouraged or embarrassed. Also she is very affordable!"
                            />

                            <FadeWrapper
                                isRight
                                clientName="Emma M"
                                clientImage={emmaM}
                                clientTestimonial="Kate is a fantastic trainer who takes the time to work with you. I started PT 3 months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but will not let you slack off during your sessions either. I’d recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does."
                            />

                            <FadeWrapper
                                clientName="Jessica R"
                                clientImage={jessR}
                                clientTestimonial="Kate knows her stuff! She is always so supportive of your goals and pushes you to reach them. Kate challenges you to transform your mind and body, and provides professional guidance every step of the way. Boss Squad feels like family! Everyone is a part of the Squad for their own reasons and everyone is so supportive of your goals and progress. Group sessions are super fun and a great way to build bonds with the other members."
                            />

                            <FadeWrapper
                                isRight
                                clientName="Dylan O"
                                clientImage={dylanO}
                                clientTestimonial="Two years ago I started my fitness journey. After a couple of weeks I felt I was still lacking in the effort I was putting in to my goal. So I enlisted the help of Boss Squad Training, not only has she helped inspire me to reach my goals by being supportive, one on one training that made me feel like I have accomplished all I could do in a day, holding me accountable for the days I was less motivated, teaching me the correct forms and eating habits AND a custom tailor made work out program which is easy to follow, explains proper form and anything I dont understand or dont have access to, she explains or changes up to suit the gym. She also became a mentor, someone to trust and a friend. I am proud to continue my work with Boss Squad Training and highly recommend anyone who feels they need support or guidance to check out the best squad there is."
                            />

                            <FadeWrapper
                                clientName="Bree M"
                                clientImage={breeM}
                                clientTestimonial="Kate is an amazing trainer! She is so knowledgeable and always makes sure you are doing 
                                    the exercises correctly. She is so motivating and always makes sure you are pushing yourself to your 
                                    full potential. She is so supportive and always makes sure you are having fun. I would highly 
                                    recommend Kate to anyone looking for a trainer!"
                            />

                            <FadeWrapper
                                isRight
                                clientName="Brett B"
                                clientImage={brettB}
                                clientTestimonial="Boss Squad Training is just that!! If you want to own your goals, be the BOSS of your health and fitness and be part of a squad of equally strong-willed people who want to be their best, Having Boss Squad Training is exactly what you need.
                                Before starting with Boss Squad Training I had zero motivation and constantly struggled to see any progress.
                                As a shift worker that has no sleeping pattern,  Kate has been beyond a dream to work with and the progress made encourages me to not only hit my goals, but set new ones.
                                The personalised workout program, the one on one training in a private studio and group events that you can join in on makes training fun, but also motivating.
                                If you want to be BOSS you know what to do!"
                            />

                            <FadeWrapper
                                clientName="Emma MK"
                                clientImage={emmaMk}
                                clientTestimonial="Kate is a fantastic trainer who takes the time to work with you. I started personal Training three months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but she will not let you slack off during your sessions either. I'd recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does. The gym is always tidy and very well presented and Kate has great times available for sessions."
                            />


                            <FadeWrapper
                                isRight
                                clientName="Sherry G"
                                clientImage={sherryG}
                                clientTestimonial="I started my weight loss journey purely through food, I began attempting to go to gym by myself but had no clue what to do. Since Kate started training me I've gotten a much better understanding of exercise and my own body. My total weight loss has been amazing but working with Kate has helped me build muscle and increase strength which in turn has made me feel so much more comfortable and confident. At first I would only wear thin jumpers or long sleeved shirts because I didn't like seeing my body in the mirror while exercising, but now I go and see Kate in whatever she feels comfortable. Kate has helped me feel like I can do whatever I set my mind too at gym and given me the skills to do so!"
                            />
                        </div>

                    </div>
                </div>


            </div >
        )
    } else if (props.deviceType === 'mobile') {

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100vw",
                }}
            >

                <div
                    style={{
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.offwhite,
                        backgroundColor: Colors.darkerOffBlack,
                        fontSize: pSize,
                        fontFamily: "bebas",
                    }}
                >
                    <div
                        style={{
                            width: "70vw",
                            marginTop: topOffset,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Fade left>
                                    <h1 style={{ fontWeight: 200, marginTop: 0, marginBottom: 0, fontSize: t1Size }}>Who are we?</h1>
                                </Fade>


                                <div
                                    style={{
                                        height: block1Size,
                                    }}
                                >
                                    <Fade left>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, fontSize: t2Size }}>Trainers Who Care</h3>
                                    </Fade>

                                    <Fade left>
                                        <p style={{ fontFamily: "RobotoSlab" }}>
                                            Everybody starts their fitness journey somewhere. Yours starts here. Our coaches use their knowledge, experience and skills to help you bridge the gap between where you are now and where you want to be. Our Boss coaches help you set goals that align with your values, desires and priorities- in fitness, nutrition and general health. If you are ready to take the first step in your fitness journey, create an account and book online today.
                                        </p>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        height: block2Size,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Fade left>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",
                                                height: "30vw",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img2}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div>
                                    <Fade left>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: "5vw", fontSize: t2Size }}>THE RIGHT TOOLS FOR THE JOB</h3>
                                    </Fade>

                                    <Fade left>
                                        <p style={{ fontFamily: "RobotoSlab" }}>
                                            Our trainers take your goals seriously. We understand that losing weight and gaining muscle can be challenging. our aim is to help you overcome hurdles to reach your goals. we analyse your body type, BMI and metabolism to create a personal fitness plan.
                                        </p>
                                    </Fade>
                                </div>

                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "right",
                                }}
                            >
                                <div
                                    style={{
                                        height: block1Size,
                                        marginTop: "10vh",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Fade right>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",
                                                height: "30vw",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img1}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        height: block2Size,
                                    }}
                                >
                                    <Fade right>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: 0, fontSize: t2Size }}>YOUR SUCCESS IS OUR GOAL</h3>
                                    </Fade>

                                    <Fade right>
                                        <p
                                            style={{
                                                fontFamily: "RobotoSlab",
                                            }}
                                        >
                                            We understand that adapting new fitness habits is difficult and can often fail. Our goal is to change your view on fitness so it becomes a part of your lifestyle and who you are. We strongly believe that if you practise healthy habits, you will be able to obtain a healthy lifestyle.
                                        </p>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        height: block2Size
                                    }}
                                >
                                    <Fade right>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",

                                                height: "30vw",
                                                marginTop: "10vh",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img3}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>
                            </div>

                        </div>

                        <Fade left>
                            <h1 style={{ fontWeight: 200, marginBottom: 0, fontSize: t1Size }}>Testimonials</h1>
                        </Fade>

                        <div
                            style={{
                                color: "white",
                                fontFamily: "RobotoSlab",
                                display: "flex",
                                width: "120%",
                                alignSelf: "center",
                                marginTop: "5vh",
                                flexDirection: "column",
                                fontSize: '5vw',
                            }}
                        >
                            <div style={{ width: "80%", marginBottom: "2vw" }}>


                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                            width: '100%',
                                            background: '#d64cA8',
                                            borderRadius: '5vw'
                                        }}
                                    >
                                        <img
                                            src={kateC}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />

                                        <div>
                                            <p>Katelyn C</p>
                                            <p style={{
                                                fontSize: '3vw'
                                            }}>Kate is one of the best trainers I have ever worked with. She is always so positive and so encouraging. She makes me feel very comfortable, especially with exercises that I struggle with. She is always correcting my technique which I love but goes about it in a way that doesn’t make you feel discouraged or embarrassed. Also she is very affordable!</p>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',

                                        }}
                                    >
                                        <div style={{ paddingLeft: '3vw' }}>
                                            <p>Emma M</p>
                                            <p style={{
                                                fontSize: '3vw'
                                            }}>Kate is a fantastic trainer who takes the time to work with you. I started PT 3 months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but will not let you slack off during your sessions either. I’d recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does.</p>
                                        </div>
                                        <img
                                            src={emmaM}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',
                                        }}
                                    >
                                        <img
                                            src={jessR}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <div>
                                            <p>Jessica R</p>
                                            <p style={{
                                                fontSize: '3vw'
                                            }}>Kate knows her stuff! She is always so supportive of your goals and pushes you to reach them. Kate challenges you to transform your mind and body, and provides professional guidance every step of the way. Boss Squad feels like family! Everyone is a part of the Squad for their own reasons and everyone is so supportive of your goals and progress. Group sessions are super fun and a great way to build bonds with the other members.</p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',
                                        }}
                                    >
                                        <div style={{ paddingLeft: '3vw' }}>
                                            <p>Dylan O</p>
                                            <p style={{
                                                fontSize: '3vw'
                                            }}>Two years ago I started my fitness journey. After a couple of weeks I felt I was still lacking in the effort I was putting in to my goal. So I enlisted the help of Boss Squad Training, not only has she helped inspire me to reach my goals by being supportive, one on one training that made me feel like I have accomplished all I could do in a day, holding me accountable for the days I was less motivated, teaching me the correct forms and eating habits AND a custom tailor made work out program which is easy to follow, explains proper form and anything I dont understand or dont have access to, she explains or changes up to suit the gym. She also became a mentor, someone to trust and a friend. I am proud to continue my work with Boss Squad Training and highly recommend anyone who feels they need support or guidance to check out the best squad there is.</p>
                                        </div>
                                        <img
                                            src={dylanO}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',
                                        }}
                                    >
                                        <img
                                            src={jessR}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <div>
                                            <p>Bree M</p>
                                            <p style={{
                                                fontSize: '3vw'
                                            }}>Kate’s passion to helping others work towards and achieve their fitness and wellness goals is outstanding. When you train with Boss Squad Training you don’t just get a PT, you become part of a community, you learn for yourself so you can become knowledgeable and you receive the support you didn’t realise was possible through personal fitness.</p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',
                                        }}
                                    >
                                        <div style={{ paddingLeft: '3vw' }}>
                                            <p>Brett B</p>
                                            <p style={{ fontSize: '3vw' }}> Boss Squad Training is just that!! If you want to own your goals, be the BOSS of your health and fitness and be part of a squad of equally strong-willed people who want to be their best, Having Boss Squad Training is exactly what you need.
                                                Before starting with Boss Squad Training I had zero motivation and constantly struggled to see any progress.
                                                As a shift worker that has no sleeping pattern,  Kate has been beyond a dream to work with and the progress made encourages me to not only hit my goals, but set new ones.
                                                The personalised workout program, the one on one training in a private studio and group events that you can join in on makes training fun, but also motivating.
                                                If you want to be BOSS you know what to do!</p>
                                        </div>
                                        <img
                                            src={brettB}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                            background: '#d64cA8',
                                            borderRadius: '5vw',
                                        }}
                                    >
                                        <img
                                            src={emmaMk}
                                            style={{
                                                width: "10vh",
                                                height: "10vh",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <div>
                                            <p>Emma MK</p>
                                            <p style={{ fontSize: '3vw' }}>Kate is a fantastic trainer who takes the time to work with you. I started personal Training three months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but she will not let you slack off during your sessions either. I'd recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does. The gym is always tidy and very well presented and Kate has great times available for sessions.</p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>

                        <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                            <Fade right>
                                <div
                                    style={{
                                        display: "flex",
                                        background: '#d64cA8',
                                        borderRadius: '5vw',
                                        fontFamily:'robotoSlab'
                                    }}
                                >
                                    <div style={{ paddingLeft: '3vw' }}>
                                        <p>Sherry G</p>
                                        <p style={{ fontSize: '3vw' }}>I started my weight loss journey purely through food, I began attempting to go to gym by myself but had no clue what to do. Since Kate started training me I've gotten a much better understanding of exercise and my own body. My total weight loss has been amazing but working with Kate has helped me build muscle and increase strength which in turn has made me feel so much more comfortable and confident. At first I would only wear thin jumpers or long sleeved shirts because I didn't like seeing my body in the mirror while exercising, but now I go and see Kate in whatever she feels comfortable. Kate has helped me feel like I can do whatever I set my mind too at gym and given me the skills to do so!</p>
                                    </div>
                                    <img
                                        src={sherryG}
                                        style={{
                                            width: "10vh",
                                            height: "10vh",
                                            borderRadius: "100%",
                                            position: 'relative',
                                            top: "-2.5vw",
                                            left: "1vw"
                                        }}
                                    />
                                </div>
                            </Fade>
                        </div>



                    </div>
                </div>
            </div >
        );
    } else {

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100vw",
                }}
            >

                <div
                    style={{
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.offwhite,
                        backgroundColor: Colors.darkerOffBlack,
                        fontSize: pSize,
                        fontFamily: "bebas",
                    }}
                >
                    <div
                        style={{
                            width: "70vw",
                            marginTop: topOffset,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Fade left>
                                    <h1 style={{ fontWeight: 200, marginTop: 0, marginBottom: 0, fontSize: t1Size }}>Who are we?</h1>
                                </Fade>


                                <div
                                    style={{
                                        height: block1Size,
                                    }}
                                >
                                    <Fade left>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, fontSize: t2Size }}>Trainers Who Care</h3>
                                    </Fade>

                                    <Fade left>
                                        <p style={{ fontFamily: "RobotoSlab" }}>
                                            Everybody starts their fitness journey somewhere. Yours starts here. Our coaches use their knowledge, experience and skills to help you bridge the gap between where you are now and where you want to be. Our Boss coaches help you set goals that align with your values, desires and priorities- in fitness, nutrition and general health. If you are ready to take the first step in your fitness journey, create an account and book online today.
                                        </p>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        height: block2Size,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Fade left>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",
                                                height: "30vw",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img2}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div>
                                    <Fade left>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: "5vw", fontSize: t2Size }}>THE RIGHT TOOLS FOR THE JOB</h3>
                                    </Fade>

                                    <Fade left>
                                        <p style={{ fontFamily: "RobotoSlab" }}>
                                            Our trainers take your goals seriously. We understand that losing weight and gaining muscle can be challenging. our aim is to help you overcome hurdles to reach your goals. we analyse your body type, BMI and metabolism to create a personal fitness plan.
                                        </p>
                                    </Fade>
                                </div>

                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "right",
                                }}
                            >
                                <div
                                    style={{
                                        height: block1Size,
                                        marginTop: "10vh",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Fade right>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",
                                                height: "30vw",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img1}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        height: block2Size,
                                    }}
                                >
                                    <Fade right>
                                        <h3 style={{ fontWeight: 200, marginBottom: 0, marginTop: 0, fontSize: t2Size }}>YOUR SUCCESS IS OUR GOAL</h3>
                                    </Fade>

                                    <Fade right>
                                        <p
                                            style={{
                                                fontFamily: "RobotoSlab",
                                            }}
                                        >
                                            We understand that adapting new fitness habits is difficult and can often fail. Our goal is to change your view on fitness so it becomes a part of your lifestyle and who you are. We strongly believe that if you practise healthy habits, you will be able to obtain a healthy lifestyle.
                                        </p>
                                    </Fade>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        height: block2Size
                                    }}
                                >
                                    <Fade right>
                                        <div
                                            className={"pinkSquare"}
                                            style={{
                                                alignSelf: "center",
                                                width: "30vw",

                                                height: "30vw",
                                                marginTop: "10vh",
                                                backgroundColor: pink,
                                            }}
                                        >
                                            <img
                                                src={img3}
                                                style={{
                                                    position: "relative",
                                                    top: "0.75vw",
                                                    left: "0.75vw",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                </div>
                            </div>

                        </div>

                        <Fade left>
                            <h1 style={{ fontWeight: 200, marginBottom: 0, fontSize: t1Size }}>Testimonials</h1>
                        </Fade>

                        <div
                            style={{
                                color: "white",
                                fontFamily: "RobotoSlab",
                                display: "flex",
                                width: "95%",
                                alignSelf: "center",
                                marginTop: "5vh",
                                flexDirection: "column",
                                fontSize: '5vw',
                            }}
                        >
                            <div style={{ width: "80%", marginBottom: "2vw" }}>


                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={kateC}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <MessageBox
                                            position={'left'}
                                            title={"Katelyn C"}
                                            titleColor={"white"}
                                            text={"Kate is one of the best trainers I have ever worked with. She is always so positive and so encouraging. She makes me feel very comfortable, especially with exercises that I struggle with. She is always correcting my technique which I love but goes about it in a way that doesn’t make you feel discouraged or embarrassed. Also she is very affordable!"}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <MessageBox
                                            position={'right'}
                                            title={"Emma M"}
                                            titleColor={"white"}
                                            text={"Kate is a fantastic trainer who takes the time to work with you. I started PT 3 months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but will not let you slack off during your sessions either. I’d recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does."}
                                        />
                                        <img
                                            src={emmaM}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={jessR}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <MessageBox
                                            position={'left'}
                                            title={"Jessica R"}
                                            titleColor={"white"}
                                            text={"Kate knows her stuff! She is always so supportive of your goals and pushes you to reach them. Kate challenges you to transform your mind and body, and provides professional guidance every step of the way. Boss Squad feels like family! Everyone is a part of the Squad for their own reasons and everyone is so supportive of your goals and progress. Group sessions are super fun and a great way to build bonds with the other members."}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <MessageBox
                                            position={'right'}
                                            title={"Dylan O"}
                                            titleColor={"white"}
                                            text={"Two years ago I started my fitness journey. After a couple of weeks I felt I was still lacking in the effort I was putting in to my goal. So I enlisted the help of Boss Squad Training, not only has she helped inspire me to reach my goals by being supportive, one on one training that made me feel like I have accomplished all I could do in a day, holding me accountable for the days I was less motivated, teaching me the correct forms and eating habits AND a custom tailor made work out program which is easy to follow, explains proper form and anything I dont understand or dont have access to, she explains or changes up to suit the gym. She also became a mentor, someone to trust and a friend. I am proud to continue my work with Boss Squad Training and highly recommend anyone who feels they need support or guidance to check out the best squad there is."}
                                        />
                                        <img
                                            src={dylanO}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={jessR}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <MessageBox
                                            position={'left'}
                                            title={"Bree M"}
                                            titleColor={"white"}
                                            text={"Kate’s passion to helping others work towards and achieve their fitness and wellness goals is outstanding. When you train with Boss Squad Training you don’t just get a PT, you become part of a community, you learn for yourself so you can become knowledgeable and you receive the support you didn’t realise was possible through personal fitness."}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <MessageBox
                                            position={'right'}
                                            title={"Brett B"}
                                            titleColor={"white"}
                                            text={`Boss Squad Training is just that!! If you want to own your goals, be the BOSS of your health and fitness and be part of a squad of equally strong-willed people who want to be their best, Having Boss Squad Training is exactly what you need.
                                            Before starting with Boss Squad Training I had zero motivation and constantly struggled to see any progress.
                                            As a shift worker that has no sleeping pattern,  Kate has been beyond a dream to work with and the progress made encourages me to not only hit my goals, but set new ones.
                                            The personalised workout program, the one on one training in a private studio and group events that you can join in on makes training fun, but also motivating.
                                            If you want to be BOSS you know what to do!`}
                                        />
                                        <img
                                            src={brettB}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw" }}>
                                <Fade left>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={emmaMk}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "-1vw"
                                            }}
                                        />
                                        <MessageBox
                                            position={'left'}
                                            title={"Emma MK"}
                                            titleColor={"white"}
                                            text={"Kate is a fantastic trainer who takes the time to work with you. I started personal Training three months after giving birth to my second baby and Kate worked with me to get my body moving again in a gentle way. Kate is encouraging and motivating while always being there for you. Kate is kind and gentle but she will not let you slack off during your sessions either. I'd recommend Kate to anyone looking for a trainer that listens to you and takes the time to work with you teaching you the correct way from the start. Kate is highly knowledgeable and amazing at what she does. The gym is always tidy and very well presented and Kate has great times available for sessions."}
                                        />
                                    </div>
                                </Fade>
                            </div>

                            <div style={{ width: "80%", marginBottom: "2vw", alignSelf: "flex-end" }}>
                                <Fade right>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <MessageBox
                                            position={'right'}
                                            title={"Sherry G"}
                                            titleColor={"white"}
                                            text={`I started my weight loss journey purely through food, I began attempting to go to gym by myself but had no clue what to do. Since Kate started training me I've gotten a much better understanding of exercise and my own body. My total weight loss has been amazing but working with Kate has helped me build muscle and increase strength which in turn has made me feel so much more comfortable and confident. At first I would only wear thin jumpers or long sleeved shirts because I didn't like seeing my body in the mirror while exercising, but now I go and see Kate in whatever she feels comfortable. Kate has helped me feel like I can do whatever I set my mind too at gym and given me the skills to do so!`}
                                        />
                                        <img
                                            src={sherryG}
                                            style={{
                                                width: "5vw",
                                                height: "5vw",
                                                borderRadius: "100%",
                                                position: 'relative',
                                                top: "-2.5vw",
                                                left: "1vw"
                                            }}
                                        />
                                    </div>
                                </Fade>
                            </div>

                        </div>

                    </div>
                </div>
            </div >
        );
    }
}

export default About;