import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ReactPlayer from 'react-player/lazy';
import vidThumb from "../../assets/images/bubAndMeThumb.PNG";

import { Colors } from '../../styles/colors.js'
import apiRequest from '../../utils/apiRequest.js';

const { DEVICE_TYPES } = require('../../constants.js');

const paypalOptions = {
	"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
	currency: "AUD",
	vault: false
};

const AppPage = (props) => {
	const [infoText, setInfoText] = useState('');

	const [submitted, setSubmitted] = useState(false);
	const [confirmed, setConfirmed] = useState(false);

	const [textSize, setTextSize] = useState('1.125vw');
	const [titleSize, setTitleSize] = useState('4vw');
	const [titleSize2, setTitleSize2] = useState('2vw');

	const [topOffset, setTopOffset] = useState('100px');
	const [sidePadding, setSidePadding] = useState('10vw');

	const submit = async () => {
		try {
			const email = document.getElementById('email').value;
			const firstName = document.getElementById('firstName').value;
			const lastName = document.getElementById('lastName').value;

			const data = {
				email,
				firstName,
				lastName,
			};

			setSubmitted(true);

			await apiRequest('mailchimp/bubAndMe', data);

			setConfirmed(true);
			setInfoText("Details registered successfully, we'll be in touch soon!");
		} catch (error) {
			setInfoText('We\'ve received your payment but encountered an error saving your details. Please contact us at info@bosssquadtraining.com so we can fix this for you.');
		}

		setSubmitted(false);
	};

	const createOrder = (data, actions) => {
		try {
			const email = document.getElementById('email').value;
			const firstName = document.getElementById('firstName').value;
			const lastName = document.getElementById('lastName').value;

			if (!email || !firstName || !lastName) {
				setInfoText("Please fill all fields before attempting to pay.");

				return false;
			}

			setInfoText("");
			setSubmitted(true);

			return actions.order.create({
				purchase_units: [
					{
						description: "Bub & Me 2023 Challenge",
						amount: {
							value: 330,
							currency_code: "AUD"
						}
					}
				]
			});
		}
		catch (error) {
			return error;
		}
	};

	const onApproveOrder = (data, actions) => {
		actions.order.capture().then(submit);
	}

	useEffect(() => {
		switch (props.deviceType) {
			case DEVICE_TYPES.tablet:
				setTextSize('3vw');
				setTitleSize('12vw');
				setTitleSize2('5vw');
				break;
			case DEVICE_TYPES.mobile:
				setTextSize('4vw');
				setTitleSize('15vw');
				setTitleSize2('5vw');
				break;
			default:
				break;
		}
	}, [props.deviceType]);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				fontSize: textSize,
				paddingTop: topOffset,
				paddingLeft: sidePadding,
				paddingRight: sidePadding,
				color: Colors.offwhite,
				background: Colors.offBlack,
			}}
		>
			<h1 style={{ fontSize: titleSize, fontFamily: 'bebas' }}>Bub & Me in 2023</h1>

			<ReactPlayer
				playing={true}
				controls={true}
				light={vidThumb}
				fallback={vidThumb}
				style={{ color: Colors.offBlack }}
				url='https://www.youtube.com/watch?v=eKUpIoG6EQ8'
				width={props.deviceType === "desktop" ? "40vw" : "100%"}
				height={props.deviceType === "desktop" ? "22.5vw" : "33vw"}
			/>

			<p style={{ textAlign: 'center' }}>
				During my pregnancy and in bub's early months of life I personally struggled without a strong support network. I believe it is such an important aspect
				of any journey pre & post-natal and so I am running a brand new challenge for mums in 2023! I am excited to share all the knowledge and experience I
				have gained over my 5 years in business to give all mums, new and experienced, the chance to love themselves in our wellness program.
			</p>

			<p style={{ textAlign: 'center' }}>
				Our program runs for 10 weeks starting from Feburary 18th through to May 13th, with a two week break over Easter. Additional guidance is available for up to twelve weeks!
			</p>

			<p style={{ textAlign: 'center' }}>
				Jump on board TODAY to access our whole program for only <span style={{ fontWeight: 'bold' }}>$330</span>
			</p>

			<br />

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginTop: props.deviceType === 'mobile' ? 30 : 0,
				}}
			>

				<input
					required
					autoFocus
					id='firstName'
					placeholder={' First Name'}
					style={{
						alignSelf: 'center',
						height: '3vh',
						marginBottom: '2vh',
						fontSize: '2vh',
						border: 'none',
						borderRadius: '10px',
						backgroundColor: 'rgba(255, 255, 255, 0.55)',
					}}
				/>

				<input
					required
					autoFocus
					id='lastName'
					placeholder={' Last Name'}
					style={{
						height: '3vh',
						marginBottom: '2vh',
						alignSelf: 'center',
						fontSize: '2vh',
						border: 'none',
						borderRadius: '10px',
						backgroundColor: 'rgba(255, 255, 255, 0.55)',
					}}
				/>

				<input
					required
					autoFocus
					id='email'
					placeholder={' Email'}
					style={{
						marginBottom: '2vh',
						height: '3vh',
						fontSize: '2vh',
						alignSelf: 'center',
						border: 'none',
						borderRadius: '10px',
						backgroundColor: 'rgba(255, 255, 255, 0.55)',
					}}
				/>

				<br />

				<label
					style={{
						textAlign: 'center',
						marginBottom: '2.5vh',
						color: confirmed ? Colors.green : Colors.orange,
					}}
				>{infoText}</label>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>

					<PayPalScriptProvider options={paypalOptions}>
						<PayPalButtons
							createOrder={(data, actions) => createOrder(data, actions)}
							onApprove={(data, actions) => onApproveOrder(data, actions)}
							style={{
								color: 'white',
								width: "100%",
								tagline: false
							}}
						/>
					</PayPalScriptProvider>

					<Loader
						color={Colors.pink}
						width={"2.5vw"}
						height={"2.5vw"}
						type="TailSpin"
						style={{
							left: '53vw',
							position: 'absolute',
							display: submitted ? "inline-block" : "none",
						}}
					/>

				</div>
			</div>
		</div>
	);
}

export default AppPage;