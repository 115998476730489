import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Colors } from '../../styles/colors';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../../styles/resetPW.css";

const ResetPassword = (props) => {


    const [errorMessage, setErrorMessage] = useState("");
    const [errorColor, setErrorColor] = useState("red");
    const [error2Message, setError2Message] = useState("");
    const [error3Message, setError3Message] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [showLoader, setShowLoader] = useState("none");
    const [s1, sets1] = useState(true);
    const [s1enabled, sets1enabled] = useState(false);
    const [s2enabled, sets2enabled] = useState(false);
    const [s3enabled, sets3enabled] = useState(false);
    const [pw, setPW] = useState("");

    const [t1Size, setT1Size] = useState("5vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [pSize, setPSize] = useState("1.25vw");

    useEffect(() => {

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setT1Size("12vw");
            setT2Size("5vw");
            setPSize("3vw")
        }

    }, [props.deviceType]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }
    const handlePWChange = (e) => {
        setPW(e.target.value);
    }
    const SubmitEmail = async () => {

        setShowLoader("block");

        if (email === null || email === "") {
            setErrorMessage("Field cannot be blank")
            setErrorColor("Red")
            return;
        }
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/resetpassword/email", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => {
                if (response.status === 200) {
                    sets1enabled(true)
                    sets1(false)
                    setErrorMessage("");
                }
                else {
                    setErrorMessage("Invalid email");
                }
                setShowLoader("none");
            })
            .catch((error) => {
                console.error(error.message);
                setShowLoader("none");
            });

    }
    const SubmitCode = async () => {

        setShowLoader("block");

        if (code === null || code === "") {
            return;
        }
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/resetpassword/code", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "code": code
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (response) => {
                let json = await response.json();
                if (response.status === 200) {
                    sets2enabled(true)
                    setError2Message("")
                }
                else {
                    console.log(json.message)
                    setError2Message(json.message)
                }
                setShowLoader("none");
            })
            .catch((error) => {
                console.error(error.message);
                setError2Message(error.message)
                setShowLoader("none");
            });

    }
    const SubmitPW = async () => {

        if (code === null || code === "") {
            return;
        }
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/resetpassword/pw", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "password": pw
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setError3Message(`Password updated successfully. Return to`)
                    sets3enabled(true);
                }
                else {
                    setError3Message("Something went wrong. Our bad. isnt that embarrassing.")
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }

    return (
        <div
            style={{
                width: "100vw",
                minHeight: "93vh",
                backgroundColor: Colors.offBlack,
                color: Colors.offwhite,
                display: "flex",
                fontFamily: "RobotoSlab",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    width: props.deviceType === "desktop" ? "20vw" : "67vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >

                <h1
                    style={{
                        fontWeight: 200,
                        fontSize: t1Size,
                        fontFamily: "bebas",
                        textAlign:"center"
                    }}
                >
                    Reset Password
                </h1>

                <label
                    style={{
                        alignSelf: "start",
                        fontSize: pSize,
                    }}
                >
                    Enter your email
                </label>

                <input
                    required
                    autoFocus
                    value={email}
                    placeholder={"Email"}
                    onChange={handleEmailChange}
                    style={{
                        width: "100%",
                        height: "3vh",
                        fontSize: "2vh",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.55)",
                        marginTop: "1vh",
                        marginBottom: "1vh"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <button
                        disabled={s1enabled}
                        onClick={SubmitEmail}
                        style={{
                            alignSelf: "start",
                            fontSize: pSize,
                        }}
                    >
                        Confirm Email
                    </button>

                    <label
                        style={{
                            fontSize: pSize,
                            alignSelf: "center",
                            textAlign: "end"
                        }}
                    >
                        {errorMessage}
                    </label>
                </div>

                <div
                    className={s1 ? "fadeOut" : "fadeIn"}
                    style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "5vh",
                        flexDirection: "column",
                    }}
                >
                    <label
                        style={{
                            alignSelf: "start",
                            fontSize: pSize
                        }}
                    >
                        Enter the code sent to your email
                    </label>
                    <input
                        disabled={s2enabled}
                        value={code}
                        onChange={handleCodeChange}
                        onSubmit={SubmitCode}
                        label="Code"
                        type="text"
                        required
                        placeholder={"Code"}
                        style={{
                            width: "100%",
                            height: "3vh",
                            fontSize: "2vh",
                            border: "none",
                            borderRadius: "10px",
                            backgroundColor: "rgba(255, 255, 255, 0.55)",
                            marginTop: "1vh",
                            marginBottom: "1vh"
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <button
                            disabled={s2enabled}
                            onClick={SubmitCode}
                            style={{
                                alignSelf: "flex-start",
                                fontSize: pSize
                            }}
                        >
                            Submit
                        </button>
                        <Loader
                            type="TailSpin"
                            color={Colors.pink}
                            width="26"
                            height="26"
                            style={{
                                alignSelf: 'center',
                                display: showLoader
                            }}
                        />
                        <label
                            style={{
                                fontSize: "0.75vw",
                                alignSelf: "center",
                                fontSize: pSize,
                                textAlign: "end"
                            }}
                        >
                            {error2Message}
                        </label>
                    </div>
                </div >

                <div
                    className={s2enabled ? "fadeIn" : "fadeOut"}
                    style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "5vh",
                        alignSelf: "center",
                        flexDirection: "column",
                    }}
                >
                    <label
                        style={{
                            alignSelf: "start",
                            fontSize: pSize
                        }}
                    >
                        Enter your new password
                    </label>

                    <input
                        value={pw}
                        onChange={handlePWChange}
                        diabled={s3enabled}
                        label="Code"
                        type="password"
                        required
                        style={{
                            width: "100%",
                            height: "3vh",
                            fontSize: "2vh",
                            border: "none",
                            borderRadius: "10px",
                            backgroundColor: "rgba(255, 255, 255, 0.55)",
                            marginTop: "1vh",
                            marginBottom: "1vh"
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <button
                            onClick={SubmitPW}
                            diabled={s3enabled}
                            style={{
                                alignSelf: "flex-start",
                                fontSize: pSize
                            }}
                        >
                            Continue
                        </button>

                        <label
                            style={{
                                fontSize: "0.75vw",
                                alignSelf: "center",
                                fontSize: pSize,
                                textAlign: "end"
                            }}
                        >
                            {error3Message}
                            <a
                                href="/login"
                                style={{
                                    marginLeft: "0.5vh",
                                    alignSelf: "center",
                                    color: Colors.pink,
                                    fontSize: "2vh",
                                    textDecoration: "none",
                                    display: s3enabled ? "inline-block" : "none"
                                }}
                            >
                                Login
                            </a>
                        </label>


                    </div>
                </div >
            </div>
        </div >
    )
}
export default ResetPassword;