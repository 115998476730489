
import React, { useState, useEffect, useRef } from 'react';
import logo1 from "../assets/images/imageLogo.png";
import logo2 from "../assets/images/glowlogo.png";
import { slide as Menu } from 'react-burger-menu';
import "../styles/navbar.scss";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Cookies from 'universal-cookie';
import loggedInIcon from '../assets/images/loggedInHead.svg';

import { useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history'
import { Colors } from '../styles/colors';

import './header.css';

const jwt = require("jsonwebtoken");

export const Header = (props) => {

	const history = createBrowserHistory()
	const cookies = new Cookies();
	const burgerStyle = {
		bmBurgerButton: {
			position: 'absolute',
			width: '5vw',
			height: '4vw',
			left: '5vw',
			top: '10vw'
		},
		bmBurgerBars: {
			background: '#f2edf0'
		},
		bmMenu: {
			background: '#8a5174',
			padding: '10vh 0 0',
			fontSize: '1.15em',
			overflow: 'hidden'
		},
		bmItemList: {
			color: '#b8b7ad',
			padding: '0.8em'
		},
	}

	const [loggedIn, setLoggedIn] = useState(false);
	const [name, setName] = useState(false);

	useEffect(() => {
		if (cookies.get("JWT")) {
			setLoggedIn(true);
			const name = jwt.decode(cookies.get("JWT")).fName;
			setName(name);
		}
	}, []);

	if (props.device === "mobile" || props.device === "tablet") {
		return (

			<Menu
				isOpen={false}
				styles={burgerStyle}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						fontFamily: "bebas",
						fontWeight: 200,
						fontSize: "6vw",
					}}
				>

					{
						name ?
							(
								<div>
									<p
										style={{
											fontStyle: "italic",
											fontFamily: "Satisfy",
											color: "white",
											textDecoration: "none",
											marginBottom: 0
										}}
									>
										{name}
									</p>
									<a
										className="nav-menu-option"
										href={"/account/schedule"}
										style={{
											textDecoration: "none",
											color: "white",
											marginTop: 0,
											textDecoration: "none",
											marginBottom: "1vh",
											fontSize: "3vh"
										}}
									>
										My Schedule
									</a>
									<br />
									<a
										className="nav-menu-option"
										href={"/account/settings"}
										style={{
											textDecoration: "none",
											color: "white",
											marginTop: 0,
											textDecoration: "none",
											marginBottom: "1vh",
											fontSize: "3vh"
										}}
									>
										Settings
									</a>
									<br />

									<button
										onClick={() => {
											cookies.remove("JWT", { path: '/' });
											history.push("/")
											history.go(0);
										}}
										style={{
											textDecoration: "none",
											color: "white",
											marginTop: 0,
											marginLeft: 0,
											padding: "0 0 ",
											textDecoration: "none",
											marginBottom: "4vh",
											fontSize: "3vh",
											fontFamily: "bebas",
											textAlign: "left",
											border: "none",
											background: "none",
										}}
									>
										Logout
									</button>
									<br />
								</div>
							)
							:
							(
								<a
									className="nav-menu-option"
									href={"/login"}
									style={{
										textDecoration: "none",
										color: "white",
										marginTop: 0,
										textDecoration: "none",
										marginBottom: "4vh",
									}}
								>
									LOG IN
								</a>
							)
					}


					<a
						id="home"
						className="menu-item"
						href="/"
						style={{
							color: "white",
							textDecoration: "none",
							marginBottom: "2vh"
						}}
					>
						Home
					</a>
					<a id="about" className="menu-item" href="/about" style={{
						color: "white",
						textDecoration: "none",
						marginBottom: "2vh"
					}}>About</a>
					<a id="gym" className="menu-item" href="/gym" style={{
						color: "white",
						textDecoration: "none",
						marginBottom: "2vh"
					}}>Our Gym</a>
					<a id="training" className="menu-item" href="/training" style={{
						color: "white",
						textDecoration: "none",
						marginBottom: "2vh"
					}}>Training</a>

					<a
						id="store"
						className="menu-item"
						href="http://spaces.chec.io/31600"
						target="_blank"
						rel="noreferrer"
						style={{
							color: "white",
							textDecoration: "none",
							marginBottom: "2vh"
						}}
					>
						Merch
					</a>

					<a
						id="store"
						className="menu-item"
						href="/blog"
						style={{
							color: "white",
							textDecoration: "none",
							marginBottom: "2vh"
						}}
					>
						blog
					</a>
					<a
						id="store"
						className="menu-item"
						href="/social"
						style={{
							color: "white",
							textDecoration: "none",
							marginBottom: "2vh"
						}}
					>
						social
					</a>

					{/* <a id="app" className="menu-item" href="/boss-self-challenge" style={{
						color: "white",
						textDecoration: "none",
						marginBottom: "2vh"
					}}
					>Boss-Self Challenge</a> */}

				</div>
			</Menu>

		)
	}
	else {
		return (
			<div
				style={{
					top: 0,
					zIndex: 1,
					width: "100vw",
					height: "10vh",
					display: "flex",
					position: "absolute",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						width: '33vw',
						marginTop: "1vh",
						marginLeft: "2vh",
						marginBottom: "1vh",
					}}
				>
					<a
						href={"/"}
						style={{
							height: "100%",
							color: "white",
							textDecoration: "none",
							position: "absolute",
						}}
					>
						<div
							className="logoContainer"
							style={{
								display: "flex",
								width: "38vh",
							}}
						>
							<img
								alt="logo"
								src={logo1}
								style={{
									height: "100%",
									position: "absolute"
								}}
							/>
							<div>

								<h1
									className="logotext"
									style={{
										marginTop: 0,
										marginBottom: 0,
										marginLeft: "6vh",
										fontWeight: 200,
										fontSize: "7vh",
										fontFamily: "bebas",
									}}
								>
									BOSS SQUAD
								</h1>
								<h1
									className="logotext"
									style={{
										marginTop: 0,
										marginBottom: 0,
										marginLeft: "6vh",
										fontWeight: 500,
										fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
										fontSize: "3vh",
										position: "absolute",
										top: "6.25vh"
									}}
								>
									TRAINING
								</h1>
								<h1
									className="logotext"
									style={{
										marginTop: "2vh",
										marginBottom: 0,
										marginLeft: "18.75vh",
										fontWeight: 500,
										fontSize: "2.75vh",
										position: "absolute",
										top: "6.25vh",
										fontFamily: "Satisfy",
										transform: "rotate(-10deg)"
									}}
								>
									with Kate Gregory
								</h1>
							</div>

						</div>
					</a>

				</div>

				<div
					style={{
						width: '50vw',
						display: "flex",
						fontSize: "2vw",
						alignSelf: "center",
						alignItems: 'center',
						justifyContent: "space-evenly",
						fontFamily: "Bebas",
					}}
				>
					<a
						className="nav-menu-option"
						href={"/about"}
						style={{
							textDecoration: "none"
						}}
					>
						About
					</a>
					<a
						className="nav-menu-option"
						href={"/gym"}
						style={{
							textDecoration: "none"
						}}
					>
						Our Gym
					</a>
					<a
						className="nav-menu-option"
						href={"/training"}
						style={{
							textDecoration: "none"
						}}
					>
						Training
					</a>
					<a
						id="store"
						className="nav-menu-option"
						href="http://spaces.chec.io/31600"
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "none"
						}}
					>
						Merch
					</a>
					<a
						className="nav-menu-option"
						href={"/blog"}
						style={{
							textDecoration: "none"
						}}
					>
						Blog
					</a>
					<a
						className="nav-menu-option"
						href={"/social"}
						style={{
							textDecoration: "none"
						}}
					>
						Social
					</a>
					{/* <div style={{ display: new Date() < new Date("2023-10-15") ? 'block' : 'none' }}>
						<a
							className="nav-menu-option"
							href={"/boss-self-challenge"}
							style={{
								textDecoration: "none"
							}}
						>
							Boss-Self Challenge
						</a>
						<label className='newLabel'>New!</label>
					</div> */}

				</div>

				<div
					style={{
						alignSelf: "center",
						display: "flex",
						flexDirection: "row-reverse",
					}}
				>
					{
						loggedIn ?
							(
								<NavItem>
									<div
										style={{
											position: "absolute",
											right: "4vh",
											top: "7.5vh",
											padding: "0 1.5vh",
											display: "flex",
											flexDirection: "column",
											backgroundColor: "#d84ca2",
											borderRadius: "2.5vh",
											zIndex: -1
										}}
									>
										<p
											style={{
												marginBottom: "1vh",
												textAlign: "center",
												fontSize: "3vh",
												fontStyle: "italic",
												fontFamily: "Satisfy",
												color: "white",
												whiteSpace: "nowrap"
											}}
										>
											{name}
										</p>
										<a
											className="nav-menu-option"
											href={"/account/schedule"}
											style={{
												marginTop: 0,
												textDecoration: "none",
												marginBottom: "1vh",
												textAlign: "center",
												fontSize: "3vh",
												fontFamily: "bebas",
												border: "none",
												background: "none",
												cursor: "pointer",
												color: "white",
											}}
										>
											My Schedule
										</a>
										<a
											className="nav-menu-option"
											href={"/account/settings"}
											style={{
												marginTop: 0,
												textDecoration: "none",
												marginBottom: "1vh",
												textAlign: "center",
												fontSize: "3vh",
												fontFamily: "bebas",
												border: "none",
												background: "none",
												cursor: "pointer",
												color: "white",
											}}
										>
											Settings
										</a>

										<button
											onClick={() => {
												cookies.remove("JWT", { path: '/' });
												history.push("/")
												history.go(0);
											}}
											style={{
												marginTop: 0,
												marginBottom: "1vh",
												textAlign: "center",
												fontSize: "3vh",
												fontFamily: "bebas",
												border: "none",
												background: "none",
												cursor: "pointer",
												color: "white",
											}}
										>
											Logout
										</button>
									</div>
								</NavItem>
							)
							:
							(
								<div
									style={{
										alignSelf: "center",
										marginRight: "2vh",
										padding: "1vh 1vh",
									}}
								>
									<a
										className="nav-menu-optionLogin"
										href={"/login"}
										style={{
											fontSize: "2vw",
											fontFamily: "Bebas",
											marginRight: "2vh",
											fontWeight: 200,
											textDecoration: "none"
										}}
									>
										Log In
									</a>

									<div
										className={"loginHoverMessage"}
										style={{
											zIndex: -1,
											top: "3.5vh",
											right: "5vh",
											width: "10vw",
											position: "absolute",
											color: Colors.offwhite,
											fontFamily: "RobotoSlab",
											textAlign: "right",
										}}
									>
										<div style={{ height: "3vh" }} />
										<p>Sign up to book PT sessions and access all our app's features.</p>

									</div>

								</div>
							)
					}
				</div>

			</div >
		)
	}
}

function NavItem(props) {
	const [open, setOpen] = useState(false);

	return (
		<div className="nav__menu" style={{ alignSelf: "center" }}>
			<button
				onClick={() => setOpen(!open)}
				style={{
					zIndex: 3,
					border: "none",
					background: "#ff65c3",
					marginTop: "1vh",
					marginRight: "4vh",
					marginBottom: "1vh",
					alignSelf: "center",
					cursor: "pointer",
					padding: "0.5vh 0.65vh",
					borderRadius: "50px"
				}}
			>
				<img
					alt="nav menu"
					className="nav__menu-item"
					src={loggedInIcon}
					style={{
						width: "3.5vh",
						height: "3.5vh"
					}}
				/>
			</button>
			{open && props.children}

		</div>

	);
}