import React, { useState, useEffect } from 'react';
import { Colors } from '../styles/colors.js';
import { useHistory } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Slider from '@material-ui/core/Slider';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/training.scss";
import moment from 'moment';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { deviceType } from 'react-device-detect';
import DeviceSignalCellularConnectedNoInternet4Bar from 'material-ui/svg-icons/device/signal-cellular-connected-no-internet-4-bar';

const jwt = require("jsonwebtoken");

const dayArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const discountedSubscriptionPlanArray = [
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_2,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_3,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_4,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_5,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_6,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_7,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_8,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_9,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_10,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_11,
    process.env.REACT_APP_PAYPAL_DISCOUNTED_SUB_PLAN_ID_12
]
// Abandon all hope ye who enter
const Training = (props) => {

    const cookies = new Cookies();
    const history = useHistory();

    const [isPT, setIsPT] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [hasfreelesson, setHasFreeLesson] = useState(false);
    // true = online, false f2f, nutrition = nutrition
    const [onlineF2F, setOnlineF2f] = useState(null);
    const [selectedClient, setSelectedClient] = useState({ firstName: false, email: false });
    const [allClients, setAllClients] = useState([]);
    const [allPTs, setAllPTs] = useState([]);
    const [allPTUnavails, setAllPTUnavails] = useState([]);
    const [gotLessons, setGotLessons] = useState(false);
    const [allPTStaticUnavails, setAllPTStaticUnavails] = useState([]);
    const [selectedPTUnavails, setSelectedPTUnavails] = useState([]);
    const [selectedPTStaticUnavails, setSelectedPTStaticUnavails] = useState([]);
    const [allUpcomingLessons, setAllUpcomingLessons] = useState([]);
    const [payingCash, setPayingCash] = useState(false);
    const [payingCashGroup, setPayingCashGroup] = useState(false);
    const [selectedPT, setSelectedPT] = useState({ firstName: false, email: false });
    const [loggedIn, setLoggedIn] = useState(false);
    const [date, setChosenDate] = useState(new Date(Date.now()));
    const [groupDate, setChosenGroupDate] = useState(new Date(Date.now()));
    const [groupSession, setGroupSession] = useState(null);
    const [isGroupBooking, setIsGroupBooking] = useState(new Date(Date.now()).getDay === 4 ? true : false);
    const [repeatWeeks, setRepeatWeeks] = useState(1);
    const [stringDate, setstringDate] = useState("");
    const [availTimes, setAvailTimes] = useState([]);
    const [selectedTimeSlot, setselectedTimeSlot] = useState(null);
    const [hidePaypal, setHidePaypal] = useState(false);
    const [bookingError, setBookingError] = useState(false);
    const [isDiscounted, setIsDiscounted] = useState(false);
    const [withPT, setWithPT] = useState(true);
    const [lessonTier, setLessonTier] = useState(3);
    //true = single, false - repeat
    const [singleRepeat, setSingleRepeat] = useState(true);
    const [confirmModalOpen, setconfirmModalOpen] = useState(false);
    const [bookingSubmitted, setBookingSubmitted] = useState(false);
    const [bookingConfirmed, setBookingConfirmed] = useState(false);
    const [paypaloptions, setpaypaloptions] = useState({
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "AUD",
        vault: false
    });
    const [sessionPrice, setSessionPrice] = useState(70);
    const [dateSet, setDateSet] = useState(false);
    const [discountedPrice, setDiscountedPrice] = useState();
    const [thursdayGroupTime, setThursdayGroupTime] = useState(null);

    const [planDetailsModalOpen, setPlanDetailsModalOpen] = useState(false);


    const [arrAvailTimes, setArrAvailTimes] = useState([["6:00am"]]);
    const [arrSelectedDays, setArrSelectedDays] = useState([0]);
    const [arrSelectedTimes, setArrSelectedTimes] = useState(["6:00am"]);
    const [arrSelectedActualDates, setArrSelectedActualDates] = useState([]);
    const [pricePerWeek, setPricePerWeek] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);



    const [t1Size, setT1Size] = useState("4vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [t3Size, setT3Size] = useState("2vw");
    const [pSize, setPSize] = useState("1.25vw");
    const [p2Size, setP2Size] = useState("1vw");
    const [p3Size, setP3Size] = useState("0.75vw");


    const [isThrusdayBootCamp, setIsThursdayBootCamp] = useState(false);
    const [sessionLength, setSessionLength] = useState(60);
    const [nutritionPrice, setNutritionPrice] = useState();
    const [nutritionDetailsModalOpen, setNutritionDetailsModalOpen] = useState(false);
    const [noNutritionSessions, setNoNutritionSessions] = useState([1, 2, 3, 4]);
    const [nutritionDates, setNutritionDates] = useState([new Date(Date.now()), new Date(Date.now()), new Date(Date.now()), new Date(Date.now())])
    const times = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am",
        "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm"];
    const [chosenNutritionTime, setChosenNutritionTime] = useState();

    const [hasFreeNutritionSession, setHasFreeNutritionSession] = useState(false)
    const [allNutritionTimes, setAllNutritionTimes] = useState([times, times, times, times])
    const [selectedNutrionTimes, setSelectedNutritionTimes] = useState(["6:00am", "6:00am", "6:00am", "6:00am"])

    useEffect(() => {
        ComputeAvailableTimeSlots2ElectricBoogaloo();
    }, [repeatWeeks, selectedPT, date, withPT, arrSelectedDays]);
    useEffect(() => {
        let t = [...arrSelectedTimes];
        for (let i = 0; i < t.length; i++) {
            t[i] = arrAvailTimes[i][0];
        }
        setArrSelectedTimes(t);
    }, [arrAvailTimes]);
    useEffect(() => {
        let d = [...arrSelectedDays];
        let dd = [];

        for (let i = 0; i < d.length; i++) {
            dd[i] = moment(date).add(d[i], 'days').toDate();
        }
        setArrSelectedActualDates(dd);

    }, [arrSelectedDays, date]);
    useEffect(() => {
        // FINAL PRICE STRUCTURE
        const onlineLession1 = 60;
        const onlineLession2 = 75;
        const onlineLession3 = 85;

        let f2f1lesson1 = 70;
        let f2f1lesson2 = 85;
        let f2f1lesson3 = 95;
        let f2fMultilesson1 = 60;
        let f2fMultilesson2 = 75;
        let f2fMultiLesson3 = 85;

        const nutritionTotesBoss = 150;
        const nutritionBodyBalancing = 200;
        const nutritionGettingStarted = 275;

        if (sessionLength == 30) {
            f2f1lesson1 = 30;
            f2f1lesson2 = 40;
            f2f1lesson3 = 45;
            f2fMultilesson1 = 25;
            f2fMultilesson2 = 35;
            f2fMultiLesson3 = 40;
            setSessionPrice(30);
        }

        if (onlineF2F === true) {
            setRepeatWeeks(12);
            if (lessonTier === 1)
                setPricePerWeek(onlineLession1);
            else if (lessonTier === 2)
                setPricePerWeek(onlineLession2);
            else if (lessonTier === 3)
                setPricePerWeek(onlineLession3);
            else {
                setLessonTier(1);
                setPricePerWeek(onlineLession1);
            }
        } else {
            if (withPT) {
                if (isDiscounted) {
                    setPricePerWeek(discountedPrice * arrSelectedDays.length);
                }
                else {
                    if (repeatWeeks == 12) {
                        if (arrSelectedDays.length === 1) {
                            if (lessonTier === 1) {
                                setPricePerWeek(f2f1lesson1 * arrSelectedDays.length);
                            }
                            if (lessonTier === 2)
                                setPricePerWeek(f2f1lesson2 * arrSelectedDays.length);
                            if (lessonTier === 3)
                                setPricePerWeek(f2f1lesson3 * arrSelectedDays.length);
                        }
                        else {
                            if (lessonTier === 1)
                                setPricePerWeek(f2fMultilesson1 * arrSelectedDays.length);
                            if (lessonTier === 2)
                                setPricePerWeek(f2fMultilesson2 * arrSelectedDays.length);
                            if (lessonTier === 3)
                                setPricePerWeek(f2fMultiLesson3 * arrSelectedDays.length);
                        }
                    }
                    else {
                        if (arrSelectedDays.length === 1) {
                            setPricePerWeek(f2f1lesson1 * arrSelectedDays.length);
                        }
                        else {
                            setPricePerWeek(f2fMultilesson1 * arrSelectedDays.length);
                        }
                    }
                }
            } else {
                if (arrSelectedDays.length === 1) {
                    setPricePerWeek(20 * arrSelectedDays.length);
                }
                else
                    setPricePerWeek(15 * arrSelectedDays.length);
            }

            if (onlineF2F === 'nutrition') {
                if (lessonTier === 'FREE') {
                    setNutritionPrice(' FREE');
                    setNoNutritionSessions([1]);

                } else if (lessonTier === 1) {
                    setNutritionPrice(nutritionTotesBoss);
                    setNoNutritionSessions([1, 2]);

                } else if (lessonTier === 2) {
                    setNutritionPrice(nutritionBodyBalancing);
                    setNoNutritionSessions([1, 2, 3]);
                } else if (lessonTier === 3) {
                    setNutritionPrice(nutritionGettingStarted);
                    setNoNutritionSessions([1, 2, 3, 4]);
                }
            }
        }
    }, [arrSelectedDays.length, repeatWeeks, lessonTier, withPT, onlineF2F, sessionLength, arrAvailTimes]);
    useEffect(() => {
        setTotalPrice(pricePerWeek * repeatWeeks)
    }, [pricePerWeek, repeatWeeks]);
    useEffect(() => {
        Modal.setAppElement('body');
        if (cookies.get("JWT")) {
            setLoggedIn(true);
        }
    }, []);
    useEffect(() => {
        if (withPT) {
            if (arrSelectedDays.length === 1)
                setSessionPrice(70);
            else
                setSessionPrice(60);
        }
        else {
            if (arrSelectedDays.length === 1)
                setSessionPrice(20);
            else
                setSessionPrice(15);
        }
    }, [withPT, arrSelectedDays.length]);
    useEffect(() => {
        if (loggedIn) {
            CheckAccountType();

            CheckIsDiscounted();
            GetAllPTs();
            GetAllPTsAvailability();
            GetAllUpcomingLessons();
            CheckFreeFirstLesson();
        }
    }, [loggedIn]);
    useEffect(() => {
        if (isManager) {
            GetAllClients();
        }
    }, [isManager]);
    useEffect(() => {

        if (payingCash) {
            if (repeatWeeks === 1) {
                BookSingleSession();
            }
            else {
                BookRepeatSession();
            }
        }
    }, [payingCash]);
    useEffect(() => {

        if (payingCashGroup) {
            BookGroupSession();
        }
    }, [payingCashGroup]);
    useEffect(() => {

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setT1Size("8vw");
            setT2Size("5vw");
            setPSize("2.5vw")
            setP2Size("2.5vw")
            setP3Size("2vw")
        }
        if (d === "mobile") {
            setPSize("3.5vw")
            setP2Size("3.5vw")
            setP3Size("4vw")
            setT3Size("5vw");
        }

    }, [props.deviceType]);
    useEffect(() => {
        if (allPTUnavails && selectedPT.email) {
            let list = [];
            allPTUnavails.filter(tt => selectedPT.email === tt.ptEmail).forEach(i => list.push(i));
            setSelectedPTUnavails(list);
        }
        if (allPTStaticUnavails && selectedPT.email) {
            let list = [];
            allPTStaticUnavails.filter(tt => selectedPT.email === tt.ptEmail).forEach(i => list.push(i));
            setSelectedPTStaticUnavails(list);
        }
    }, [selectedPT, allPTUnavails, allPTStaticUnavails]);
    useEffect(() => {
        if (onlineF2F === 'nutrition') {
            return;
        }
        if (selectedPT.email && gotLessons) {
            ComputeAvailableTimeSlots();
            setselectedTimeSlot(null);
        }
    }, [allPTUnavails, selectedPTUnavails, date, repeatWeeks, singleRepeat, allUpcomingLessons, withPT]);
    useEffect(() => {

        if (repeatWeeks == 1) {
            setpaypaloptions({
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "AUD",
                vault: false
            })
        }
        else {
            setpaypaloptions({
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "AUD",
                vault: true,
                intent: "subscription"
            })
        }

    }, [repeatWeeks])
    useEffect(() => {
        if (onlineF2F === "nutrition" || onlineF2F === "bootcamp") {
            setpaypaloptions({
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "AUD",
                vault: false
            })
            setLessonTier(3)
        }
        else if (onlineF2F) {
            setpaypaloptions({
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "AUD",
                vault: true,
                intent: "subscription"
            })
        }

        if (onlineF2F || onlineF2F === "nutrition") {
            setWithPT(true)
        }

        if (onlineF2F === 'nutrition') {
            checkFreeNutriontionSession();
        }
    }, [onlineF2F])
    useEffect(() => {

        if (groupDate.getDay() === 4) {
            setGroupSession(["Thursday 5:00pm", "Thursday 5:30pm"])
        }
        else if (groupDate.getDay() === 6) {
            setGroupSession("Saturday 6:00am")
        }

    }, [groupDate])
    useEffect(() => {
        if (!isDiscounted) {
            if (!singleRepeat) {
                if (repeatWeeks > 1) {
                    setSessionPrice(60);
                }
            }
            else {
                setSessionPrice(70);
            }
        }
    }, [repeatWeeks, singleRepeat])
    useEffect(() => {

    }, [repeatWeeks, singleRepeat, withPT])
    useEffect(() => {
        computeNutritionTimeSlots();
    }, [onlineF2F, lessonTier, noNutritionSessions, nutritionDates])
    useEffect(() => {
        setSelectedNutritionTimes([allNutritionTimes[0][0], allNutritionTimes[1][0], allNutritionTimes[2][0], allNutritionTimes[3][0]])
    }, [nutritionDates, lessonTier])



    const checkFreeNutriontionSession = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/checkhasFreeNutrition", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                if (r.hasfreelesson === 'yes') {
                    setHasFreeNutritionSession(true);
                    setLessonTier('FREE')
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const CheckAccountType = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getaccounttype", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                if (r.isPT)
                    setIsPT(true);
                if (r.isManager)
                    setIsManager(true);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const CheckIsDiscounted = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/checkisuserdiscounted", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                if (r.isDiscounted === "yes") {
                    setIsDiscounted(true);
                    setDiscountedPrice(r.rate);
                }
            })
            .catch(function (error) {
                console.log("ERROR" + error)
                setBookingError(true);
            })
    }
    const handlePTChange = (e) => {

        let ptObject = allPTs.filter((pt) => pt.email === e.target.value);

        if (!ptObject.length) {
            let newpt = { firstName: false, email: false }
            setSelectedPT(newpt)
        }
        setSelectedPT(ptObject[0]);

    }
    const handleClientChange = (e) => {

        let clientObject = allClients.filter((c) => c.email === e.target.value);

        if (!clientObject.length) {
            let newClient = { firstName: false, email: false }
            setSelectedClient(newClient)
        }
        setSelectedClient(clientObject[0]);

    }
    const GetAllClients = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getallclients", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                let arr = [];
                arr.push(r.message)

                setSelectedClient(arr[0][0])
                setAllClients(arr[0]);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const GetAllPTs = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/getallpts", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }

                let r = await res.json();
                let arr = [];
                arr.push(r.message)
                setSelectedPT(arr[0][0])
                setAllPTs(arr[0]);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const GetAllPTsAvailability = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/getallptunavails", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then((response) => {
                setAllPTUnavails(response.message[0]);
                setAllPTStaticUnavails(response.message[1]);
            })
            .catch(function (error) {
                console.log("ERROR" + error)
            })
    }
    const GetAllUpcomingLessons = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/GetAllUpcomingLessons", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then((response) => {
                setAllUpcomingLessons(response.message);
                setGotLessons(true);
            })
            .catch(function (error) {
                console.log("ERROR" + error)
            })
    }
    const CheckFreeFirstLesson = async () => {
        let email = jwt.decode(cookies.get("JWT")).email;

        //yeah i know...
        if (email == "dquinn1331131@gmail.com" || email == "t.dayhew@hotmail.com" || email == " sherrygordon98@outlook.com" || email == "georgiagirl305@bigpond.com" || email == "leisasmall1978@gmail.com" || email == "harrisongregory99@gmail.com" || email == "chantelle264@hotmail.com" || email == "spoonerka@gmail.com" || email == "laurenkesby@outlook.com" || email == "Nikkie_n@yahoo.com" || email == "triciasilcock@Hotmail.com")
            return;

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/checkfreefirstlesson", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.hasfreelesson === "yes") {
                    setHasFreeLesson(true);
                }
            })
            .catch(function (error) {
                console.log("ERROR" + error)
            })
    }
    const ComputeAvailableTimeSlots = () => {
        const unavails = selectedPTUnavails;
        const staticUnavails = selectedPTStaticUnavails;
        const day = new Date(date).toString().substring(0, 3);
        //6am 1970/2/1
        //Because date and time are stored seperately for whatever reason
        const TimeOffset = 2664000000;
        const timeslotarray = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm"]

        //disregard if chosen date is in past
        let now = new Date(Date.now());
        now.setHours(now.getHours() + 24)
        if (date < now) {
            setAvailTimes([]);
            return;
        }

        if (!withPT) {
            const sunMon = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm", "6:30pm", "7:00pm", "7:30pm", "8:00pm", "8:30pm", "9:00pm"]
            const tues = sunMon.slice(0, 11);
            const wedThurFri = sunMon.slice(12, 15);

            for (let i = 0; i < 7; i++) {

                wedThurFri.push(sunMon.slice(24 + i, 25 + i)[0]);
            }


            const sat = sunMon.slice(10, 22);

            if (day === "Sun" || day === "Mon") {
                setAvailTimes(sunMon);
            }
            if (day === "Tue") {
                setAvailTimes(tues);
            }
            if (day === "Wed" || day === "Thu" || day === "Fri") {
                setAvailTimes(wedThurFri);
            }
            if (day === "Sat") {
                setAvailTimes(sat);
            }
            return;
        }

        const CheckRecurringUnavail = (day) => {

            for (let i = 0; i < unavails.length; i++) {
                let unavail = unavails[i];
                let start = Date.parse(unavail.startT) - TimeOffset;
                let end = Date.parse(unavail.endT) - TimeOffset;
                //convert from milliseconds to 30 min increments ==== blocks in calendar
                start = start / 1800000
                end = end / 1800000


                if (day === "Sun") {
                    if (unavail.sunday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Mon") {
                    if (unavail.monday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Tue") {
                    if (unavail.tuesday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Wed") {
                    if (unavail.wednesday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Thu") {
                    if (unavail.thursday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Fri") {
                    if (unavail.friday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
                if (day === "Sat") {
                    if (unavail.saturday)
                        for (let j = start; j < end; j++) {
                            timeslotarray[j] = null;
                            if (j !== 0) {
                                timeslotarray[j - 1] = null;
                            }
                        }
                }
            }

        }
        const CheckStaticUnavail = (date) => {

            for (let i = 0; i < staticUnavails.length; i++) {

                let unavail = staticUnavails[i];

                if (date.getDate() === new Date(unavail.date).getDate()
                    && date.getMonth() === new Date(unavail.date).getMonth()
                    && date.getFullYear() === new Date(unavail.date).getFullYear()) {
                    let start = Date.parse(unavail.startT) - TimeOffset;
                    let end = Date.parse(unavail.endT) - TimeOffset;
                    //convert from milliseconds to 30 min increments ==== blocks in calendar
                    start = start / 1800000
                    end = end / 1800000


                    if (day === "Sun") {
                        if (new Date(unavail.date).getDay() === 0)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Mon") {
                        if (new Date(unavail.date).getDay() === 1)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Tue") {
                        if (new Date(unavail.date).getDay() === 2)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Wed") {
                        if (new Date(unavail.date).getDay() === 3)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Thu") {
                        if (new Date(unavail.date).getDay() === 4)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Fri") {
                        if (new Date(unavail.date).getDay() === 5)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }
                    if (day === "Sat") {
                        if (new Date(unavail.date).getDay() === 6)
                            for (let j = start; j < end; j++) {
                                timeslotarray[j] = null;
                                if (j !== 0) {
                                    timeslotarray[j - 1] = null;
                                }
                            }
                    }

                }



            }
        }
        const CheckBookedSlots = (date) => {
            for (let i = 0; i < allUpcomingLessons.length; i++) {
                let unavail = allUpcomingLessons[i];


                if (unavail.trainer === selectedPT.email) {

                    if (date.getDate() === new Date(unavail.startDate).getDate()
                        && date.getMonth() === new Date(unavail.startDate).getMonth()
                        && date.getFullYear() === new Date(unavail.startDate).getFullYear()) {

                        //18:00:00
                        //start to 1970-02-01 06:00:00
                        let st = "1970-02-01 " + unavail.startTime
                        let start = Date.parse(st) - TimeOffset;
                        let end = Date.parse(st) - TimeOffset + 3600000;

                        //convert from milliseconds to 30 min increments ==== blocks in calendar
                        start = start / 1800000
                        end = end / 1800000


                        if (day === "Sun") {
                            if (new Date(unavail.startDate).getDay() === 0)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Mon") {
                            if (new Date(unavail.startDate).getDay() === 1)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Tue") {
                            if (new Date(unavail.startDate).getDay() === 2)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Wed") {
                            if (new Date(unavail.startDate).getDay() === 3)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Thu") {
                            if (new Date(unavail.startDate).getDay() === 4)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Fri") {
                            if (new Date(unavail.startDate).getDay() === 5)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Sat") {
                            if (new Date(unavail.startDate).getDay() === 6)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                    }
                }
            }
        }
        CheckRecurringUnavail(day);

        if (singleRepeat) {
            CheckStaticUnavail(date);
            CheckBookedSlots(date);
        }
        else {
            for (let i = 0; i < repeatWeeks; i++) {

                let iDate = new Date(date);
                iDate.setDate(iDate.getDate() + (i * 7));

                CheckStaticUnavail(iDate);
                CheckBookedSlots(iDate);
            }
        }

        let filtered = timeslotarray.filter(function (el) {
            return el != null;
        });
        setAvailTimes(filtered);
    }
    const ComputeAvailableTimeSlots2ElectricBoogaloo = () => {
        const unavails = selectedPTUnavails;
        const staticUnavails = selectedPTStaticUnavails;

        let d = [...arrSelectedDays];
        let t = [...arrSelectedTimes];
        let a = [...arrAvailTimes];

        for (let i = 0; i < a.length; i++) {
            const startDate = moment(date).add(d[i], 'days').toDate();
            const day = new Date(startDate).toString().substring(0, 3);

            //6am 1970/2/1
            //Because date and time are stored seperately for whatever reason
            const TimeOffset = 2664000000;
            const timeslotarray = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm"]

            //disregard if chosen date is in past
            let now = new Date(Date.now());
            now.setHours(now.getHours() + 24)
            if (startDate < now) {
                a[i] = ["No times available"];
            }
            else {
                if (!withPT) {
                    const sunMon = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm", "6:30pm", "7:00pm", "7:30pm", "8:00pm", "8:30pm", "9:00pm"]
                    const tues = sunMon.slice(0, 11);
                    const wedThurFri = sunMon.slice(12, 15);

                    for (let i = 0; i < 7; i++) {
                        wedThurFri.push(sunMon.slice(24 + i, 25 + i)[0]);
                    }

                    const sat = sunMon.slice(10, 22);

                    if (day === "Sun" || day === "Mon") {
                        a[i] = sunMon;
                    }
                    if (day === "Tue") {
                        a[i] = tues;
                    }
                    if (day === "Wed" || day === "Thu" || day === "Fri") {
                        a[i] = wedThurFri;
                    }
                    if (day === "Sat") {
                        a[i] = sat;
                    }
                }
                else {

                    const CheckRecurringUnavail = (day) => {

                        for (let i = 0; i < unavails.length; i++) {
                            let unavail = unavails[i];
                            let start = Date.parse(unavail.startT) - TimeOffset;
                            let end = Date.parse(unavail.endT) - TimeOffset;
                            //convert from milliseconds to 30 min increments ==== blocks in calendar
                            start = start / 1800000
                            end = end / 1800000


                            if (day === "Sun") {
                                if (unavail.sunday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Mon") {
                                if (unavail.monday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Tue") {
                                if (unavail.tuesday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Wed") {
                                if (unavail.wednesday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Thu") {
                                if (unavail.thursday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Fri") {
                                if (unavail.friday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Sat") {
                                if (unavail.saturday)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                        }

                    }
                    const CheckStaticUnavail = (date) => {

                        for (let i = 0; i < staticUnavails.length; i++) {

                            let unavail = staticUnavails[i];

                            if (date.getDate() === new Date(unavail.date).getDate()
                                && date.getMonth() === new Date(unavail.date).getMonth()
                                && date.getFullYear() === new Date(unavail.date).getFullYear()) {
                                let start = Date.parse(unavail.startT) - TimeOffset;
                                let end = Date.parse(unavail.endT) - TimeOffset;
                                //convert from milliseconds to 30 min increments ==== blocks in calendar
                                start = start / 1800000
                                end = end / 1800000


                                if (day === "Sun") {
                                    if (new Date(unavail.date).getDay() === 0)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Mon") {
                                    if (new Date(unavail.date).getDay() === 1)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Tue") {
                                    if (new Date(unavail.date).getDay() === 2)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Wed") {
                                    if (new Date(unavail.date).getDay() === 3)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Thu") {
                                    if (new Date(unavail.date).getDay() === 4)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Fri") {
                                    if (new Date(unavail.date).getDay() === 5)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Sat") {
                                    if (new Date(unavail.date).getDay() === 6)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                            }
                        }
                    }
                    const CheckBookedSlots = (date) => {
                        for (let i = 0; i < allUpcomingLessons.length; i++) {
                            let unavail = allUpcomingLessons[i];


                            if (unavail.trainer === selectedPT.email) {

                                if (date.getDate() === new Date(unavail.startDate).getDate()
                                    && date.getMonth() === new Date(unavail.startDate).getMonth()
                                    && date.getFullYear() === new Date(unavail.startDate).getFullYear()) {

                                    //18:00:00
                                    //start to 1970-02-01 06:00:00
                                    let st = "1970-02-01 " + unavail.startTime
                                    let start = Date.parse(st) - TimeOffset;
                                    let end = Date.parse(st) - TimeOffset + 3600000;

                                    //convert from milliseconds to 30 min increments ==== blocks in calendar
                                    start = start / 1800000
                                    end = end / 1800000


                                    if (day === "Sun") {
                                        if (new Date(unavail.startDate).getDay() === 0)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Mon") {
                                        if (new Date(unavail.startDate).getDay() === 1)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Tue") {
                                        if (new Date(unavail.startDate).getDay() === 2)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Wed") {
                                        if (new Date(unavail.startDate).getDay() === 3)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Thu") {
                                        if (new Date(unavail.startDate).getDay() === 4)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Fri") {
                                        if (new Date(unavail.startDate).getDay() === 5)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                    if (day === "Sat") {
                                        if (new Date(unavail.startDate).getDay() === 6)
                                            for (let j = start; j < end; j++) {
                                                timeslotarray[j] = null;
                                                if (j !== 0) {
                                                    timeslotarray[j - 1] = null;
                                                }
                                            }
                                    }
                                }
                            }
                        }
                    }
                    CheckRecurringUnavail(day);

                    if (repeatWeeks === 1) {
                        CheckStaticUnavail(startDate);
                        CheckBookedSlots(startDate);
                    }
                    else {
                        for (let i = 0; i < repeatWeeks; i++) {

                            let iDate = new Date(startDate);
                            iDate.setDate(iDate.getDate() + (i * 7));

                            CheckStaticUnavail(iDate);
                            CheckBookedSlots(iDate);
                        }
                    }

                    let filtered = timeslotarray.filter(function (el) {
                        return el != null;
                    });
                    if (filtered.length === 0) {
                        filtered = ["No times available"]
                    }
                    a[i] = filtered;
                }
            }
        }
        setArrAvailTimes(a);
    }
    const computeNutritionTimeSlots = () => {
        if (onlineF2F !== 'nutrition') {
            return;
        }

        const unavails = selectedPTUnavails;
        const staticUnavails = selectedPTStaticUnavails;
        const dates = [...nutritionDates];
        const allTimes = [...allNutritionTimes];

        for (let session = 0; session < noNutritionSessions.length; session++) {
            //6am 1970/2/1
            //Because date and time are stored seperately for whatever reason
            const timeslotarray = ["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am",
                "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm"];
            const TimeOffset = 2664000000;
            const sessionDate = nutritionDates[session];
            const day = new Date(dates[session]).toString().substring(0, 3);

            for (let i = 0; i < allTimes.length; i++) {
                //disregard if chosen date is in past
                let now = new Date(Date.now());
                now.setHours(now.getHours() + 24)
                if (sessionDate < now) {
                    allTimes[session] = ["No times available"];
                    continue;
                }

                const CheckRecurringUnavail = (day) => {

                    for (let i = 0; i < unavails.length; i++) {
                        let unavail = unavails[i];
                        let start = Date.parse(unavail.startT) - TimeOffset;
                        let end = Date.parse(unavail.endT) - TimeOffset;
                        //convert from milliseconds to 30 min increments ==== blocks in calendar
                        start = start / 1800000
                        end = end / 1800000


                        if (day === "Sun") {
                            if (unavail.sunday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Mon") {
                            if (unavail.monday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Tue") {
                            if (unavail.tuesday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Wed") {
                            if (unavail.wednesday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Thu") {
                            if (unavail.thursday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Fri") {
                            if (unavail.friday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                        if (day === "Sat") {
                            if (unavail.saturday)
                                for (let j = start; j < end; j++) {
                                    timeslotarray[j] = null;
                                    if (j !== 0) {
                                        timeslotarray[j - 1] = null;
                                    }
                                }
                        }
                    }

                }
                const CheckStaticUnavail = (date) => {

                    for (let i = 0; i < staticUnavails.length; i++) {

                        let unavail = staticUnavails[i];

                        if (date.getDate() === new Date(unavail.date).getDate()
                            && date.getMonth() === new Date(unavail.date).getMonth()
                            && date.getFullYear() === new Date(unavail.date).getFullYear()) {
                            let start = Date.parse(unavail.startT) - TimeOffset;
                            let end = Date.parse(unavail.endT) - TimeOffset;
                            //convert from milliseconds to 30 min increments ==== blocks in calendar
                            start = start / 1800000
                            end = end / 1800000


                            if (day === "Sun") {
                                if (new Date(unavail.date).getDay() === 0)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Mon") {
                                if (new Date(unavail.date).getDay() === 1)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Tue") {
                                if (new Date(unavail.date).getDay() === 2)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Wed") {
                                if (new Date(unavail.date).getDay() === 3)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Thu") {
                                if (new Date(unavail.date).getDay() === 4)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Fri") {
                                if (new Date(unavail.date).getDay() === 5)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                            if (day === "Sat") {
                                if (new Date(unavail.date).getDay() === 6)
                                    for (let j = start; j < end; j++) {
                                        timeslotarray[j] = null;
                                        if (j !== 0) {
                                            timeslotarray[j - 1] = null;
                                        }
                                    }
                            }
                        }
                    }
                }
                const CheckBookedSlots = (date) => {
                    for (let i = 0; i < allUpcomingLessons.length; i++) {
                        let unavail = allUpcomingLessons[i];


                        if (unavail.trainer === selectedPT.email) {

                            if (date.getDate() === new Date(unavail.startDate).getDate()
                                && date.getMonth() === new Date(unavail.startDate).getMonth()
                                && date.getFullYear() === new Date(unavail.startDate).getFullYear()) {

                                //18:00:00
                                //start to 1970-02-01 06:00:00
                                let st = "1970-02-01 " + unavail.startTime
                                let start = Date.parse(st) - TimeOffset;
                                let end = Date.parse(st) - TimeOffset + 3600000;

                                //convert from milliseconds to 30 min increments ==== blocks in calendar
                                start = start / 1800000
                                end = end / 1800000


                                if (day === "Sun") {
                                    if (new Date(unavail.startDate).getDay() === 0)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Mon") {
                                    if (new Date(unavail.startDate).getDay() === 1)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Tue") {
                                    if (new Date(unavail.startDate).getDay() === 2)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Wed") {
                                    if (new Date(unavail.startDate).getDay() === 3)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Thu") {
                                    if (new Date(unavail.startDate).getDay() === 4)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Fri") {
                                    if (new Date(unavail.startDate).getDay() === 5)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                                if (day === "Sat") {
                                    if (new Date(unavail.startDate).getDay() === 6)
                                        for (let j = start; j < end; j++) {
                                            timeslotarray[j] = null;
                                            if (j !== 0) {
                                                timeslotarray[j - 1] = null;
                                            }
                                        }
                                }
                            }
                        }
                    }
                }

                CheckRecurringUnavail(day);
                CheckStaticUnavail(sessionDate);
                CheckBookedSlots(sessionDate);


                let filtered = timeslotarray.filter(function (el) {
                    return el != null;
                });
                if (filtered.length === 0) {
                    filtered = ["No times available"]
                }
                allTimes[session] = filtered;
            }
        }
        setAllNutritionTimes(allTimes);
    }
    const BookSingleSession = async () => {

        //Should only be called when both no of weeks and sesions per week are 1 - arrseldays[0] is ok

        if (onlineF2F === 'nutrition') {
            const body = {
                jwt: cookies.get("JWT"),
                nutritionDates,
                selectedNutrionTimes,
                trainer: selectedPT.email,
                lessonTier,
                //if manager send client, otherwise null
                PTBookingClient: selectedClient
            }

            await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/bookNutrition", {
                mode: "cors",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
                dataType: "text",
                contentType: "text/plain"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setBookingConfirmed(true);
                    }
                    else {
                        setBookingError(true);
                    }
                })
                .catch(function (error) {
                    console.log("ERROR" + error)
                    setBookingError(true);
                })
        } else {
            await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/booksingle", {
                mode: "cors",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "jwt": cookies.get("JWT"),
                    "trainer": selectedPT.email,
                    "date": arrSelectedActualDates[0],
                    "time": arrSelectedTimes[0],
                    "cash": payingCash,
                    "noPT": !withPT,
                    //if manager send client, otherwise null
                    "PTBookingClient": selectedClient,
                    "nutritionConsult": onlineF2F === 'nutrition',
                    sessionLength
                }),
                dataType: "text",
                contentType: "text/plain"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setBookingConfirmed(true);
                    }
                    else {
                        setBookingError(true);
                    }
                })
                .catch(function (error) {
                    console.log("ERROR" + error)
                    setBookingError(true);
                })
        }
    }
    const BookGroupSession = async () => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/bookgroupsession", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "date": groupDate,
                "cash": payingCashGroup,
                "PTBookingClient": selectedClient,
                thursdayGroupTime,
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {
                if (res.status === 200) {
                    setBookingConfirmed(true);
                }
                else {
                    setBookingError(true);
                }
            })
            .catch(function (error) {
                console.error(error);
                console.log("ERROR" + error)
                setBookingError(true);
            })
    }
    const BookRepeatSession = async () => {
        for (let i = 0; i < arrSelectedActualDates.length; i++) {

            let d = arrSelectedActualDates[i];
            await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/bookrepeat", {
                mode: "cors",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "jwt": cookies.get("JWT"),
                    "trainer": selectedPT.email,
                    "date": d,
                    "time": arrSelectedTimes[i],
                    'weeks': repeatWeeks,
                    "cash": payingCash,
                    "noPT": !withPT,
                    "PTBookingClient": selectedClient,
                    "lessonTier": lessonTier,
                    "online": onlineF2F,
                    sessionLength,
                }),
                dataType: "text",
                contentType: "text/plain"
            })
                .then((response) => response.json())
                .then(async (response) => {
                    setBookingConfirmed(true);
                    console.log(response.message)
                })
                .catch(function (error) {
                    console.log("ERROR" + error);
                    setBookingError(true);
                })
        }
    }
    const createOrder = (data, actions, description) => {
        setBookingSubmitted(true);
        if (onlineF2F === "nutrition") {
            return actions.order.create({
                purchase_units: [
                    {
                        description: "Boss Squad Training Nutrition Coaching",
                        amount: {
                            currency_code: "AUD",
                            value: nutritionPrice
                        }
                    }
                ]
            });
        }
        else {
            return actions.order.create({
                purchase_units: [
                    {
                        description: "Boss Squad Training PT Session",
                        amount: {
                            currency_code: "AUD",
                            value: !withPT ? pricePerWeek : isDiscounted ? discountedPrice : pricePerWeek
                        }
                    }
                ]
            });
        }
    }
    const createGroupOrder = (data, actions, description) => {
        setBookingSubmitted(true);
        return actions.order.create({
            purchase_units: [
                {
                    description: "Boss Squad Training Group Session",
                    amount: {
                        currency_code: "AUD",
                        value: 10
                    }
                }
            ]
        });
    }
    const onApproveGroupOrder = (data, actions, lessonCount) => {
        actions.order.capture().then(async () => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            console.log("Payment Approved: ", paymentData);
            setHidePaypal(true);
            BookGroupSession();
        });
    }
    const createSubscription = (data, actions) => {
        setBookingSubmitted(true);

        if (onlineF2F) {
            if (lessonTier === 1) {
                return actions.subscription.create({
                    plan_id: process.env.REACT_APP_PAYPAL_BRONZE_PLAN
                });
            }
            else if (lessonTier === 2) {
                return actions.subscription.create({
                    plan_id: process.env.REACT_APP_PAYPAL_SILVER_PLAN
                });
            }
            else {
                return actions.subscription.create({
                    plan_id: process.env.REACT_APP_PAYPAL_GOLD_PLAN
                });
            }
        }
        else {
            return actions.subscription.create({
                plan_id: discountedSubscriptionPlanArray[repeatWeeks - 2],
                //Discounted plans are $1 per item so discountedPrice x quantity = rate
                quantity: pricePerWeek
            });
        }


    }
    const onApproveOrder = (data, actions, lessonCount) => {
        actions.order.capture().then(async () => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            console.log("Payment Approved: ", paymentData);
            setHidePaypal(true);
            BookSingleSession();
        });
    }
    const onApproveSubscription = (data, actions) => {
        actions.subscription.get().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.subscriptionID
            };
            console.log("Payment Approved: ", paymentData);
            setHidePaypal(true);
            BookRepeatSession();
        });
    }
    const showNutritionBookButton = () => {
        if (lessonTier === 'FREE') {
            if (allNutritionTimes[0][0] === "No times available") {
                return false;
            }

            return true;
        }

        if (lessonTier === 1) {
            if (allNutritionTimes[0][0] === "No times available" || allNutritionTimes[1][0] === "No times available") {
                return false;
            }

            return true;
        }

        if (lessonTier === 2) {
            if (allNutritionTimes[0][0] === "No times available" || allNutritionTimes[1][0] === "No times available"
                || allNutritionTimes[2][0] === "No times available") {
                return false;
            }

            return true;
        }

        if (lessonTier === 3) {
            if (allNutritionTimes[0][0] === "No times available" || allNutritionTimes[1][0] === "No times available"
                || allNutritionTimes[2][0] === "No times available" || allNutritionTimes[3][0] === "No times available") {
                return false;
            }

            return true;
        }

        return false;
    }

    //Select training type
    if (loggedIn && onlineF2F === null) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    width: "100vw",
                    minHeight: "93vh",
                    fontFamily: "RobotoSlab",
                    color: Colors.offwhite,
                    backgroundColor: Colors.offBlack
                }}
            >
                <h1
                    style={{
                        marginTop: "20vh",
                        fontFamily: "bebas",
                        fontWeight: 200,
                        fontSize: t1Size
                    }}
                >
                    OUR SERVICES
                </h1>

                <div
                    style={{
                        width: props.deviceType === "desktop" ? "67%" : "85%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexWrap: props.deviceType === "desktop" ? "none" : "wrap",
                        alignItems: "center"
                    }}
                >
                    <div
                        className="f2f"
                        onClick={() => setOnlineF2f(false)}
                        style={{

                            color: Colors.offBlack,
                            backgroundColor: Colors.pink,
                            width: props.deviceType === "desktop" ? "15vw" : "30vw",
                            height: props.deviceType === "desktop" ? "20vw" : "35vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: "5vh"
                        }}
                    >
                        <h1
                            style={{
                                fontFamily: "bebas",
                                fontWeight: 200,
                                fontSize: t2Size,
                                textAlign: "center",
                                marginBottom: 0
                            }}
                        >
                            Face to Face Training
                        </h1>

                        <p
                            style={{
                                textAlign: 'center',
                                width: "85%",
                                fontSize: p2Size
                            }}
                        >
                            One on one PT sessions at Boss Squad HQ.
                        </p>

                    </div>

                    <div
                        className="f2f"
                        onClick={() => setOnlineF2f(true)}
                        style={{
                            // border: "0.5vw solid #ffffff",
                            color: Colors.offBlack,
                            backgroundColor: Colors.pink,
                            width: props.deviceType === "desktop" ? "15vw" : "30vw",
                            height: props.deviceType === "desktop" ? "20vw" : "35vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: "5vh"
                        }}
                    >
                        <h1
                            style={{
                                fontFamily: "bebas",
                                fontWeight: 200,
                                textAlign: "center",
                                fontSize: t2Size,
                                marginBottom: 0
                            }}
                        >
                            Online <br /> Training
                        </h1>

                        <p
                            style={{
                                textAlign: 'center',
                                width: "85%",
                                fontSize: p2Size
                            }}
                        >
                            12 week tailored programs to complete at home.
                        </p>

                    </div>

                    <div
                        className="f2f"
                        onClick={() => setOnlineF2f("bootcamp")}
                        style={{
                            // border: "0.5vw solid #ffffff",
                            color: Colors.offBlack,
                            backgroundColor: Colors.pink,
                            width: props.deviceType === "desktop" ? "15vw" : "30vw",
                            height: props.deviceType === "desktop" ? "20vw" : "35vw",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "5vh",
                            alignItems: "center",
                            cursor: "pointer"
                        }}
                    >
                        <h1
                            style={{
                                fontFamily: "bebas",
                                fontWeight: 200,
                                fontSize: t2Size,
                                marginBottom: 0,
                                textAlign: "center",
                                width: "80%"
                            }}
                        >
                            BOSS SQUAD BOOTCAMPS
                        </h1>

                        <p
                            style={{
                                textAlign: 'center',
                                width: "85%",
                                fontSize: p2Size
                            }}
                        >
                            Group training sessions hosted every Thursday and Saturday
                        </p>

                    </div>

                    <div
                        className="f2f"
                        onClick={() => setOnlineF2f("nutrition")}
                        style={{
                            // border: "0.5vw solid #ffffff",
                            color: Colors.offBlack,
                            backgroundColor: Colors.pink,
                            width: props.deviceType === "desktop" ? "15vw" : "30vw",
                            height: props.deviceType === "desktop" ? "20vw" : "35vw",
                            display: "flex",
                            marginBottom: "5vh",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer"
                        }}
                    >
                        <h1
                            style={{
                                fontFamily: "bebas",
                                fontWeight: 200,
                                fontSize: t2Size,
                                marginBottom: 0,
                                textAlign: "center",
                                width: "80%"
                            }}
                        >
                            Nutrition Coaching
                        </h1>

                        <p
                            style={{
                                textAlign: 'center',
                                width: "85%",
                                fontSize: p2Size
                            }}
                        >
                            Everything food related to compliment your training.
                        </p>

                    </div>

                </div>

            </div>
        )
    }
    //Online training
    else if (loggedIn && onlineF2F === true) {
        return (

            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100vw",
                    minHeight: "93vh",
                    fontSize: pSize,
                    background: Colors.offBlack
                }}
            >

                <Modal
                    isOpen={confirmModalOpen}
                    onRequestClose={() => setconfirmModalOpen(false)}
                    style={{
                        content: {
                            width: props.deviceType === "desktop" ? "30vw" : "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            maxHeight: "75vh",
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >

                    <div>
                        {
                            !bookingConfirmed ?
                                bookingError ?
                                    (
                                        <h2
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 20
                                            }}
                                        >Uh Oh</h2>
                                    )
                                    :
                                    (
                                        <h2
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 20
                                            }}>Confirm Booking</h2>
                                    )
                                :
                                (
                                    <h2
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 20
                                        }}>Booking Confirmed!</h2>
                                )
                        }

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: pSize
                            }}
                        >
                            {

                                bookingConfirmed ?
                                    (
                                        <>
                                            <p>Success! your booking has been finalised.</p>
                                            <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
                                        </>
                                    )
                                    :
                                    (
                                        bookingError ?
                                            (
                                                <>
                                                    <label>This is embarassing but something has gone wrong on our end.</label>
                                                    <label>We've received your payment but were unable to create a record for your PT session.</label>
                                                    <label>Please send us an email at info@bosssquadtraining.com and we'll sort it out for you.</label>
                                                    <label>Apologies for the inconvenience.</label>
                                                </>
                                            )
                                            :
                                            (
                                                isDiscounted && discountedPrice === 0 || isManager ?
                                                    (
                                                        <p>Your first session will be on {stringDate} at {availTimes[selectedTimeSlot]} with {selectedPT.firstName}. You will continue to have sessions on {dayArray[date.getDay()]} at {availTimes[selectedTimeSlot]} for another {repeatWeeks - 1} weeks.
                                                        </p>

                                                    )
                                                    :
                                                    (
                                                        <p>Your first session will be on {stringDate} at {availTimes[selectedTimeSlot]} with {selectedPT.firstName}. You will continue to have sessions on {dayArray[date.getDay()]} at {availTimes[selectedTimeSlot]} for another {repeatWeeks - 1} weeks.
                                                            You will be charged ${lessonTier === 1 ? "60" : lessonTier === 2 ? "75" : "85"} now, and will continue to be charged ${lessonTier === 1 ? "60" : lessonTier === 2 ? "75" : "85"} weekly for another {repeatWeeks - 1} weeks.</p>

                                                    )
                                            )
                                    )
                            }
                            {
                                bookingConfirmed ?
                                    (
                                        null
                                    )
                                    :
                                    (
                                        isDiscounted && discountedPrice === 0 || isManager ?
                                            (
                                                <button
                                                    style={{
                                                        backgroundColor: "#2C2E2F",
                                                        color: Colors.offwhite,
                                                        fontFamily: "Bebas",
                                                        border: "none",
                                                        maxWidth: "750px",
                                                        fontSize: "1.5vw",
                                                        borderRadius: "3px",
                                                        cursor: "pointer",
                                                        padding: "0.5vw 0.5vw",
                                                        marginBottom: "2vh",
                                                        marginTop: "2vh"
                                                    }}
                                                    onClick={() => {
                                                        BookRepeatSession();
                                                    }}
                                                >
                                                    Confirm
                                                </button>
                                            )
                                            :
                                            (
                                                hidePaypal ?
                                                    (
                                                        null
                                                    )
                                                    :
                                                    (
                                                        <PayPalScriptProvider options={paypaloptions}>
                                                            <PayPalButtons
                                                                createSubscription={(data, actions) => createSubscription(data, actions)}
                                                                onApprove={(data, actions) => onApproveSubscription(data, actions)}
                                                                onCancel={() => setBookingSubmitted(false)}
                                                                onError={() => setBookingSubmitted(false)}
                                                                style={{
                                                                    color: 'black',
                                                                    tagline: false
                                                                }}
                                                            />
                                                        </PayPalScriptProvider>
                                                    )
                                            )
                                    )
                            }
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse"
                                }}
                            >
                                {
                                    bookingConfirmed ?
                                        <a href="/" >
                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: pSize
                                                }}
                                            >
                                                Go Home
                                            </button>
                                        </a>
                                        :
                                        bookingError ?
                                            <a href="/" >
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        fontSize: pSize
                                                    }}
                                                >
                                                    Go Home
                                                </button>
                                            </a>
                                            :
                                            <button
                                                className="f2f"
                                                style={{
                                                    border: "none",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                    backgroundColor: Colors.offBlack,
                                                    color: Colors.offwhite,
                                                    height: "10%",
                                                    fontSize: pSize,
                                                    borderRadius: 10,
                                                    fontFamily: "bebas",
                                                }}
                                                onClick={() => setconfirmModalOpen(false)}
                                            >
                                                Back
                                            </button>
                                }

                                {
                                    bookingSubmitted && !bookingConfirmed && !bookingError ?
                                        (
                                            <Loader
                                                type="TailSpin"
                                                color={Colors.offwhite}
                                                width={30}
                                                height={30}
                                                style={{
                                                    marginRight: "1vw",
                                                    alignSelf: 'center',
                                                }}
                                            />
                                        )
                                        :
                                        (
                                            null
                                        )
                                }
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal
                    isOpen={planDetailsModalOpen}
                    onRequestClose={() => setPlanDetailsModalOpen(false)}
                    style={{
                        content: {
                            width: "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            maxHeight: "75vh",
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >

                        <h2
                            style={{
                                fontSize: t3Size
                            }}
                        >
                            We offer three tiers for our 12 week virtual program, see below for details.
                        </h2>


                        <button
                            className="f2f"
                            style={{
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                                backgroundColor: Colors.offBlack,
                                color: Colors.offwhite,
                                fontSize: p2Size,
                                borderRadius: 10,
                                fontFamily: "bebas",
                                fontweight: 200,
                                height: pSize,
                                alignSelf: "center"
                            }}
                            onClick={() => setPlanDetailsModalOpen(false)}
                        >
                            Back
                        </button>

                    </div>

                    <div
                        style={
                            props.deviceType === 'mobile' ?
                                {
                                }
                                :
                                {
                                    display: "flex",
                                    justifyContent: "space-around"
                                }
                        }
                    >
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >
                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Getting you started package:
                            </h4>
                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have little know how when it comes to training and I would love all the info you have to get started'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $85/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Initial virtual consultation</li>
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>12x week program tailored to your goals</li>
                                <li>Tailored assessments and record keeping</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >

                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Body Balancing Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I am prepared to meal prep, track calories, and submit feedback along my journey.'</h4>


                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $75/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Initial virtual consultation</li>
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>Tailored fitness program up to 3x weekly sessions (self monitored, updated monthly)</li>
                                <li>Tailored strength, endurance, flexibility and composition assessments</li>
                                <li>Daily wellness checklist</li>
                                <li>2x virtual nutrition consultations</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >
                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Totally Boss Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have great nutritional literacy; I just need my macronutrients portioned and someone to hold me accountable - no need for a meal plan.'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $60/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Initial virtual consultation</li>
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>Tailored fitness program up to 5x weekly sessions (PT monitored, updated weekly)</li>
                                <li>Tailored strength, endurance, flexibility and composition assessments</li>
                                <li>Daily wellness checklist</li>
                                <li>3x virtual nutrition consultations</li>
                                <li>Final client comparison profile</li>
                                <li>Free group sessions</li>
                            </ul>
                        </div>
                    </div>

                </Modal>


                <div>

                    <h1
                        style={{
                            color: Colors.offwhite,
                            fontFamily: "bebas",
                            fontWeight: 200,
                            fontSize: t1Size,
                            marginBottom: 0,
                            marginTop: "10vh"
                        }}
                    >
                        Book online sessions below
                    </h1>
                    {
                        !isPT && !isManager && hasfreelesson ?
                            (
                                <p
                                    style={{
                                        color: Colors.lightPink,
                                        fontFamily: "bebas",
                                        fontWeight: 200,
                                        textAlign: "center",
                                        marginBottom: 0,
                                        fontSize: pSize,
                                        marginTop: 0
                                    }}
                                >
                                    (Book your free consultation to chat about which package suits your goals, <br /> lifestyle & budget, and how to combine fitness & nutrition packages).
                                </p>
                            )
                            :
                            (
                                null
                            )
                    }
                </div>
                {
                    isManager ?
                        (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    marginBottom: "2vh"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        color: Colors.pink,
                                        fontweight: 1000,
                                        fontSize: t2Size,
                                        fontFamily: "bebas",
                                    }}
                                >
                                    client:
                                </p>
                                <select
                                    value={selectedClient.email}
                                    onChange={handleClientChange}
                                    style={{
                                        whiteSpace: "pre",
                                        alignSelf: "center",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {
                                        allClients.map((i) => (
                                            <option
                                                value={i.email}
                                            >{i.firstName} {i.lastName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        )
                        :
                        null
                }
                <button
                    className="f2f"
                    style={{
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        backgroundColor: Colors.pink,
                        color: Colors.offwhite,
                        height: "10%",
                        fontSize: pSize,
                        borderRadius: 10,
                        fontFamily: "bebas",
                        fontweight: 200,
                        position: "relative",
                        left: props.deviceType === "desktop" ? "-21vw" : "-30vw",
                        marginBottom: "2vw"
                    }}
                    onClick={() => setOnlineF2f(null)}
                >
                    Back
                </button>
                <div
                    style={props.deviceType === 'mobile' ? {
                        fontSize: pSize,
                        width: '90vw',
                        color: Colors.offwhite,
                        fontFamily: "bebas",
                        border: "0.5vw solid #fda6dc",
                        fontWeight: 200,
                    } : {
                        display: "flex",
                        fontSize: pSize,
                        color: Colors.offwhite,
                        fontFamily: "bebas",
                        border: "0.5vw solid #fda6dc",
                        fontWeight: 200,
                    }
                    }
                >

                    <div
                        style={{
                            padding: "1vw 1vw",
                        }}
                    >

                        <p
                            style={props.deviceType === 'mobile' ? {
                                fontSize: p2Size,
                                fontFamily: "robotoslab",
                            } : {
                                fontSize: p2Size,
                                width: props.deviceType === "desktop" ? "15vw" : "25vw",
                                fontFamily: "robotoslab",
                                marginTop: 0,
                                marginBottom: 0
                            }
                            }
                        >
                            Your first session will be a virtual consultation where you can set your schedule and goals. Virtual PT sessions will continue for 11 weeks afterwards. Program details can be found <span onClick={() => setPlanDetailsModalOpen(true)} style={{ color: Colors.pink, cursor: "pointer", textDecoration: "underline" }}>Here.</span>
                        </p>
                        <p
                            style={{
                                fontSize: p2Size,
                                fontFamily: "robotoslab",
                                marginBottom: 5,
                                marginTop: 30
                            }}
                        >
                            Program Type:
                        </p>

                        <div
                            style={{
                                display: "flex",
                                alignItems: 'center',
                                maxWidth: '350px',
                                marginBottom: '20px'
                            }}
                        >

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 0,
                                    marginTop: 0,
                                    display: repeatWeeks == 12 && withPT ? "block" : "none",
                                }}
                            >
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(3)}
                                    style={{
                                        backgroundColor: lessonTier === 3 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Getting You Started Package:</span><br /> I have little know how when it comes to
                                        training and I would love all the info you have to get started!</label>
                                </button>
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(2)}
                                    style={{
                                        backgroundColor: lessonTier === 2 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Body Balancing Package:</span> <br />I am prepared to train outside of sessions
                                        as well as track my wellness and submit feedback throughout my
                                        journey!</label>
                                </button>
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(1)}
                                    style={{
                                        backgroundColor: lessonTier === 1 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Totally Boss Package:</span> <br />I have been training for a while! I need
                                        to fine tune my program and someone to keep me accountable!</label>
                                </button>
                            </div>
                        </div>


                        <div
                            style={{
                                display: "flex",
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: p2Size,
                                    fontFamily: "robotoslab",
                                    marginBottom: 5,
                                    marginTop: 5
                                }}
                            >
                                PT:
                            </p>


                            <select
                                value={selectedPT.email}
                                onChange={handlePTChange}
                                disabled={!withPT}
                                style={{
                                    overflow: "hidden",
                                    whiteSpace: "pre",
                                    marginLeft: 10,
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {
                                    allPTs.map((i) => (
                                        <option
                                            value={i.email}
                                        >{i.firstName} {i.lastName}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: p2Size,
                                    fontFamily: "robotoslab",
                                    marginBottom: 5,
                                    marginTop: 5,
                                    marginRight: 10
                                }}
                            >
                                Start Date:
                            </p>

                            <DatePicker
                                selected={date}
                                onChange={(date) => {
                                    setChosenDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="react-datepicker-ignore-onclickoutside"
                            />
                        </div>

                        <p
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        fontSize: '4vw',
                                        marginBottom: 0,
                                    }
                                    :
                                    {
                                        marginBottom: 0,
                                    }
                            }
                        >
                            Cost per week:
                            <span style={{ color: Colors.pink }}>
                                {hasfreelesson && repeatWeeks == 1 && arrSelectedDays.length == 1 || isDiscounted && discountedPrice == 0 || isManager ? " Free" : withPT ? isDiscounted ? " $" + discountedPrice * arrSelectedDays.length : " $" + pricePerWeek : " $" + pricePerWeek}
                            </span>
                            <br />
                            Total over {repeatWeeks} weeks:
                            <span style={{ color: Colors.pink }}>
                                {hasfreelesson && repeatWeeks == 1 && arrSelectedDays.length == 1 || isDiscounted && discountedPrice == 0 || isManager ? " Free" : withPT ? isDiscounted ? " $" + discountedPrice * repeatWeeks * arrSelectedDays.length : " $" + totalPrice : " $" + totalPrice}
                            </span>
                        </p>

                    </div>


                    <div
                        style={{
                            display: "flex",
                            width: props.deviceType === "desktop" ? "25vw" : "35vw",
                            padding: "1vw 1vw",
                            flexDirection: "column",
                        }}
                    >

                        <p
                            style={{
                                marginTop: "0.5vw",
                                marginBottom: 0
                            }}
                        >
                            Available Timeslots:
                        </p>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginTop: 5,
                                fontSize: "20px",
                            }}
                        >
                            {
                                (availTimes.length && selectedPT.email !== "") ?
                                    (
                                        availTimes.map((i, ii) => (
                                            <div
                                                style={
                                                    (selectedTimeSlot === null || selectedTimeSlot === ii) ?
                                                        {
                                                            backgroundColor: Colors.pink,
                                                            color: "white",
                                                            fontWeight: 200,
                                                            width: 65,
                                                            height: 25,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            marginRight: 20,
                                                            marginBottom: 20,
                                                            borderRadius: 5,
                                                        }
                                                        :
                                                        {
                                                            backgroundColor: "#888888",
                                                            color: "white",
                                                            fontWeight: 200,
                                                            width: 65,
                                                            height: 25,
                                                            justifyContent: "center",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            marginRight: 20,
                                                            marginBottom: 20,
                                                            borderRadius: 5,
                                                        }
                                                }
                                                onClick={() => setselectedTimeSlot(ii)}
                                            >
                                                <label
                                                    style={{
                                                        alignSelf: "center",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setselectedTimeSlot(ii)}
                                                >
                                                    {i}
                                                </label>
                                            </div>
                                        ))

                                    )
                                    :
                                    (
                                        <label style={{ fontSize: p2Size }}> No timeslots available.</label>
                                    )
                            }
                        </div>


                        <button
                            style={
                                ((selectedTimeSlot || selectedTimeSlot === 0) && availTimes.length && selectedPT.email !== "") ?
                                    {
                                        display: "block",
                                        backgroundColor: Colors.pink,
                                        border: "none",
                                        color: "white",
                                        width: props.deviceType === "desktop" ? "33%" : props.deviceType === "mobile" ? '50%' : "20%",
                                        alignSelf: 'center',
                                        borderRadius: 10,
                                        cursor: "pointer"

                                    } : {
                                        display: "none",
                                    }}
                            onClick={() => {
                                setIsGroupBooking(false);
                                setconfirmModalOpen(true);
                                setstringDate(date.getDate() + "/" + (date.getMonth() + 1) + '/' + date.getFullYear());
                            }}
                        >
                            Book
                        </button>
                    </div>

                </div>

            </div>
        )
    }
    else if (loggedIn && onlineF2F === "nutrition") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100vw",
                    minHeight: "93vh",
                    backgroundColor: Colors.offBlack
                }}
            >

                <Modal
                    isOpen={confirmModalOpen}
                    onRequestClose={() => setconfirmModalOpen(true)}
                    style={{
                        content: {
                            width: props.deviceType == "desktop" ? "30vw" : "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: pSize
                        }
                    }}
                >
                    {
                        isManager ?
                            bookingConfirmed ?
                                (
                                    <div>
                                        <p>Success! your booking has been finalised.</p>
                                        <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
                                    </div>
                                )
                                :
                                (
                                    (
                                        <div>

                                            <h2 style={{ fontSize: t2Size, marginTop: 0, marginBottom: 0 }}>Confirm Booking</h2>
                                            {
                                                selectedPT.email ?
                                                    (
                                                        bookingConfirmed ?
                                                            (
                                                                <>
                                                                    <p>Success! your booking has been finalised.</p>
                                                                    <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                bookingError ?
                                                                    (
                                                                        <>
                                                                            <label>This is embarassing but something has gone wrong on our end.</label>
                                                                            <label>We've received your payment but were unable to create a record for your PT session.</label>
                                                                            <label>Please send us an email at info@bosssquadtraining.com and we'll sort it out for you.</label>
                                                                            <label>Apologies for the inconvenience.</label>
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <p>Booking a nutrition consult for {selectedClient.firstName} with PT {selectedPT.firstName} at {availTimes[selectedTimeSlot]} on {stringDate}.</p>
                                                                    )
                                                            )
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                            <button
                                                style={{
                                                    backgroundColor: "#2C2E2F",
                                                    color: Colors.offwhite,
                                                    fontFamily: "Bebas",
                                                    border: "none",
                                                    maxWidth: "750px",
                                                    fontSize: "1.5vw",
                                                    borderRadius: "3px",
                                                    cursor: "pointer",
                                                    padding: "0.5vw 0.5vw",
                                                    marginBottom: "2vh",
                                                    marginTop: "2vh"
                                                }}
                                                onClick={() => {
                                                    BookSingleSession();
                                                }}
                                            >
                                                Confirm
                                            </button>

                                        </div>
                                    )

                                )
                            :
                            (
                                <div>
                                    {
                                        !bookingConfirmed ?
                                            bookingError ?
                                                (
                                                    <h2 style={{ fontSize: t2Size, marginTop: 0, marginBottom: 0 }}>Uh Oh</h2>
                                                )
                                                :
                                                (<h2 style={{ fontSize: t2Size, marginTop: 0, marginBottom: 0 }}>Confirm Booking</h2>
                                                )
                                            :
                                            (
                                                <h2>Booking Confirmed!</h2>
                                            )
                                    }
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: pSize
                                        }}
                                    >
                                        {
                                            selectedPT.email ?
                                                (
                                                    bookingConfirmed ?
                                                        (
                                                            <>
                                                                <p>Success! your booking has been finalised.</p>
                                                                <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            bookingError ?
                                                                (
                                                                    <>
                                                                        <label>This is embarassing but something has gone wrong on our end.</label>
                                                                        <label>We've received your payment but were unable to create a record for your PT session.</label>
                                                                        <label>Please send us an email at info@bosssquadtraining.com and we'll sort it out for you.</label>
                                                                        <label>Apologies for the inconvenience.</label>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <p>Your first nutrition coaching session will be on {stringDate} at {chosenNutritionTime} with {selectedPT.firstName}.</p>
                                                                )
                                                        )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                        {
                                            bookingConfirmed ?
                                                (
                                                    null
                                                )
                                                :
                                                (
                                                    <div
                                                        style={{
                                                            marginTop: "2vh",
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}
                                                    >

                                                        <PayPalScriptProvider options={paypaloptions}>
                                                            <PayPalButtons
                                                                createOrder={(data, actions) => createOrder(data, actions)}
                                                                onApprove={(data, actions) => onApproveOrder(data, actions)}
                                                                onCancel={() => setBookingSubmitted(false)}
                                                                onError={() => setBookingSubmitted(false)}
                                                                style={{
                                                                    color: 'black',
                                                                    tagline: false
                                                                }}
                                                            />
                                                        </PayPalScriptProvider>

                                                    </div>
                                                )
                                        }
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row-reverse"
                                            }}
                                        >
                                            {
                                                bookingConfirmed ?
                                                    <a href="/" >
                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: pSize
                                                            }}
                                                        >
                                                            Go Home
                                                        </button>
                                                    </a>
                                                    :
                                                    bookingError ?
                                                        <a href="/" >
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize: pSize
                                                                }}
                                                            >
                                                                Go Home
                                                            </button>
                                                        </a>
                                                        :
                                                        <button
                                                            className="f2f"
                                                            style={{
                                                                border: "none",
                                                                outline: "none",
                                                                cursor: "pointer",
                                                                backgroundColor: Colors.offBlack,
                                                                color: Colors.offwhite,
                                                                height: "10%",
                                                                fontSize: pSize,
                                                                borderRadius: 10,
                                                                fontFamily: "bebas",
                                                            }}
                                                            onClick={() => setconfirmModalOpen(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                            }

                                            {
                                                bookingSubmitted && !bookingConfirmed && !bookingError ?
                                                    (
                                                        <Loader
                                                            type="TailSpin"
                                                            color={Colors.offwhite}
                                                            width={30}
                                                            height={30}
                                                            style={{
                                                                marginRight: "1vw",
                                                                alignSelf: 'center',
                                                            }}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </Modal>

                <Modal
                    isOpen={nutritionDetailsModalOpen}
                    // onRequestClose={() => setNutritionDetailsModalOpen(true)}
                    style={{
                        content: {
                            width: "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            maxHeight: "75vh",
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >

                        <h2
                            style={{
                                margin: 'unset',
                                marginBottom: '20px',
                                fontSize: t3Size
                            }}
                        >
                            We offer three tiers for our nutrition coaching program, see below for details
                        </h2>


                        <button
                            className="f2f"
                            style={{
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                                backgroundColor: Colors.offBlack,
                                color: Colors.offwhite,
                                fontSize: p2Size,
                                borderRadius: 10,
                                fontFamily: "bebas",
                                fontweight: 200,
                                height: pSize,
                                alignSelf: "center"
                            }}
                            onClick={() => setNutritionDetailsModalOpen(false)}
                        >
                            Back
                        </button>

                    </div>

                    <div
                        style={
                            props.deviceType === 'mobile' ?
                                {
                                }
                                :
                                {
                                    display: "flex",
                                    justifyContent: "space-around"
                                }
                        }
                    >
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >

                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Getting you started package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have little know-how when it comes to nutrition and would love all the information you have.'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: '0px',
                                    textAlign: 'center',
                                    marginBottom: 0,
                                    fontSize: p2Size
                                }}
                            >
                                $275 total
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                    marginLeft: '-10px'
                                }}
                            >
                                <li>4x in person or online nutrition consultations along your journey</li>
                                <li>4x Body scans on our Tanita composition scales</li>
                                <li>Calorie and macronutrient targets tailored to your program goal</li>
                                <li>7 day meal plan changed fortnightly with flexible meal options (for anyone who loves variety)</li>
                                <li>Breakfast, lunch, dinner and snacks planned RECIPES PROVIDED!</li>
                                <li>Daily nutrition and wellness check-ins with ongoing feedback</li>
                                <li>Daily nutrition and fitness support along your journey (recommended for anyone who has struggled or never tracked calories previously)</li>
                                <li>Full Initial and final assessments tailored to your goal</li>
                                <li>Access to our exclusive Facebook Squad groups</li>
                                <li>Reduced rate Personal Training sessions</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >

                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Body Balancing Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I am prepared to meal prep, track calories, and submit feedback along my journey.'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: '0px',
                                    textAlign: 'center',
                                    marginBottom: 0,
                                    fontSize: p2Size
                                }}
                            >
                                $200 total
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>3x in person or online nutrition consultations along your journey</li>
                                <li>3x Body scans on our Tanita composition scales</li>
                                <li>Calorie and macronutrient targets tailored to your program goal</li>
                                <li>7 day meal plan changed every three weeks with flexible meal options (I like variety)</li>
                                <li>Breakfast, lunch, dinner planned RECIPES PROVIDED!</li>
                                <li>Daily nutrition support with ongoing feedback</li>
                                <li>Weekly wellness check-ins</li>
                                <li>Full Initial and final assessments tailored to your goal</li>
                                <li>Access to our exclusive Facebook Squad Groups</li>
                                <li>Reduced rate Personal Training session</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >

                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Totally Boss Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have great nutritional literacy; I just need my macronutrients portioned and someone to hold me accountable - no need for a meal plan.'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: '0px',
                                    textAlign: 'center',
                                    marginBottom: 0,
                                    fontSize: p2Size
                                }}
                            >
                                $150 total
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>2x in person or online nutrition consultations along your journey</li>
                                <li>2x Body scans on our Tanita composition scales</li>
                                <li>Calorie and macronutrient targets tailored to your program goal</li>
                                <li>Daily nutrition support with ongoing feedback</li>
                                <li>Full Initial and final assessments tailored to your goal</li>
                                <li>Access to our exclusive Facebook Squad Groups</li>
                                <li>Reduced rate Personal Training sessions</li>
                            </ul>
                        </div>
                    </div>

                </Modal>
                <div
                    style={{
                        minHeight: "93vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div>
                        <h1
                            style={{
                                color: Colors.offwhite,
                                fontFamily: "bebas",
                                fontWeight: 200,
                                fontSize: t1Size,
                                marginBottom: 0,
                                marginTop: "10vh",
                                textAlign: "center"
                            }}
                        >
                            Book nutrition coaching sessions below
                        </h1>
                        {
                            !isPT && !isManager && hasFreeNutritionSession ?
                                (
                                    <p
                                        style={{
                                            color: Colors.lightPink,
                                            fontFamily: "bebas",
                                            fontWeight: 200,
                                            textAlign: "center",
                                            marginBottom: 0,
                                            fontSize: pSize,
                                            marginTop: 0
                                        }}
                                    >
                                        (Book your free consultation to chat about which package suits your goals, <br /> lifestyle & budget, and how to combine fitness & nutrition packages).
                                    </p>
                                )
                                :
                                (
                                    null
                                )
                        }

                    </div>

                    {
                        isManager ?
                            (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: 'center',
                                        marginBottom: "2vh"
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            color: Colors.pink,
                                            fontweight: 1000,
                                            fontSize: t2Size,
                                            fontFamily: "bebas",
                                        }}
                                    >
                                        client:
                                    </p>
                                    <select
                                        value={selectedClient.email}
                                        onChange={handleClientChange}
                                        style={{
                                            whiteSpace: "pre",
                                            alignSelf: "center",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {
                                            allClients.map((i) => (
                                                <option
                                                    value={i.email}
                                                >{i.firstName} {i.lastName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            )
                            :
                            null
                    }
                    <button
                        className="f2f"
                        style={{
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            backgroundColor: Colors.pink,
                            color: Colors.offwhite,
                            height: "10%",
                            fontSize: pSize,
                            borderRadius: 10,
                            fontFamily: "bebas",
                            fontweight: 200,
                            position: "relative",
                            left: props.deviceType === "desktop" ? "-21vw" : "-35vw",
                            marginBottom: "2vw"
                        }}
                        onClick={() => setOnlineF2f(null)}
                    >
                        Back
                    </button>
                    <div
                        style={
                            props.deviceType === 'mobile' ?
                                {
                                    fontSize: pSize,
                                    color: Colors.offwhite,
                                    fontFamily: "bebas",
                                    width: '90vw',
                                    border: "0.5vw solid #fda6dc",
                                    fontWeight: 200,
                                }
                                :
                                {
                                    display: "flex",
                                    fontSize: pSize,
                                    color: Colors.offwhite,
                                    fontFamily: "bebas",
                                    border: "0.5vw solid #fda6dc",
                                    fontWeight: 200,
                                }
                        }
                    >
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        padding: "1vw 1vw",
                                    }
                                    :
                                    {
                                        width: props.deviceType === "desktop" ? "25vw" : "20vw",
                                        padding: "1vw 1vw",
                                    }
                            }
                        >

                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 20,
                                    fontSize: pSize,
                                    fontFamily: "robotoslab"
                                }}
                            >
                                Nutrition coaching gives you an opportunity to formulate a realistic meal plan to help you achieve your fitness goals. See
                                <span onClick={() => setNutritionDetailsModalOpen(true)}
                                    style={{
                                        color: Colors.pink,
                                        cursor: 'pointer',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        textDecoration: "underline"
                                    }}
                                >here</span>
                                for details on our plan options.
                            </p>


                            <div
                                style={{
                                    alignItems: "center",
                                    marginBottom: '20px',
                                    marginTop: 0,
                                }}
                            >
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier('FREE')}
                                    style={{
                                        display: hasFreeNutritionSession ? 'block' : 'none',
                                        backgroundColor: lessonTier === 'FREE' ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>FREE CONSULT:</span><br /> Book your free consult now! </label>
                                </button>
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(3)}
                                    style={{
                                        backgroundColor: lessonTier === 3 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Getting You Started Package:</span><br /> I have little know-how when it comes to nutrition and
                                        would love all the information you have</label>
                                </button>
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(2)}
                                    style={{
                                        backgroundColor: lessonTier === 2 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Body Balancing Package:</span> <br />I am prepared to meal prep, track calories, and
                                        submit feedback along my journey</label>
                                </button>
                                <button
                                    className='f2f'
                                    onClick={() => setLessonTier(1)}
                                    style={{
                                        backgroundColor: lessonTier === 1 ? "purple" : "grey",
                                        outline: "none",
                                        marginTop: '10px',
                                        width: '95%',
                                        fontweight: "bold",
                                        fontSize: p3Size,
                                        textAlign: 'left',
                                        padding: '10px',
                                        marginLeft: 10,
                                        border: "none",
                                        cursor: "pointer",
                                        color: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Totally Boss Package:</span> <br />I have great nutritional literacy and I just need my
                                        macronutrients portioned and someone to keep me accountable, no need for a
                                        meal plan</label>
                                </button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: 5,
                                    marginBottom: 5
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginRight: 10,
                                        fontFamily: "robotoslab"
                                    }}
                                >
                                    PT:
                                </p>

                                <select
                                    value={selectedPT.email}
                                    onChange={handlePTChange}
                                    disabled={!withPT}
                                    style={{
                                        overflow: "hidden",
                                        whiteSpace: "pre",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {
                                        withPT ?
                                            (
                                                allPTs.map((i) => (
                                                    <option
                                                        value={i.email}
                                                    >{i.firstName} {i.lastName}</option>
                                                ))
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }
                                </select>
                            </div>

                            <p
                                style={{
                                    marginTop: 20,
                                    marginBottom: 5,
                                    fontFamily: "robotoslAB"
                                }}
                            >
                                Total cost:
                                <span style={{ color: Colors.pink }}>
                                    {lessonTier === 'FREE' ? nutritionPrice : " $" + nutritionPrice}
                                </span>
                            </p>


                        </div>


                        <div
                            style={props.deviceType === 'mobile' ? {
                                padding: "0.5vw 0",
                                display: "flex",
                                flexDirection: "column"
                            } : {

                                width: props.deviceType === "desktop" ? "34vw" : "50vw",
                                padding: "0.5vw 0",
                                display: "flex",
                                flexDirection: "column"
                            }
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: '100%',
                                    marginTop: '20px',
                                    justifyContent: 'space-evenly',
                                    flexWrap: "wrap"
                                }}
                            >

                                {
                                    noNutritionSessions.map((day, i) => (

                                        <div
                                            style={{
                                                background: Colors.pink,
                                                fontFamily: "robotoslab",
                                                marginLeft: "0.5vw",
                                                marginRight: "0.5vw",
                                                borderRadius: '10px',
                                                marginBottom: "1vw",
                                                fontSize: p2Size,
                                                height: 'auto',
                                                padding: "10px 10px"
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: pSize,
                                                    marginTop: 0,
                                                    fontFamily: "bebas"
                                                }}
                                            >
                                                Session {i + 1}
                                            </p>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: 5,
                                                    marginBottom: 5
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontFamily: "robotoslab",
                                                        marginRight: 10
                                                    }}
                                                >
                                                    Date:
                                                </p>

                                                <DatePicker
                                                    selected={nutritionDates[i]}
                                                    onChange={(d) => {
                                                        const x = [...nutritionDates];
                                                        x[i] = d
                                                        setNutritionDates(x);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="react-datepicker-ignore-onclickoutside"
                                                />
                                            </div>

                                            <br />

                                            <label>Time: </label>
                                            <select
                                                value={selectedNutrionTimes[i]}
                                                onChange={(e) => {
                                                    let t = [...selectedNutrionTimes];
                                                    t[i] = e.target.value;

                                                    setSelectedNutritionTimes(t);
                                                }}
                                            >
                                                {
                                                    allNutritionTimes[i].map((ii) => (
                                                        <option
                                                            key={ii}
                                                            value={ii}
                                                        >
                                                            {ii}
                                                        </option>
                                                    ))
                                                }
                                            </select>

                                        </div>


                                    ))
                                }
                            </div>

                            <button
                                style={
                                    {
                                        display: showNutritionBookButton() ? 'block' : 'none',
                                        backgroundColor: Colors.pink,
                                        border: "none",
                                        color: "white",
                                        fontSize: t3Size,
                                        fontFamily: "bebas",
                                        alignSelf: "center",

                                        marginTop: "20px",
                                        borderRadius: 10,
                                        cursor: "pointer",
                                    }}
                                onClick={() => {
                                    setIsGroupBooking(false);
                                    setconfirmModalOpen(true);
                                    const times = [...selectedNutrionTimes];
                                    const dates = [...nutritionDates];
                                    const objs = [];
                                    for (let k = 0; k < times.length; k++) {
                                        objs.push({ time: times[k], date: dates[k] })
                                    }
                                    objs.sort(function (a, b) {
                                        return new Date(a.date) - new Date(b.date);
                                    });
                                    setChosenNutritionTime(objs[0].time);
                                    setstringDate(objs[0].date.getDate() + "/" + (objs[0].date.getMonth() + 1) + '/' + objs[0].date.getFullYear());
                                    // BookSingleSession();
                                }}
                            >
                                Book
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (loggedIn && onlineF2F === "bootcamp") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100vw",
                    minHeight: "93vh",
                    backgroundColor: Colors.offBlack
                }}
            >

                <Modal
                    isOpen={confirmModalOpen}
                    onRequestClose={() => setconfirmModalOpen(true)}
                    style={{
                        content: {
                            width: props.deviceType == "desktop" ? "30vw" : "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            fontSize: pSize,
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >
                    {
                        isManager ?
                            bookingConfirmed ?
                                (
                                    <div>
                                        <p>Success! your booking has been finalised.</p>
                                        <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
                                    </div>
                                )
                                :
                                (
                                    <div>


                                        <p style={{ fontSize: pSize }}>{selectedClient.firstName} {selectedClient.lastName} will be booked on {stringDate}.</p>
                                        <button
                                            style={{
                                                backgroundColor: "#2C2E2F",
                                                color: Colors.offwhite,
                                                fontFamily: "Bebas",
                                                border: "none",
                                                maxWidth: "750px",
                                                fontSize: "1.5vw",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                padding: "0.5vw 0.5vw",
                                                marginBottom: 0,
                                                marginTop: 0
                                            }}
                                            onClick={() => {
                                                BookGroupSession();
                                            }}
                                        >
                                            Confirm
                                        </button>

                                    </div>
                                )
                            :
                            (
                                <div>
                                    {
                                        !bookingConfirmed ?
                                            bookingError ?
                                                (
                                                    <h2 style={{ marginBottom: 0, marginTop: 0, fontSize: t2Size }}>Uh Oh</h2>
                                                )
                                                :
                                                (
                                                    <h2 style={{ marginBottom: 0, marginTop: 0, fontSize: t2Size }}>Confirm Booking</h2>
                                                )
                                            :
                                            (
                                                <h2 style={{ marginBottom: 0, marginTop: 0, fontSize: t2Size }}>Booking Confirmed!</h2>
                                            )
                                    }
                                    {
                                        bookingConfirmed ?
                                            <p style={{ fontSize: pSize }}>Sucess! Booking confirmed</p>
                                            :
                                            (
                                                discountedPrice === 0 ?
                                                    <p style={{ fontSize: pSize }}>Your session will be {isThrusdayBootCamp ? 'at' : 'on'} {stringDate}.</p>
                                                    :
                                                    <p style={{ fontSize: pSize }}>Your session will be {isThrusdayBootCamp ? 'at' : 'on'} {stringDate}. <br /> You can pay $10 cash on arrival, or online now.</p>
                                            )
                                    }
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: pSize
                                        }}
                                    >

                                        {
                                            bookingConfirmed ?
                                                (
                                                    null
                                                )
                                                :
                                                (
                                                    <div
                                                        style={{
                                                            marginTop: "2vh",
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}
                                                    >
                                                        {
                                                            discountedPrice === 0 ?

                                                                <button
                                                                    style={{
                                                                        backgroundColor: "#2C2E2F",
                                                                        color: Colors.offwhite,
                                                                        fontFamily: "Bebas",
                                                                        border: "none",
                                                                        maxWidth: "750px",
                                                                        fontSize: "1.5vw",
                                                                        borderRadius: "3px",
                                                                        cursor: "pointer",
                                                                        padding: "0.5vw 0.5vw",
                                                                        marginBottom: "2vh",
                                                                        marginTop: "2vh"
                                                                    }}
                                                                    onClick={() => {
                                                                        BookGroupSession();
                                                                    }}
                                                                >
                                                                    Confirm
                                                                </button>
                                                                : hidePaypal ? null : (
                                                                    <div>

                                                                        <button
                                                                            style={{
                                                                                backgroundColor: "#2C2E2F",
                                                                                color: Colors.offwhite,
                                                                                fontFamily: "Bebas",
                                                                                border: "none",
                                                                                maxWidth: "750px",
                                                                                fontSize: "3vw",
                                                                                borderRadius: "3px",
                                                                                cursor: "pointer",
                                                                                width: "100%",
                                                                                padding: "0.5vw 0.5vw",
                                                                                marginBottom: "2vh"
                                                                            }}
                                                                            onClick={() => {
                                                                                setPayingCashGroup(true);
                                                                            }}
                                                                        >
                                                                            Cash on Arrival
                                                                        </button>
                                                                        <PayPalScriptProvider options={paypaloptions}>
                                                                            <PayPalButtons
                                                                                createOrder={(data, actions) => createGroupOrder(data, actions)}
                                                                                onApprove={(data, actions) => onApproveGroupOrder(data, actions)}
                                                                                onCancel={() => setBookingSubmitted(false)}
                                                                                onError={() => setBookingSubmitted(false)}
                                                                                style={{
                                                                                    color: 'black',
                                                                                    tagline: false
                                                                                }}
                                                                            />
                                                                        </PayPalScriptProvider>
                                                                    </div>
                                                                )}

                                                    </div>
                                                )
                                        }
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row-reverse"
                                            }}
                                        >
                                            {
                                                bookingConfirmed ?
                                                    <a href="/" >
                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: pSize
                                                            }}
                                                        >
                                                            Go Home
                                                        </button>
                                                    </a>
                                                    :
                                                    bookingError ?
                                                        <a href="/" >
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize: pSize
                                                                }}
                                                            >
                                                                Go Home
                                                            </button>
                                                        </a>
                                                        :
                                                        <button
                                                            className="f2f"
                                                            style={{
                                                                border: "none",
                                                                outline: "none",
                                                                cursor: "pointer",
                                                                backgroundColor: Colors.offBlack,
                                                                color: Colors.offwhite,
                                                                height: "10%",
                                                                fontSize: pSize,
                                                                borderRadius: 10,
                                                                fontFamily: "bebas",
                                                            }}
                                                            onClick={() => setconfirmModalOpen(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                            }

                                            {
                                                bookingSubmitted && !bookingConfirmed && !bookingError ?
                                                    (
                                                        <Loader
                                                            type="TailSpin"
                                                            color={Colors.offwhite}
                                                            width={30}
                                                            height={30}
                                                            style={{
                                                                marginRight: "1vw",
                                                                alignSelf: 'center',
                                                            }}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </Modal>

                <div
                    style={{
                        minHeight: "93vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                        }}
                    >

                        <h1
                            style={{
                                color: Colors.offwhite,
                                fontFamily: "bebas",
                                fontWeight: 200,
                                fontSize: t1Size,
                                marginBottom: 0,
                                marginTop: "10vh",
                                textAlign: "center"
                            }}
                        >
                            Book bootcamp sessions below
                        </h1>

                    </div>


                    {
                        isManager ?
                            (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: 'center',
                                        marginBottom: "2vh"
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            color: Colors.pink,
                                            fontweight: 1000,
                                            fontSize: t2Size,
                                            fontFamily: "bebas",
                                        }}
                                    >
                                        client:
                                    </p>
                                    <select
                                        value={selectedClient.email}
                                        onChange={handleClientChange}
                                        style={{
                                            whiteSpace: "pre",
                                            alignSelf: "center",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {
                                            allClients.map((i) => (
                                                <option
                                                    value={i.email}
                                                >{i.firstName} {i.lastName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            )
                            :
                            null
                    }
                    <button
                        className="f2f"
                        style={{
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            backgroundColor: Colors.pink,
                            color: Colors.offwhite,
                            height: "10%",
                            fontSize: pSize,
                            borderRadius: 10,
                            fontFamily: "bebas",
                            fontweight: 200,
                            position: "relative",
                            left: props.deviceType === "desktop" ? "-21vw" : "-35vw",
                            marginBottom: "2vw"
                        }}
                        onClick={() => setOnlineF2f(null)}
                    >
                        Back
                    </button>

                    <div

                        style={
                            props.deviceType === 'mobile' ?
                                {
                                    width: "80vw",
                                    padding: "1vw 1vw",
                                    color: Colors.offwhite,
                                    fontFamily: "robotoslab",
                                    border: "0.5vw solid #fda6dc",
                                }
                                :
                                {
                                    width: "30vw",
                                    padding: "1vw 1vw",
                                    color: Colors.offwhite,
                                    fontFamily: "robotoslab",
                                    border: "0.5vw solid #fda6dc",
                                }
                        }
                    >
                        <p
                            style={{
                                marginTop: 0,
                                fontSize: pSize,
                                fontFamily: "robotoslab"
                            }}
                        >
                            Boss Squad Training are now holding bootcamps at the Wamuran Sports Complex.
                            <br />
                            <br />
                            Current session times are:
                            <br />
                            <br />
                            Thursday 5:00pm - 5:30pm & 5:30pm - 6:00pm
                            <br />
                            Saturday 6:00am - 6:45am
                            <br />
                            <br />
                            <span style={{ fontStyle: "italic", fontSize: `calc(${pSize} * 0.75)` }}>
                                Refer a friend and receive your session free!
                            </span>
                        </p>
                        <p>
                            Cost per session:
                            <span style={{ color: Colors.pink }}>
                                {" "} $10
                            </span>
                        </p>

                        <DatePicker
                            selected={groupDate}
                            filterDate={(date) => {
                                const day = date.getDay();
                                const today = new Date(Date.now());
                                today.setDate(today.getDate() + 1);

                                return day !== 0 && day !== 1 && day !== 2 && day !== 3 && day !== 5 && date > today;
                            }}
                            onChange={(date) => {
                                setChosenGroupDate(date);
                                // Thursday class two time options
                                if (date.getDay() === 4) {
                                    setIsThursdayBootCamp(true);
                                } else {
                                    setIsThursdayBootCamp(false);
                                }
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="react-datepicker-ignore-onclickoutside"
                        />


                        {
                            groupSession ?
                                isThrusdayBootCamp ?
                                    (
                                        <div>

                                            <button
                                                onClick={() => {
                                                    setstringDate("5:00pm " + groupDate.getDate() + "/" + (groupDate.getMonth() + 1) + '/' + groupDate.getFullYear())
                                                    // 5pm
                                                    setThursdayGroupTime(17);
                                                    setconfirmModalOpen(true);
                                                    setIsGroupBooking(true);
                                                }}
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 5,
                                                    backgroundColor: Colors.pink,
                                                    border: "none",
                                                    color: "white",
                                                    borderRadius: 10,
                                                    cursor: "pointer"
                                                }}
                                            >
                                                {groupSession[0]}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setstringDate("5:30pm " + groupDate.getDate() + "/" + (groupDate.getMonth() + 1) + '/' + groupDate.getFullYear())
                                                    // 5:30pm
                                                    setThursdayGroupTime(17.5);
                                                    setconfirmModalOpen(true);
                                                    setIsGroupBooking(true);
                                                }}
                                                style={{
                                                    marginLeft: '10px',
                                                    marginTop: 20,
                                                    marginBottom: 5,
                                                    backgroundColor: Colors.pink,
                                                    border: "none",
                                                    color: "white",
                                                    borderRadius: 10,
                                                    cursor: "pointer"
                                                }}
                                            >
                                                {groupSession[1]}
                                            </button>
                                        </div>

                                    )
                                    :
                                    (
                                        <button
                                            onClick={() => {
                                                setstringDate(groupDate.getDate() + "/" + (groupDate.getMonth() + 1) + '/' + groupDate.getFullYear())
                                                setconfirmModalOpen(true);
                                                setIsGroupBooking(true);
                                            }}
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 5,
                                                backgroundColor: Colors.pink,
                                                border: "none",
                                                color: "white",
                                                borderRadius: 10,
                                                cursor: "pointer"
                                            }}
                                        >
                                            {groupSession}
                                        </button>
                                    )
                                :
                                (
                                    null
                                )
                        }

                    </div>
                </div>
            </div>
        );
    }
    //Face to face training
    else {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100vw",
                    minHeight: "93vh",
                    backgroundColor: Colors.offBlack
                }}
            >

                <Modal
                    isOpen={confirmModalOpen}
                    onRequestClose={() => setconfirmModalOpen(true)}
                    style={{
                        content: {
                            width: props.deviceType === "desktop" ? "35vw" : "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            maxHeight: "80vh",
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >
                    <button
                        style={{
                            backgroundColor: "#2C2E2F",
                            color: Colors.offwhite,
                            fontFamily: "Bebas",
                            border: "none",
                            maxWidth: "750px",
                            fontSize: "1.5vw",
                            borderRadius: "3px",
                            cursor: "pointer",
                            padding: "0.5vw 0.5vw",
                            marginBottom: "2vh",
                            marginTop: "2vh"
                        }}
                        onClick={() => setconfirmModalOpen(false)}
                    >
                        Back
                    </button>
                    <ConfirmMessage
                        isFirstFreeSession={hasfreelesson}
                        isDiscounted={isDiscounted}
                        discountedRate={discountedPrice}
                        regularRate={sessionPrice}
                        pricePerWeek={pricePerWeek}
                        repeatWeeks={repeatWeeks}
                        daysPerWeek={arrSelectedDays.length}
                        selectedDays={arrSelectedDays}
                        actualDates={arrSelectedActualDates}
                        stringDate={stringDate}
                        selectedTimes={arrSelectedTimes}
                        isManager={isManager}
                        client={selectedClient}
                        withPT={withPT}
                        PT={selectedPT}
                        bookingConfirmed={bookingConfirmed}
                        BookSingleSession={() => BookSingleSession()}
                        BookRepeatSession={() => BookRepeatSession()}
                        CashOnArrival={() => setPayingCash(true)}
                        paypaloptions={paypaloptions}
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApproveOrder={(data, actions) => onApproveOrder(data, actions)}
                        createSubscription={(data, actions) => createSubscription(data, actions)}
                        onApproveSubscription={(data, actions) => onApproveSubscription(data, actions)}
                        setBookingSubmitted={(value) => setBookingSubmitted(value)}
                        pSize={pSize}
                    />


                </Modal>

                <Modal
                    isOpen={planDetailsModalOpen}
                    onRequestClose={() => setPlanDetailsModalOpen(true)}
                    style={{
                        content: {
                            width: "75vw",
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: "auto",
                            zIndex: 100,
                            maxHeight: "75vh",
                            color: Colors.offwhite,
                            fontFamily: "RobotoSlab",
                            background: Colors.pink,
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: t2Size
                        }
                    }}
                >

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >

                        <h2
                            style={{
                                margin: 'unset',
                                fontSize: t3Size
                            }}
                        >
                            We offer three tiers for our 12 week program, see below for details.
                        </h2>


                        <button
                            className="f2f"
                            style={{
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                                backgroundColor: Colors.offBlack,
                                color: Colors.offwhite,
                                fontSize: p2Size,
                                borderRadius: 10,
                                fontFamily: "bebas",
                                fontweight: 200,
                                height: pSize,
                                alignSelf: "center"
                            }}
                            onClick={() => setPlanDetailsModalOpen(false)}
                        >
                            Back
                        </button>

                    </div>

                    <p style={{ fontSize: pSize }}>Book two or more sessions per week and take $10 off the price of each session!</p>


                    <div
                        style={
                            props.deviceType === 'mobile' ?
                                {
                                }
                                :
                                {
                                    display: "flex",
                                    justifyContent: "space-around"
                                }
                        }
                    >
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >
                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Getting you started package:
                            </h4>
                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have little know how when it comes to training and I would love all the info you have to get started'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $85/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>Tailored PT sessions, assessments and record keeping</li>
                                <li>2x body scans</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >

                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Body Balancing Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I am prepared to meal prep, track calories, and submit feedback along my journey.'</h4>


                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $75/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>Tailored PT sessions</li>
                                <li>Tailored strength, endurance, flexibility and composition assessments</li>
                                <li>Daily wellness checklist</li>
                                <li>2x nutrition consultations</li>
                                <li>External programs up to 2x extra planned sessions (self monitored, changed every 4 weeks)</li>
                                <li>4x body scans</li>
                            </ul>

                        </div>
                        <div
                            style={
                                props.deviceType === 'mobile' ?
                                    {
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        marginBottom: '5vw',
                                        flexDirection: "column",
                                    }
                                    :
                                    {
                                        width: "30%",
                                        background: 'purple',
                                        borderRadius: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                    }
                            }
                        >
                            <h4
                                style={{
                                    marginTop: '10px',
                                    marginLeft: "0.5vw",
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                Totally Boss Package:
                            </h4>

                            <h4
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                    marginLeft: "0.5vw",
                                    marginTop: '20px',
                                    fontStyle: "italic",
                                    fontweight: 100,
                                    fontSize: `calc(${p2Size} * 0.8)`
                                }}>'I have great nutritional literacy; I just need my macronutrients portioned and someone to hold me accountable - no need for a meal plan.'</h4>

                            <h4
                                style={{
                                    marginLeft: "0.5vw",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: pSize
                                }}
                            >
                                $60/week
                            </h4>

                            <ul
                                style={{
                                    fontSize: p3Size,
                                }}
                            >
                                <li>Goal setting</li>
                                <li>Fitness/nutrition program overview & strategy</li>
                                <li>Nutrition advice</li>
                                <li>Tailored personal training sessions</li>
                                <li>Tailored strength, endurance, flexibility and composition assessments</li>
                                <li>Daily wellness checklist</li>
                                <li>3x nutrition consultations</li>
                                <li>External programs up to 4x extra planned sessions (PT monitored)</li>
                                <li>Final client comparison profile</li>
                                <li>Free group sessions</li>
                            </ul>
                        </div>
                    </div>

                </Modal>

                {
                    loggedIn ?
                        (
                            <div
                                style={{
                                    minHeight: "93vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                    }}
                                >
                                    <h1
                                        style={{
                                            color: Colors.offwhite,
                                            fontFamily: "bebas",
                                            fontWeight: 200,
                                            fontSize: t1Size,
                                            textAlign: 'center',
                                            marginBottom: 0,
                                            marginTop: "10vh"
                                        }}
                                    >
                                        Book face to face sessions below
                                    </h1>
                                    {
                                        !isPT && !isManager && hasfreelesson ?
                                            (
                                                <p
                                                    style={{
                                                        color: Colors.lightPink,
                                                        fontFamily: "bebas",
                                                        fontWeight: 200,
                                                        textAlign: "center",
                                                        marginBottom: 0,
                                                        fontSize: pSize,
                                                        marginTop: 0
                                                    }}
                                                >
                                                    (Book your free consultation to chat about which package suits your goals, <br /> lifestyle & budget, and how to combine fitness & nutrition packages).
                                                </p>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }
                                    {
                                        isManager ?
                                            (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: 'center',
                                                        marginBottom: "2vh"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                            color: Colors.pink,
                                                            fontweight: 1000,
                                                            fontSize: t2Size,
                                                            fontFamily: "bebas",
                                                        }}
                                                    >
                                                        client:
                                                    </p>
                                                    <select
                                                        value={selectedClient.email}
                                                        onChange={handleClientChange}
                                                        style={{
                                                            whiteSpace: "pre",
                                                            alignSelf: "center",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {
                                                            allClients.map((i) => (
                                                                <option
                                                                    value={i.email}
                                                                >{i.firstName} {i.lastName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            )
                                            :
                                            null
                                    }
                                    <button
                                        className="f2f"
                                        style={{
                                            border: "none",
                                            outline: "none",
                                            cursor: "pointer",
                                            backgroundColor: Colors.pink,
                                            color: Colors.offwhite,
                                            height: "10%",
                                            marginBottom: "1vh",
                                            fontSize: pSize,
                                            borderRadius: 10,
                                            fontFamily: "bebas",
                                            fontweight: 200,
                                        }}
                                        onClick={() => setOnlineF2f(null)}
                                    >
                                        Back
                                    </button>

                                    <div
                                        style={props.deviceType === 'mobile' ? {
                                            fontSize: pSize,
                                            color: Colors.offwhite,
                                            fontFamily: "bebas",
                                            border: "0.5vw solid #fda6dc",
                                            fontWeight: 200,
                                        } : {
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: pSize,
                                            color: Colors.offwhite,
                                            fontFamily: "bebas",
                                            border: "0.5vw solid #fda6dc",
                                            fontWeight: 200,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: props.deviceType === "desktop" ? "20vw" : props.deviceType === "mobile" ? '90vw' : "40vw",
                                                padding: "1vw 1vw",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontSize: p2Size,
                                                        fontFamily: "robotoslab"
                                                    }}
                                                >
                                                    Session Length:
                                                </p>

                                                <select
                                                    value={sessionLength}
                                                    onChange={(e) => setSessionLength(e.target.value)}
                                                    style={{
                                                        marginLeft: 5
                                                    }}

                                                >
                                                    <option value={60}>60 Minutes</option>
                                                    <option value={30}>30 Minutes</option>
                                                </select>

                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '20px',
                                                    display: "flex",
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontSize: p2Size,
                                                        fontFamily: "robotoslab"
                                                    }}
                                                >
                                                    No. of weeks:
                                                </p>

                                                <select
                                                    value={repeatWeeks}
                                                    onChange={(e) => setRepeatWeeks(e.target.value)}
                                                    style={{
                                                        marginLeft: 5
                                                    }}

                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                </select>

                                            </div>


                                            <p
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 5,
                                                    fontSize: p3Size,
                                                    opacity: 0.8,
                                                    fontFamily: "robotoslab",
                                                    marginLeft: '20px'
                                                }}
                                            >
                                                Choose 12 weeks with a PT for a structured program. <span onClick={() => setPlanDetailsModalOpen(true)} style={{ color: Colors.pink, cursor: "pointer", textDecoration: "underline" }}>Details Here.</span>
                                            </p>

                                            <p
                                                style={{
                                                    display: repeatWeeks == 12 && withPT ? "block" : "none",
                                                    fontSize: p2Size,
                                                    marginBottom: 0,
                                                    fontFamily: "robotoslab"
                                                }}
                                            >
                                                Program Type:
                                            </p>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: 0,
                                                    marginTop: 0,
                                                    display: repeatWeeks == 12 && withPT ? "block" : "none",
                                                }}
                                            >
                                                <button
                                                    className='f2f'
                                                    onClick={() => setLessonTier(3)}
                                                    style={{
                                                        backgroundColor: lessonTier === 3 ? "purple" : "grey",
                                                        outline: "none",
                                                        marginTop: '10px',
                                                        width: '95%',
                                                        fontweight: "bold",
                                                        fontSize: p3Size,
                                                        textAlign: 'left',
                                                        padding: '10px',
                                                        marginLeft: 10,
                                                        border: "none",
                                                        cursor: "pointer",
                                                        color: 'white',
                                                        borderRadius: '10px'
                                                    }}
                                                >
                                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Getting You Started Package:</span><br /> I have little know how when it comes to
                                                        training and I would love all the info you have to get started!</label>
                                                </button>
                                                <button
                                                    className='f2f'
                                                    onClick={() => setLessonTier(2)}
                                                    style={{
                                                        backgroundColor: lessonTier === 2 ? "purple" : "grey",
                                                        outline: "none",
                                                        marginTop: '10px',
                                                        width: '95%',
                                                        fontweight: "bold",
                                                        fontSize: p3Size,
                                                        textAlign: 'left',
                                                        padding: '10px',
                                                        marginLeft: 10,
                                                        border: "none",
                                                        cursor: "pointer",
                                                        color: 'white',
                                                        borderRadius: '10px'
                                                    }}
                                                >
                                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Body Balancing Package:</span> <br />I am prepared to train outside of sessions
                                                        as well as track my wellness and submit feedback throughout my
                                                        journey!</label>
                                                </button>
                                                <button
                                                    className='f2f'
                                                    onClick={() => setLessonTier(1)}
                                                    style={{
                                                        backgroundColor: lessonTier === 1 ? "purple" : "grey",
                                                        outline: "none",
                                                        marginTop: '10px',
                                                        width: '95%',
                                                        fontweight: "bold",
                                                        fontSize: p3Size,
                                                        textAlign: 'left',
                                                        padding: '10px',
                                                        marginLeft: 10,
                                                        border: "none",
                                                        cursor: "pointer",
                                                        color: 'white',
                                                        borderRadius: '10px'
                                                    }}
                                                >
                                                    <label style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>Totally Boss Package:</span> <br />I have been training for a while! I need
                                                        to fine tune my program and someone to keep me accountable!</label>
                                                </button>
                                            </div>


                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: '20px',
                                                    marginBottom: 5
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: p2Size,
                                                        fontFamily: "robotoslab",
                                                        marginBottom: 0,
                                                        marginTop: 0,
                                                    }}
                                                >
                                                    Sessions per week:
                                                </p>

                                                <select
                                                    value={arrAvailTimes.length}
                                                    style={{ marginLeft: 5 }}
                                                    onChange={(e) => {
                                                        let cavailtimes = [];
                                                        let cselecteddays = [];
                                                        let cselectedtimes = [];
                                                        let sessionsPerWeek = e.target.value;

                                                        for (let i = 0; i < sessionsPerWeek; i++) {
                                                            cavailtimes.push(["6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm"]);
                                                            cselecteddays.push("Sunday")
                                                            cselectedtimes.push("6:00am")
                                                        }
                                                        setArrAvailTimes(cavailtimes);
                                                        setArrSelectedDays(cselecteddays);
                                                        setArrSelectedTimes(cselectedtimes);
                                                    }}

                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                </select>
                                            </div>


                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    marginBottom: 5,
                                                    marginTop: '20px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: p2Size,
                                                        fontFamily: "robotoslab",
                                                        marginBottom: 0,
                                                        marginTop: 0,
                                                    }}
                                                >
                                                    With PT:
                                                </p>
                                                <input
                                                    type="checkbox"
                                                    checked={withPT}
                                                    onChange={() => setWithPT(!withPT)}
                                                    style={{
                                                        marginLeft: 5,
                                                        alignSelf: "center"
                                                    }}
                                                />

                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: 5,
                                                    marginTop: '20px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: p2Size,
                                                        fontFamily: "robotoslab",
                                                        marginBottom: 0,
                                                        marginTop: 0,
                                                    }}
                                                >
                                                    PT:
                                                </p>
                                                <select
                                                    value={selectedPT.email}
                                                    onChange={handlePTChange}
                                                    disabled={!withPT}
                                                    style={{
                                                        overflow: "hidden",
                                                        whiteSpace: "pre",
                                                        marginLeft: 5,
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {
                                                        withPT ?
                                                            (
                                                                allPTs.map((i) => (
                                                                    <option
                                                                        value={i.email}
                                                                    >{i.firstName} {i.lastName}</option>
                                                                ))
                                                            )
                                                            :
                                                            (
                                                                null
                                                            )
                                                    }
                                                </select>
                                            </div>


                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: 5,
                                                    marginTop: '20px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: p2Size,
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        marginRight: 5,
                                                        fontFamily: "robotoslab"
                                                    }}
                                                >
                                                    Starting week:
                                                </p>

                                                <DatePicker
                                                    selected={date}
                                                    filterDate={(date) => {
                                                        const day = date.getDay();
                                                        let sixDays = 60 * 60 * 1000 * 24 * 6;

                                                        return day === 0 && date > new Date(Date.now() - sixDays);
                                                    }}
                                                    onChange={(date) => {
                                                        setDateSet(true);
                                                        setChosenDate(date);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="react-datepicker-ignore-onclickoutside"
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    marginTop: 0,
                                                    marginLeft: "20px",
                                                    fontSize: p3Size,
                                                    opacity: 0.8,
                                                    flexGrow: 1,
                                                    fontFamily: "robotoslab"
                                                }}
                                            >
                                                This is NOT the date for the lesson itself, only the week on which sessions will begin.
                                            </p>

                                            <p

                                                style={
                                                    props.deviceType === 'mobile' ?
                                                        {
                                                            fontSize: '4vw',
                                                            marginBottom: 0,
                                                        }
                                                        :
                                                        {
                                                            marginBottom: 0,
                                                        }
                                                }
                                            >
                                                Cost per week:
                                                <span style={{ color: Colors.pink }}>
                                                    {hasfreelesson && repeatWeeks == 1 && arrSelectedDays.length == 1 || isDiscounted && discountedPrice == 0 || isManager ? " Free" : withPT ? isDiscounted ? " $" + discountedPrice * arrSelectedDays.length : " $" + pricePerWeek : " $" + pricePerWeek}
                                                </span>
                                                <br />
                                                Total over {repeatWeeks} weeks:
                                                <span style={{ color: Colors.pink }}>
                                                    {hasfreelesson && repeatWeeks == 1 && arrSelectedDays.length == 1 || isDiscounted && discountedPrice == 0 || isManager ? " Free" : withPT ? isDiscounted ? " $" + discountedPrice * repeatWeeks * arrSelectedDays.length : " $" + totalPrice : " $" + totalPrice}
                                                </span>
                                            </p>

                                        </div>


                                        <div
                                            style={{
                                                width: props.deviceType === "desktop" ? "34vw" : props.deviceType === "mobile" ? '90vw' :"50vw",
                                                padding: "0.5vw 0",
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    justifyContent: 'space-evenly',
                                                    flexWrap: "wrap"
                                                }}
                                            >

                                                {
                                                    dateSet ?
                                                        arrSelectedDays.map((day, i) => (

                                                            <div
                                                                style={{
                                                                    background: Colors.pink,
                                                                    fontFamily: "robotoslab",
                                                                    marginLeft: "0.5vw",
                                                                    marginRight: "0.5vw",
                                                                    borderRadius: '10px',
                                                                    marginBottom: "1vw",
                                                                    fontSize: p2Size,
                                                                    height: 'auto',
                                                                    padding: "10px 10px"
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: pSize,
                                                                        marginTop: 0,
                                                                        fontFamily: "bebas"
                                                                    }}
                                                                >
                                                                    Session {i + 1}
                                                                </p>

                                                                <label>Day: </label>
                                                                <select
                                                                    value={arrSelectedDays[i]}
                                                                    onChange={(e) => {
                                                                        let d = [...arrSelectedDays];
                                                                        d[i] = e.target.value;

                                                                        setArrSelectedDays(d);
                                                                    }}
                                                                >
                                                                    <option value={0}>Sunday</option>
                                                                    <option value={1}>Monday</option>
                                                                    <option value={2}>Tuesday</option>
                                                                    <option value={3}>Wednesday</option>
                                                                    <option value={4}>Thursday</option>
                                                                    <option value={5}>Friday</option>
                                                                    <option value={6}>Saturday</option>
                                                                </select>

                                                                <br />

                                                                <label>Time: </label>
                                                                <select
                                                                    value={arrSelectedTimes[i]}
                                                                    onChange={(e) => {
                                                                        let t = [...arrSelectedTimes];
                                                                        t[i] = e.target.value;

                                                                        setArrSelectedTimes(t);
                                                                    }}
                                                                >
                                                                    {
                                                                        arrAvailTimes[i].map((ii) => (
                                                                            <option
                                                                                key={ii}
                                                                                value={ii}
                                                                            >
                                                                                {ii}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>

                                                            </div>


                                                        ))
                                                        :
                                                        <p style={{ alignSelf: 'center' }} > Select a starting week to continue</p>
                                                }
                                            </div>

                                            <button
                                                style={
                                                    {
                                                        display: dateSet && !arrSelectedTimes.includes("No times available") ? "block" : "none",
                                                        backgroundColor: Colors.pink,
                                                        border: "none",
                                                        color: "white",
                                                        fontSize: t3Size,
                                                        fontFamily: "bebas",
                                                        alignSelf: "center",

                                                        marginTop: "20px",
                                                        borderRadius: 10,
                                                        cursor: "pointer",


                                                    }}
                                                onClick={() => {
                                                    setIsGroupBooking(false);
                                                    setconfirmModalOpen(true);
                                                    setstringDate(arrSelectedActualDates[0].getDate() + "/" + (arrSelectedActualDates[0].getMonth() + 1) + '/' + arrSelectedActualDates[0].getFullYear());
                                                }}
                                            >
                                                Book
                                            </button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        )
                        :
                        (
                            <div
                                style={{
                                    width: "100vw",
                                    height: "93vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                    alignItems: "center"
                                }}
                            >
                                <h1
                                    style={{
                                        color: Colors.offwhite,
                                        fontFamily: "bebas",
                                        fontWeight: 200,
                                        marginBottom: 0,
                                        fontSize: "5vw"
                                    }}
                                >
                                    TRAINING
                                </h1>

                                <p
                                    style={{
                                        color: Colors.offwhite,
                                        fontFamily: "RobotoSlab",
                                        fontSize: pSize,
                                        width: "50%",
                                        marginTop: 0,
                                        textAlign: "center"
                                    }}
                                >
                                    Boss Squad Training offers 1:1 personal training with the option of additional external programs to do in your own home or at your local gym. We also offer small group sessions, boot camps, social training events and stretching classes.
                                </p>

                                <a href={"/login"}>
                                    <button
                                        className="btn draw-border"
                                        style={{
                                            fontSize: "3vw",
                                            fontFamily: "bebas",
                                        }}
                                    >
                                        LOG IN TO BOOK YOUR SESSIONS
                                    </button>
                                </a>

                            </div>
                        )
                }
            </div >
        );
    }
}

const ConfirmMessage = (props) => {

    const prettyDate = (d) => {
        let date = new Date(d);
        return date.getDate() + "/" + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
    const FreeButton = (props) => {

        return (
            <button
                style={{
                    backgroundColor: "#2C2E2F",
                    color: Colors.offwhite,
                    fontFamily: "Bebas",
                    border: "none",
                    maxWidth: "750px",
                    fontSize: "1.5vw",
                    borderRadius: "3px",
                    cursor: "pointer",
                    padding: "0.5vw 0.5vw",
                    marginBottom: "2vh",
                    marginTop: "2vh"
                }}
                onClick={() => {
                    if (props.repeatWeeks == 1) {
                        props.BookSingleSession();
                    }
                    else {
                        props.BookRepeatSession();
                    }
                }}
            >
                Confirm
            </button>
        )
    }
    const CashOnArrival = (props) => {
        return (
            <button
                onClick={() => props.CashOnArrival()}
                style={{
                    backgroundColor: "#2C2E2F",
                    color: Colors.offwhite,
                    border: "none",
                    cursor: "pointer",
                    maxWidth: "750px",
                    width: "100%",
                    fontSize: "3vw",
                    fontFamily: "Bebas",
                    borderRadius: "3px",
                    marginBottom: "2vh",
                    padding: "0.5vw 0.5vw",
                }}
            >
                Cash on Arrival
            </button>
        )
    }
    const PayPalOrder = (props) => {
        return (
            <PayPalScriptProvider options={props.paypaloptions}>
                <PayPalButtons
                    createOrder={(data, actions) => props.createOrder(data, actions)}
                    onApprove={(data, actions) => props.onApproveOrder(data, actions)}
                    onCancel={() => props.setBookingSubmitted(false)}
                    onError={() => props.setBookingSubmitted(false)}
                    style={{
                        color: 'black',
                        tagline: false
                    }}
                />
            </PayPalScriptProvider>
        )
    }
    const PayPalSub = (props) => {
        return (
            <PayPalScriptProvider options={props.paypaloptions}>
                <PayPalButtons
                    createSubscription={(data, actions) => props.createSubscription(data, actions)}
                    onApprove={(data, actions) => props.onApproveSubscription(data, actions)}
                    onCancel={() => props.setBookingSubmitted(false)}
                    onError={() => props.setBookingSubmitted(false)}
                    style={{
                        color: 'black',
                        tagline: false
                    }}
                />
            </PayPalScriptProvider>
        )
    }

    if (!props.bookingConfirmed) {

        if (props.isManager) {
            if (props.repeatWeeks === 1) {
                if (props.daysPerWeek === 1) {
                    if (props.withPT) {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>Booking {props.client.firstName} {props.client.lastName} for a single PT session with {props.PT.firstName} {props.PT.lastName} on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>Booking {props.client.firstName} {props.client.lastName} for a single solo session on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                }
                else {
                    if (props.withPT) {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>
                                    Booking {props.client.firstName} {props.client.lastName} for {props.daysPerWeek} PT sessions with {props.PT.firstName} {props.PT.lastName} on
                                    {
                                        props.actualDates.map((date, i) =>
                                            i === props.actualDates.length - 1 ?
                                                (
                                                    <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                )
                                                :
                                                (
                                                    <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                )
                                        )
                                    }
                                </p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>
                                    Booking {props.client.firstName} {props.client.lastName} for {props.daysPerWeek} solo sessions on
                                    {
                                        props.actualDates.map((date, i) =>
                                            i === props.actualDates.length - 1 ?
                                                (
                                                    <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                )
                                                :
                                                (
                                                    <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                )
                                        )
                                    }
                                </p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                }
            }
            else {
                if (props.daysPerWeek === 1) {
                    if (props.withPT) {
                        //1 PT session for multiple weeks
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>Booking {props.client.firstName} {props.client.lastName} for 1 PT session per week for {props.repeatWeeks} weeks, with {props.PT.firstName} {props.PT.lastName}, starting on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                    else {
                        //1 solo session for multiple weeks
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>Booking {props.client.firstName} {props.client.lastName} for 1 solo session per week for {props.repeatWeeks} weeks, starting on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                }
                else {
                    if (props.withPT) {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>
                                    Booking {props.client.firstName} {props.client.lastName} for {props.daysPerWeek} PT sessions per week, for {props.repeatWeeks} weeks with {props.PT.firstName} {props.PT.lastName}. First week of sessions will be on
                                    {
                                        props.actualDates.map((date, i) =>
                                            i === props.actualDates.length - 1 ?
                                                (
                                                    <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                )
                                                :
                                                (
                                                    <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                )
                                        )
                                    }
                                    This pattern will continue for another {props.repeatWeeks - 1} weeks.
                                </p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div
                                style={{
                                    fontSize: props.pSize,
                                }}
                            >
                                <p>
                                    Booking {props.client.firstName} {props.client.lastName} for {props.daysPerWeek} solo sessions per week, for {props.repeatWeeks} weeks. First week of sessions will be on
                                    {
                                        props.actualDates.map((date, i) =>
                                            i === props.actualDates.length - 1 ?
                                                (
                                                    <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                )
                                                :
                                                (
                                                    <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                )
                                        )
                                    }
                                    This pattern will continue for another {props.repeatWeeks - 1} weeks.
                                </p>
                                <FreeButton {...props} />
                            </div>
                        )
                    }
                }
            }
        }
        else {
            if (props.discountedRate === 0) {
                if (props.repeatWeeks == 1) {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking a single PT session with {props.PT.firstName} {props.PT.lastName} on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking a single solo session on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions with {props.PT.firstName} {props.PT.lastName} on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                    </p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                    </p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                    }
                }
                else {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            //1 PT session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 PT session per week for {props.repeatWeeks} weeks, with {props.PT.firstName} {props.PT.lastName}, starting on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                        else {
                            //1 solo session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 solo session per week for {props.repeatWeeks} weeks, starting on {props.stringDate} at {props.selectedTimes[0]}.</p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions per week, for {props.repeatWeeks} weeks with {props.PT.firstName} {props.PT.lastName}. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks.
                                    </p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions per week, for {props.repeatWeeks} weeks. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks.
                                    </p>
                                    <FreeButton {...props} />
                                </div>
                            )
                        }
                    }
                }
            }
            else if (props.isDiscounted) {
                if (props.repeatWeeks == 1) {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking a single PT session with {props.PT.firstName} {props.PT.lastName} on {props.stringDate} at {props.selectedTimes[0]}. You will be charged ${props.discountedRate} immediately.</p>
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking a single solo session on {props.stringDate} at {props.selectedTimes[0]}. You can pay ${props.regularRate} cash on arrival or via card immediately.</p>
                                    <CashOnArrival {...props} />
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions with {props.PT.firstName} {props.PT.lastName} on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        You will be charged ${props.daysPerWeek * props.discountedRate} immediately.
                                    </p>
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        You can pay ${props.daysPerWeek * props.regularRate} cash on arrival, or via card immediately.
                                    </p>
                                    <CashOnArrival {...props} />
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                    }
                } else {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            //1 PT session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 PT session per week for {props.repeatWeeks} weeks, with {props.PT.firstName} {props.PT.lastName}, starting on {props.stringDate} at {props.selectedTimes[0]}. You will be charged ${props.discountedRate} immediately, and will continue to be charged ${props.discountedRate} weekly for another {props.repeatWeeks - 1} weeks.</p>
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                        else {
                            //1 solo session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 PT solo session per week for {props.repeatWeeks} weeks, starting on {props.stringDate} at {props.selectedTimes[0]}.
                                        You will be charged ${props.regularRate} immediately, and will continue to be charged ${props.regularRate} weekly for another {props.repeatWeeks - 1} weeks. You may also pay cash on arrival.</p>
                                    <CashOnArrival {...props} />
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions per week, for {props.repeatWeeks} weeks with {props.PT.firstName} {props.PT.lastName}. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks. You will be charged ${props.discountedRate * props.daysPerWeek} immediately, and will continue to be charged ${props.discountedRate * props.daysPerWeek} weekly for another {props.repeatWeeks - 1} weeks.
                                    </p>
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions per week, for {props.repeatWeeks} weeks. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks. You will be charged ${props.pricePerWeek} immediately, and will continue to be charged ${props.pricePerWeek} weekly for another {props.repeatWeeks - 1} weeks. You may also pay cash on arrival.</p>
                                    <CashOnArrival {...props} />
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                    }
                }
            }
            else {
                if (props.repeatWeeks == 1) {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            if (props.isFirstFreeSession) {
                                return (
                                    <div
                                        style={{
                                            fontSize: props.pSize,
                                        }}
                                    >
                                        <p>Booking a single PT session with {props.PT.firstName} {props.PT.lastName} on {props.stringDate} at {props.selectedTimes[0]}. This is your complimentary first session.</p>
                                        <FreeButton {...props} />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div
                                        style={{
                                            fontSize: props.pSize,
                                        }}
                                    >
                                        <p>Booking a single PT session with {props.PT.firstName} {props.PT.lastName} on {props.stringDate} at {props.selectedTimes[0]}. You will be charged ${props.pricePerWeek} immediately.</p>
                                        <PayPalOrder {...props} />
                                    </div>
                                )
                            }
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking a single solo session on {props.stringDate} at {props.selectedTimes[0]}. You can pay ${props.pricePerWeek} cash on arrival, or via card immediately.</p>
                                    <CashOnArrival {...props} />
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions with {props.PT.firstName} {props.PT.lastName} on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        You will be charged ${props.pricePerWeek} immediately.
                                        <PayPalOrder {...props} />
                                    </p>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '.'}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        You will be charged ${props.pricePerWeek} immediately. You may also pay cash on arrival.
                                    </p>
                                    <CashOnArrival {...props} />
                                    <PayPalOrder {...props} />
                                </div>
                            )
                        }
                    }
                }
                else {
                    if (props.daysPerWeek === 1) {
                        if (props.withPT) {
                            //1 PT session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 PT session per week for {props.repeatWeeks} weeks, with {props.PT.firstName} {props.PT.lastName}, starting on {props.stringDate} at {props.selectedTimes[0]}. You will be charged ${props.pricePerWeek} immediately, and will continue to be charged ${props.pricePerWeek} weekly for another {props.repeatWeeks - 1} weeks.</p>
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                        else {
                            //1 solo session for multiple weeks
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>Booking 1 PT solo session per week for {props.repeatWeeks} weeks, starting on {props.stringDate} at {props.selectedTimes[0]}. You will be charged ${props.pricePerWeek} immediately, and will continue to be charged ${props.pricePerWeek} weekly for another {props.repeatWeeks - 1} weeks. Alternatively, you may pay cash on arrival.</p>
                                    <CashOnArrival {...props} />
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (props.withPT) {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} PT sessions per week, for {props.repeatWeeks} weeks with {props.PT.firstName} {props.PT.lastName}. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks. You will be charged ${props.pricePerWeek} immediately, and will continue to be charged ${props.pricePerWeek} weekly for another {props.repeatWeeks - 1} weeks.
                                    </p>
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div
                                    style={{
                                        fontSize: props.pSize,
                                    }}
                                >
                                    <p>
                                        Booking {props.daysPerWeek} solo sessions per week, for {props.repeatWeeks} weeks. First week of sessions will be on
                                        {
                                            props.actualDates.map((date, i) =>
                                                i === props.actualDates.length - 1 ?
                                                    (
                                                        <span>{" and " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + '. '}</span>
                                                    )
                                                    :
                                                    (
                                                        <span>{" " + date.toString().slice(0, 3) + "  " + prettyDate(date) + " at " + props.selectedTimes[i] + ', '}</span>
                                                    )
                                            )
                                        }
                                        This pattern will continue for another {props.repeatWeeks - 1} weeks. You will be charged ${props.pricePerWeek} immediately, and will continue to be charged ${props.pricePerWeek} weekly for another {props.repeatWeeks - 1} weeks. Alternatively, you may pay cash on arrival.

                                    </p>
                                    <CashOnArrival {...props} />
                                    <PayPalSub {...props} />
                                </div>
                            )
                        }
                    }
                }
            }
        }
    }
    else {
        return (
            <div
                style={{
                    fontSize: props.pSize,
                }}
            >
                <p>Success! Booking confirmed.</p>
                <p><a href="/account/schedule">View your upcoming sessions here.</a></p>
            </div>

        )
    }
}


export default Training;
