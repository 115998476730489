import React, { Component, useState, useEffect } from 'react';
// import { commerce } from '../lib/commerce';
import cartImage from "../assets/images/header/bag.png";
import Loader from "react-loader-spinner";
import { Colors } from "../styles/colors.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect, useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
export const Cart = (props) => {

    const [cart, setCart] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false)

    useEffect(() => {
        if (props.cart) {
            setCart(props.cart);
        }
    }, [props.cart]);

    const handleUpdateCartQty = (lineItemId, newQuantity) => {
        props.onUpdateCartQty(lineItemId, newQuantity);
    }
    const handleRemoveFromCart = (lineItemId) => {
        props.onRemoveFromCart(lineItemId);
    }
    const handleEmptyCart = () => {
        props.onEmptyCart();
    }
    const goToCheckout = () => {
        props.goToCheckout();
    }

    if (!cart) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    zIndex:10
                }}
            >
                <img
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    src={cartImage}
                    style={{
                        height: "4vh",
                        alignSelf: "center",
                        marginBottom: "1.5vh",
                        marginRight: "2vh",
                        cursor: "pointer",
                    }}
                />
                <Loader
                    color={Colors.pink}
                    width={"2vh"}
                    height={"2vh"}
                    type="TailSpin"
                    style={{
                        position: "relative",
                        right: "-2.5vh",
                        pointerEvents: "none"
                    }}
                />
            </div >
        );
    }
    else if (cart && cart.total_unique_items === 0) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    zIndex:10
                }}
            >
                <img
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    src={cartImage}
                    style={{
                        height: "4vh",
                        alignSelf: "center",
                        marginBottom: "1.5vh",
                        marginRight: "2vh",
                        cursor: "pointer",
                    }}
                />

                <CartDropDown
                    cart={cart}
                    open={dropdownOpen}
                    onUpdateCartQty={handleUpdateCartQty}
                    onRemoveFromCart={handleRemoveFromCart}
                    onEmptyCart={handleEmptyCart}
                    goToCheckout={goToCheckout}
                />
            </div>
        );
    }
    else {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    zIndex:10
                }}
            >

                <img
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    src={cartImage}
                    style={{
                        height: "4vh",
                        alignSelf: "center",
                        marginBottom: "1.5vh",
                        marginRight: "2vh",
                        cursor: "pointer",
                    }}
                />
                <p
                    style={{
                        position: "relative",
                        right: "-3.3vh",
                        color: Colors.offwhite,
                        fontFamily: "bebas",
                        fontSize: "2vh",
                        width: "3.5vh",
                        marginRight: 0,
                        marginLeft: 0,
                        textAlign: "center",
                        fontWeight: 200,
                        pointerEvents: "none"
                    }}
                >
                    {cart.total_items}
                </p>

                <CartDropDown
                    cart={cart}
                    open={dropdownOpen}
                    onUpdateCartQty={handleUpdateCartQty}
                    onRemoveFromCart={handleRemoveFromCart}
                    onEmptyCart={handleEmptyCart}
                    goToCheckout={goToCheckout}
                />
            </div>
        );
    }

}

export class CartDropDown extends Component {

    constructor(props) {
        super(props);
        this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
        this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
        this.handleEmptyCart = this.handleEmptyCart.bind(this);
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            cart: newProps.cart
        })
    }

    handleUpdateCartQty(lineItemId, newQuantity) {
        this.props.onUpdateCartQty(lineItemId, newQuantity);
    }
    handleRemoveFromCart(lineItemId) {
        this.props.onRemoveFromCart(lineItemId);
    }
    handleEmptyCart() {
        this.props.onEmptyCart();
    }
    handleClick = e => {
        this.props.goToCheckout();
    };

    render() {

        const { cart } = this.props;

        if (!cart || !this.props.open)
            return (<div />)

        return (
            <div
                style={{
                    zIndex: 100,
                    top: "7vh",
                    right: "13.75vh",
                    width: "15vw",
                    padding: "1vw 1vw",
                    position: "absolute",
                    border: "0.25vw solid #ff65c3",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    background: "#2e302f",
                    color: Colors.offwhite,
                    fontFamily: "RobotoSlab"
                }}
            >
                {
                    cart.line_items.length === 0 ?
                        (
                            <p
                                style={{
                                    textAlign: "center"
                                }}
                            >You have no items in your shopping  cart, start adding some!</p>
                        )
                        :
                        (
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >

                                {
                                    cart.line_items.map(lineItem => (
                                        <CartDropDownItem
                                            item={lineItem}
                                            key={lineItem.id}
                                            onUpdateCartQty={this.handleUpdateCartQty}
                                            onRemoveFromCart={this.handleRemoveFromCart}
                                        />
                                    ))
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <p>Subtotal: </p>
                                    <p>{cart.subtotal.formatted_with_symbol}</p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    width: "100%",
                                    flexDirection: "row-reverse",
                                    justifyContent: "space-evenly"
                                }}
                                >
                                    <button
                                        className="btn draw-borderCart"
                                        onClick={this.handleClick}
                                    >Checkout
                                    </button>

                                    <button
                                        className="btn draw-borderCart"
                                        onClick={this.handleEmptyCart}>Empty cart
                                    </button>
                                </div>
                            </div>
                        )
                }
            </div>
        );
    };
};

export class CartDropDownItem extends Component {

    constructor(props) {
        super(props);
        this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
        this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            cart: newProps.cart
        })
    }
    handleUpdateCartQty(lineItemId, newQuantity) {
        this.props.onUpdateCartQty(lineItemId, newQuantity);
    }
    handleRemoveFromCart(lineItemId) {
        this.props.onRemoveFromCart(lineItemId);
    }

    render() {
        const { item } = this.props;

        return (
            <div
                style={{
                    width: "100%",
                }}>

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <img
                        alt={item.name}
                        src={item.media.source}
                        style={{
                            width: "7vh",
                            height: "7vh",
                            objectFit: "cover",
                            marginRight: "0.5vw"
                        }}
                    />

                    <h4>{item.name}</h4>
                </div>

                <div
                    style={{
                        display: "flex",
                        marginBottom: "1vh",
                        justifyContent: "space-between",
                    }}
                >

                    <label
                        style={{
                            alignSelf: "center",
                            cursor: "pointer"
                        }}
                        onClick={() => this.handleRemoveFromCart(item.id)}
                    >
                        Remove
                    </label>
                    <label>QTY: {item.quantity}   {item.line_total.formatted_with_symbol}</label>

                </div>
            </div>
        );
    };
};

/*

                <div
                    style={{
                        height: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-evenly",
                        alignItems: "center"
                    }}
                >
                    <button tyle={{ marginTop: 0, marginBottom: 0 }} type="button" onClick={() => this.handleUpdateCartQty(item.id, item.quantity + 1)} title="Increase quantity">+</button>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>{item.quantity}</p>
                    <button tyle={{ marginTop: 0, marginBottom: 0 }} type="button" onClick={() => this.handleUpdateCartQty(item.id, item.quantity - 1)} title="Decrease quantity">-</button>
                </div>



*/