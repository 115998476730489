import React, { useState, useEffect, Component } from 'react';
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import { Colors } from '../styles/colors';

const Blog = (props) => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        GetPosts();
    }, []);

    const GetPosts = async (props) => {

        axios
            .get(
                process.env.REACT_APP_BLOG_URL + "posts"
            )
            .then((res) => {
                setPosts(res.data.posts)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
                backgroundColor: Colors.offBlack,
                minHeight: "93vh",
                color: Colors.offwhite,
                fontFamily: "RobotSlab"
            }}
        >
            <div
                style={{
                    marginTop: "20vh",
                    width: "75%",
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                }}
            >
                {
                    posts.map(post => <ArticlePreview post={post} deviceType={props.deviceType} />)
                }
            </div>
        </div>
    );
}

class ArticlePreview extends Component {

    removeUnicode(string) {
        if (string.indexOf("&#8217;") > 0) {
            return this.removeUnicode(string.replace("&#8217;", "'"));
        } else if (string.indexOf("&#8211;") > 0) {
            return this.removeUnicode(string.replace("&#8211;", "-"));
        } else if (string.indexOf("[&hellip;]</p>") > 0) {
            return string.replace("<p>", "").replace(" [&hellip;]</p>", "...");
        } else {
            return string.replace("<p>", "").replace("</p>", "...");
        }
    }

    render() {
        const excerpt = this.removeUnicode(this.props.post.excerpt);
        const date = this.props.post.date + "";
        const formattedDate = date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)


        if (this.props.post) {
            return (
                <div
                    style={{
                        alignSelf: "center",
                        width: this.props.deviceType === "desktop" ? "50vw" : "75vw",
                        marginBottom: "10vh",
                        overflow: "hidden",
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            marginRight: "0.5vw"
                        }}
                    >
                        {
                            this.props.post.featured_image ? (
                                <a
                                    className="blackLink"
                                    href={"/blog/" + this.props.post.ID}
                                    style={{
                                        textDecoration: "none",
                                        color: Colors.offwhite
                                    }}
                                >
                                    <div
                                        style={{
                                            width: this.props.deviceType === "desktop" ? "25vw" : "37.5vw",
                                            height: this.props.deviceType === "desktop" ? "13.25vw" : "25vw",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            className="img-responsive webpic"
                                            alt="article header"
                                            src={this.props.post.featured_image}
                                        />
                                    </div>
                                </a>
                            )
                                :
                                (
                                    null
                                )
                        }
                    </div>

                    <div
                        style={{
                            marginLeft: "0.5vw",
                            width: this.props.deviceType === "desktop" ? "25vw" : "37.5vw",
                        }}
                    >
                        <a
                            className="blackLink"
                            href={"/blog/" + this.props.post.ID}
                            style={{
                                textDecoration: "none",
                                color: Colors.offwhite
                            }}
                        >
                            <h2
                                style={{
                                    fontFamily: "bebas",
                                    fontSize: this.props.deviceType === "desktop" ? "2vw" : "5vw",
                                    fontWeight: 200,
                                    marginBottom: 0,
                                    marginTop: 0
                                }}
                            >
                                {this.removeUnicode(this.props.post.title)}
                            </h2>

                        </a>

                        <p
                            style={{
                                fontFamily: "bebas",
                                fontSize: this.props.deviceType === "desktop" ? "1vw" : "3vw",
                                marginTop: 0,
                                marginBottom: 0
                            }}
                        >
                            {formattedDate}
                        </p>

                        <p
                            style={{
                                fontWeight: "normal",
                                fontSize: "1vw",
                                marginTop: 0,
                                marginBottom: 0
                            }}
                        >
                            {this.props.deviceType === "desktop" ? excerpt : ""}
                        </p>


                        <HashLink to={"/blog/" + this.props.post.ID}>
                            <button
                                className="btn draw-border"
                                style={{
                                    fontFamily: "bebas",
                                    fontSize: this.props.deviceType === "desktop" ? "1vw" : "2.5vw",
                                    marginTop: "1vh"
                                }}
                            >
                                Read More
                            </button>
                        </HashLink>

                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}


export default Blog;