import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Colors, DesktopSizes } from "../../styles/colors.js";
import "../../styles/register.css"


const Register = (props) => {

    const history = useHistory();

    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [p1, setP1] = useState('');
    const [p2, setP2] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [dob, setDob] = useState('');
    const [message, setMessage] = useState('');
    const [messageColour, setMessageColour] = useState('');


    const [t1Size, setT1Size] = useState("7vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [pSize, setPSize] = useState("1.25vw");



    useEffect(() => {

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setT1Size("14vw");
            setT2Size("5vw");
            setPSize("3vw")
        }

    }, [props.deviceType]);


    useEffect(() => {
        setMessageColour(Colors.offwhite);
    }, []);

    const handleFNameChange = event => {
        setfName(event.target.value);
    };
    const handleLNameChange = event => {
        setlName(event.target.value);
    };
    const handleEmailChange = event => {
        setEmail(event.target.value);
    };
    const handleMobileChange = event => {
        setMobile(event.target.value.replace(/\D/, ''));
    };
    const handleDobChange = event => {
        setDob(event.target.value);
    };
    const handleP1Change = event => {
        setP1(event.target.value);
    };
    const handleP2Change = event => {
        setP2(event.target.value);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setMessage("");

        if (fName === "" || lName === "" || email === "" || p1 === "" || p2 === "" || fName === null || lName === null || email === null || p1 === null || p2 === null) {
            setMessage("Please complete all fields.");
            return;
        }

        if (p1 !== p2) {
            setMessage("Your passwords don't match.");
            return;
        }

        if (p1.length < 8) {
            setMessage("Your password is too short.");
            return;
        }

        if (!/\d/.test(p1)) {
            setMessage("Your password must contain at least one number.");
            return;
        }

        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(p1)) {
            setMessage("Your password must contain at least one special character.");
            return;
        }

        setLoaderVisible(true);

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/register", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": fName + "",
                "lastName": lName + "",
                "email": email + "",
                "password": p1 + "",
                "mobile": mobile + ""
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then(async (data) => {

                setLoaderVisible(false);

                if (data.message === "✔️ Success") {
                    setMessage(data.message);
                    setMessageColour(Colors.green);
                    await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/mailchimp/newsub", {
                        mode: "cors",
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "fName": fName,
                            "lName": lName,
                            "email": email
                        }),
                        dataType: "text",
                        contentType: "text/plain"
                    })
                        .then((res) => {
                            setTimeout(() => history.push("/login"), 1000);
                        })
                        .catch(function (error) {
                            console.error(error.message);
                        })
                }
                else {
                    setMessage(data.message);
                    setMessageColour(Colors.orange);
                }
            })
            .catch((error) => {
                setLoaderVisible(false);
                console.error(error.message);
            });
    };


    return (
        <form
            noValidate
            onSubmit={handleSubmit}
            style={{
                width: "100vw",
                height: "93vh",
                display: "flex",
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                color: Colors.offwhite,
                backgroundColor: Colors.offBlack,
            }}
        >
            <div
                style={{
                    width: "25%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontFamily: "RobotoSlab",
                }}
            >
                <h1
                    style={{
                        fontWeight: 200,
                        fontFamily: "bebas",
                        fontSize: t1Size,
                        marginTop: 0
                    }}
                >
                    Register
                </h1>

                <div
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <input
                        required
                        autoFocus
                        value={fName}
                        placeholder={"First Name"}
                        onChange={handleFNameChange}
                        className="registerDetailsOption"
                    />
                    <div style={{ width: "2vh" }} />
                    <input
                        required
                        value={lName}
                        placeholder={"Last Name"}
                        onChange={handleLNameChange}
                        className="registerDetailsOption"
                    />
                </div>

                <input
                    required
                    value={email}
                    type="email"
                    placeholder={"Email"}
                    onChange={handleEmailChange}
                    className="registerDetailsOption"
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                    }}
                />

                <input
                    required
                    value={mobile}
                    placeholder={"Mobile"}
                    onChange={handleMobileChange}
                    className="registerDetailsOption"
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                    }}
                />

                <input
                    required
                    type="date"
                    value={dob}
                    placeholder={"Date of Birth"}
                    onChange={handleDobChange}
                    className="registerDetailsOption"
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                    }}
                />

                <input
                    required
                    value={p1}
                    type="password"
                    placeholder={"Password"}
                    onChange={handleP1Change}
                    className="registerDetailsOption"
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                    }}
                />
                <input
                    required
                    value={p2}
                    type="password"
                    placeholder={"Confirm Password"}
                    onChange={handleP2Change}
                    className="registerDetailsOption"
                    style={{
                        width: props.deviceType === "desktop" ? " 100%" : "300%",
                    }}
                />
               
                <div
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        marginTop: "1.5vw",
                    }}
                >
                    <button
                        type="submit"
                        className="btn draw-border-login"
                        style={{
                            fontFamily: "bebas",
                            fontSize: pSize,
                        }}
                    >
                        Let's Go
                    </button>

                    <Loader
                        visible={loaderVisible}
                        color={Colors.pink}
                        width={"3vh"}
                        height={"3vh"}
                        type="TailSpin"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            left: props.deviceType === "desktop" ? "53.5vw" : "57vw",
                            position: "absolute",
                        }}
                    />
                    <label
                        style={{
                            width: "9vw",
                            fontSize: props.deviceType === "desktop" ? "0.85vw" : "2vw",
                            position: "absolute",
                            left: props.deviceType === "desktop" ? "53.5vw" : "57vw",
                            color: messageColour
                        }}
                    >
                        {message}
                    </label>

                </div>
                <a
                    className={"loginToRegisterLink"}
                    href={"/login"}
                    style={{
                        fontSize: pSize,
                        width: "300%",
                        textAlign: "center",
                        marginTop: "2.5vw",
                        textDecoration: "none",
                        color: Colors.offwhite,
                    }}
                >
                    Already have an account? <span style={{ color: Colors.pink }}>Log in here.</span>
                </a>

            </div>
        </form>
    );
}

export default Register;

