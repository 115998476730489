import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ReactPlayer from 'react-player/lazy';
import vidThumb from "../../assets/images/bubAndMeThumb.PNG";

import { Colors } from '../../styles/colors.js'
import apiRequest from '../../utils/apiRequest.js';

import kate from '../../assets/images/bossself/pay.JPG';

import './checkout.css';

const { DEVICE_TYPES } = require('../../constants.js');




const paypalOptions = {
	"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
	currency: "AUD",
	vault: false
};

const PLANS = {
	inPerson: 'in-person',
	online: 'online',
	extras: 'extras',
};

const ADDONS = {
	ptSession: 'pt-session',
	privateAccess: 'private-access',
	equipmentBundle: 'equipment-bundle',
	mindfulness: 'mindfulness',
	followUp: 'follow-up',
};

const BossSelfCheckOut = ({ deviceType }) => {
	const [approved, setApproved] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [fieldsComplete, setFieldsComplete] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [paid, setPaid] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [startDate, setStartDate] = useState('');
	const [plan, setPlan] = useState(PLANS.inPerson);

	const [nutritionOnly, setNutritionOnly] = useState(false);
	const [motherChecked, setMotherChecked] = useState(false);
	const [followUpChecked, setFollowUpChecked] = useState(false);
	const [nutritionConsult, setNutritionConsult] = useState(false);
	const [ptSessionChecked, setPtSessionChecked] = useState(false);
	const [mindfulnessChecked, setMindfulnessChecked] = useState(false);
	const [privateAccessChecked, setPrivateAccessChecked] = useState(false);
	const [equipmentBundleChecked, setEquipmentBundleChecked] = useState(false);

	const useMobile = window.innerWidth < window.innerHeight && deviceType === DEVICE_TYPES.mobile;
	const createOrder = (data, actions, description) => {
		return actions.order.create({
			purchase_units: [
				{
					description: "Boss Squad Training Boss-Self Challenge",
					amount: {
						currency_code: "AUD",
						value: totalPrice
					}
				}
			]
		});
	}
	const onApproveOrder = async () => {
		setPaid(true);
	}

	useEffect(async () => {
		if (!paid) {
			return;
		}

		try {
			const name = document.getElementById('name').value;
			const email = document.getElementById('email').value;
			const checkedOptionsString = `plan: ${plan}, startDate: ${startDate}, ${motherChecked ? '-IS EXPECING-,' : ''}, ${ptSessionChecked ? 'PT Sessions' : ''}${privateAccessChecked ? ', Private Access' : ''}${equipmentBundleChecked ? ', Equipment Bundle' : ''}${mindfulnessChecked ? ', Mindfulness Sessions' : ''}${followUpChecked ? ', Follow Up Plan' : ''}${nutritionOnly ? ', Nutrition Only' : ''}${nutritionConsult ? ', Nutrition Plan' : ''}`;

			const data = {
				name,
				email,
				checkedOptionsString
			};

			setFeedback("Success - your order has been received, we'll be in touch shortly!");
		} catch (error) {
			setFeedback('Apologies - your funds have been received, but there was an error processing your order. Please contact us at info@bosssquadtraining.com so we can sort this out.');
		}
		setApproved(true);
	}, [paid, plan, startDate, motherChecked, ptSessionChecked, privateAccessChecked, equipmentBundleChecked, mindfulnessChecked, followUpChecked, nutritionConsult, nutritionOnly]);
	useEffect(() => {
		const url = window.location.pathname;

		if (url.includes(PLANS.inPerson)) {
			setPlan(PLANS.inPerson);
		} else if (url.includes(PLANS.online)) {
			setPlan(PLANS.online);
		} else {
			setPtSessionChecked(true);
		}
	}, []);

	useEffect(() => {
		let total;

		if (plan === PLANS.inPerson) {
			total = 429.99;
		} else if (plan === PLANS.online) {
			total = 159.99;
		}

		if (ptSessionChecked) {
			total += 39.99
		}

		if (privateAccessChecked) {
			total += 10
		}

		if (equipmentBundleChecked) {
			total += 79.99
		}

		if (mindfulnessChecked) {
			total += 39.99
		}

		if (followUpChecked) {
			total += 199.99
		}

		if (nutritionOnly) {
			total += 79.99;
		}

		if (nutritionConsult) {
			total += 249.99;
		}

		// setTotalPrice(0.1);

		setTotalPrice(Math.round((total + Number.EPSILON) * 100) / 100);
	}, [ptSessionChecked, privateAccessChecked, equipmentBundleChecked, mindfulnessChecked, followUpChecked, plan, nutritionOnly, nutritionConsult]);

	const handlePtSessionChange = (event) => {
		setPtSessionChecked(event.target.checked);
	};

	const handlePrivateAccessChange = (event) => {
		setPrivateAccessChecked(event.target.checked);
	};

	const handleEquipmentBundleChange = (event) => {
		setEquipmentBundleChecked(event.target.checked);
	};

	const handleMindfulnessChange = (event) => {
		setMindfulnessChecked(event.target.checked);
	};

	const handleFollowUpChange = (event) => {
		setFollowUpChecked(event.target.checked);
	};
	const handlen1pChange = (event) => {
		setNutritionOnly(event.target.checked);
	};
	const handlen2Change = (event) => {
		setNutritionConsult(event.target.checked);
	};

	const handlePlanChangeChange = (event) => {
		setPlan(event.target.value);
	};
	const handleMotherChange = (event) => {
		setMotherChecked(event.target.checked);
	};

	useEffect(() => {
		if (name && email && plan && startDate) {
			setFieldsComplete(true);
		} else {
			setFieldsComplete(false);
		}
	}, [name, email, plan, startDate]);

	return (
		useMobile ?
			<div style={{ marginTop: '5vh', width: '100vw', color: Colors.pink, fontFamily: 'bebas', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>,
				<h1 style={{ textAlign: 'center' }}>Checkout</h1>


				<img src={kate} style={{ width: '33vw', maxWidth: 600, minWidth: 200 }} alt='Kate Gregory' />

				<div >
					<form id="form" style={{ maxWidth: '85vw' }}>
						<div className='formItem'>
							<label>Name*:</label>
							<input type="text" name="name" id='name' value={name} onChange={(e) => setName(e.target.value)} />

						</div>

						<div className='formItem'>
							<label>Email*:</label>
							<input type="email" name="email" id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>

						<div className='formItem'>
							<label>Plan*:</label>
							<select value={plan} name="planSelect" id='planSelect' onChange={handlePlanChangeChange}>
								<option value="in-person">In Person | VIP - $429.99</option>
								<option value="online">Online | Regular - $159.99</option>
							</select>
						</div>

						<div className='formItem'>
							<label>Start Date*:</label>
							<input type="date" name="startDate" id='startDate' min='2023-07-10' max='2023-07-24' value={startDate} onChange={(e) => setStartDate(e.target.value)} />

						</div>

						<div className='formItem'>
							<label>Any Addons?</label>
							<div  >
								<br />
								<input type="checkbox" id="pt-session" checked={ptSessionChecked} onChange={handlePtSessionChange} />
								<label for="pt-session">1 in person PT session / week - $39.99</label>

								<br />

								<input type="checkbox" id="private-access" checked={privateAccessChecked} onChange={handlePrivateAccessChange} />
								<label for="private-access">Private gym access (no PT) - $10.00</label>

								<br />
								<input type="checkbox" id="equipment-bundle" checked={equipmentBundleChecked} onChange={handleEquipmentBundleChange} />
								<label for="equipment-bundle">Fitness equipment bundle - $79.99</label>

								<br />
								<input type="checkbox" id="mindfulness" checked={mindfulnessChecked} onChange={handleMindfulnessChange} />
								<label for="mindfulness">Mindfulness and meditation sessions - $39.99</label>

								<br />
								<input type="checkbox" id="follow-up" checked={followUpChecked} onChange={handleFollowUpChange} />
								<label for="follow-up">Post-challenge follow up plan (8 weeks) - $199.99</label>
								<br />
								<input type="checkbox" id="nutritionConsult" checked={nutritionConsult} onChange={handlen2Change} />
								<label for="nutritionConsult">Nutrition plan + consult (online or in-person) - $249.99</label>
								<br />
								<input type="checkbox" id="nutritionOnly" checked={nutritionOnly} onChange={handlen1pChange} />
								<label for="nutritionOnly">Nutrition consult only - $79.99</label>

							</div >
						</div>

						<div  >
							<input type="checkbox" id="mother" checked={motherChecked} onChange={handleMotherChange} />
							<label for="mother">Are you an expecting mother?</label>
						</div>

						<div className='formItem'>
							<label>Total:</label>
							<label>{totalPrice}</label>
						</div>


						{
							!approved && fieldsComplete ?
								<PayPalScriptProvider options={paypalOptions}>
									<PayPalButtons
										createOrder={(data, actions) => createOrder(data, actions)}
										onApprove={(data, actions) => onApproveOrder(data, actions)}
										// onCancel={() => setBookingSubmitted(false)}
										// onError={() => setBookingSubmitted(false)}
										style={{
											color: 'black',
											tagline: false
										}}
									/>
								</PayPalScriptProvider> :
								<p>{feedback}</p>
						}

						{/* <button onClick={handleSubmit}>Submit</button> */}
					</form>
				</div>

			</div>
			:
			<div style={{ marginTop: '15vh', marginLeft: '2vw', color: Colors.pink, fontFamily: 'bebas' }}>
				<h1 style={{ textAlign: 'center' }}>Checkout</h1>

				<div
					style={{
						width: '100vw',
						display: 'flex',
						justifyContent: 'center',
					}}
				>

					<div id="formContainer">
						<form id="form">
							<div className='formItem'>
								<label>Name*:</label>
								<input type="text" name="name" id='name' value={name} onChange={(e) => setName(e.target.value)} />

							</div>

							<div className='formItem'>
								<label>Email*:</label>
								<input type="email" name="email" id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
							</div>

							<div className='formItem'>
								<label>Plan*:</label>
								<select value={plan} name="planSelect" id='planSelect' onChange={handlePlanChangeChange}>
									<option value="in-person">In Person | VIP - $429.99</option>
									<option value="online">Online | Regular - $159.99</option>
								</select>
							</div>

							<div className='formItem'>
								<label>Start Date*:</label>
								<input type="date" name="startDate" id='startDate' min='2023-07-24' max='2023-08-07' value={startDate} onChange={(e) => setStartDate(e.target.value)} />

							</div>

							<div className='formItem'>
								<label>Any Addons?</label>
								<div  >
									<br />
									<input type="checkbox" id="pt-session" checked={ptSessionChecked} onChange={handlePtSessionChange} />
									<label for="pt-session">1 in person PT session / week - $39.99</label>

									<br />

									<input type="checkbox" id="private-access" checked={privateAccessChecked} onChange={handlePrivateAccessChange} />
									<label for="private-access">Private gym access (no PT) - $10.00</label>

									<br />
									<input type="checkbox" id="equipment-bundle" checked={equipmentBundleChecked} onChange={handleEquipmentBundleChange} />
									<label for="equipment-bundle">Fitness equipment bundle - $79.99</label>

									<br />
									<input type="checkbox" id="mindfulness" checked={mindfulnessChecked} onChange={handleMindfulnessChange} />
									<label for="mindfulness">Mindfulness and meditation sessions - $39.99</label>

									<br />
									<input type="checkbox" id="follow-up" checked={followUpChecked} onChange={handleFollowUpChange} />
									<label for="follow-up">Post-challenge follow up plan (8 weeks) - $199.99</label>
									<br />
									<input type="checkbox" id="nutritionConsult" checked={nutritionConsult} onChange={handlen2Change} />
									<label for="nutritionConsult">Nutrition plan + consult (online or in-person) - $249.99</label>
									<br />
									<input type="checkbox" id="nutritionOnly" checked={nutritionOnly} onChange={handlen1pChange} />
									<label for="nutritionOnly">Nutrition consult only - $79.99</label>

								</div >
							</div>

							<div  >
								<input type="checkbox" id="mother" checked={motherChecked} onChange={handleMotherChange} />
								<label for="mother">Are you an expecting mother?</label>
							</div>

							<div className='formItem'>
								<label>Total:</label>
								<label>{totalPrice}</label>
							</div>


							{
								!approved && fieldsComplete ?
									<PayPalScriptProvider options={paypalOptions}>
										<PayPalButtons
											createOrder={(data, actions) => createOrder(data, actions)}
											onApprove={(data, actions) => onApproveOrder(data, actions)}
											// onCancel={() => setBookingSubmitted(false)}
											// onError={() => setBookingSubmitted(false)}
											style={{
												color: 'black',
												tagline: false
											}}
										/>
									</PayPalScriptProvider> :
									<p>{feedback}</p>
							}

							{/* <button onClick={handleSubmit}>Submit</button> */}
						</form>
					</div>

					<img src={kate} style={{ width: '33vw', maxWidth: 600, minWidth: 200 }} alt='Kate Gregory' />

				</div>


			</div>
	);
}

export default BossSelfCheckOut;