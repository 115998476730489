import React, { useState, useEffect, useRef } from 'react';
import { Header } from "../../components/header.js";
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import { Colors, DesktopSizes } from "../../styles/colors.js"
import FourOFour from "../404.js";
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from "react-router-dom";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import cross from "../../assets/images/accountSettings/cross.png";



const jwt = require("jsonwebtoken");
const modalStyle = {
    content: {
        width: 500,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const hoverGrey = "#e2e2e2"
const Settings = (props) => {
    const history = useHistory();
    const cookies = new Cookies();

    const [token, setToken] = React.useState(null);
    const [fName, setfName] = React.useState('');
    const [lName, setlName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [p1, setP1] = React.useState('');
    const [p2, setP2] = React.useState('');
    const [message, setMessage] = React.useState();
    const [messageColour, setMessageColour] = React.useState(Colors.orange);
    const [loading, setLoading] = React.useState(false);
    const [isPT, setIsPT] = React.useState(false);
    const [isManager, setIsManager] = React.useState(false);
    const [date, setDate] = useState(new Date(Date.now()));
    const [allUsers, setAllUsers] = useState([]);

    const [availModalOpen, setAvailModalOpen] = React.useState(false);
    const [oneOffAvailModalOpen, setOneOffAvailModalOpen] = React.useState(false);
    const [weekModalArray, setWeekModalArray] = React.useState([0, 0, 0, 0, 0, 0, 0]);
    const [modalHover, setModalHover] = React.useState([0, 0, 0, 0, 0, 0, 0]);
    const [modalStartTime, setModalStartTime] = React.useState(new Date(1970, 1, 1, 6, 0, 0));
    const [modalEndTime, setModalEndTime] = React.useState(new Date(1970, 1, 1, 19, 0, 0));
    const [sentenceDays, setSentenceDays] = React.useState("");
    const [sentenceTime, setSentenceTime] = React.useState("");
    const [modalbutton, setModalButton] = React.useState("false");
    const [discountClientInput, setdiscountClientInput] = useState("");
    const [discountClientError, setdiscountClientError] = useState("");
    const [discountedClients, setDiscountedClients] = useState([])
    const [discountClientAmountInput, setDiscountClientAmountInput] = useState()
    const [availSent, setavailSent] = React.useState(false);

    const [avail3d, setavail3d] = React.useState([
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
    ]);
    const [unavails, setUnavails] = React.useState([]);
    const [oneOffUnavails, setOneOffUnavails] = React.useState([]);
    const [unavailsHover, setUnavailsHover] = React.useState([]);
    const [currentHover, setcurrentHover] = React.useState("");

    const dayslotarray = ["", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const mobiledayslotarray = ["", "Su", "M", "Tu", "W", "Th", "F", "Sa"]
    const timeslotarray = ["", "6:00am", "6:30am", "7:00am", "7:30am", "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm", "6:30pm"]

    useEffect(() => {
        setToken(jwt.decode(cookies.get("JWT")));
        CheckAccountType();
    }, []);

    useEffect(() => {
        if (token) {
            setfName(token.fName);
            setlName(token.lName);
            setEmail(token.email);
            setMobile(token.mobile);
        }
    }, [token]);

    useEffect(() => {
        if (isPT) {
            GetUnavailabilites();
        }
    }, [isPT]);


    useEffect(() => {
        if (isManager) {
            GetDiscountedCLients();
            GetAllUsers();
        }
    }, [isManager]);


    useEffect(() => {
        FillCalendar();
        let arr = []
        for (let i = 0; i < unavails.length; i++) {
            arr.push(0);
        }

        setUnavailsHover(arr);
    }, [unavails]);

    useEffect(() => {
        const earliest = new Date(1970, 1, 1, 6, 0, 0);
        const latest = new Date(1970, 1, 1, 19, 0, 0);

        if (modalStartTime > modalEndTime) {
            setSentenceDays("Finish time must be after start time.");
            setSentenceTime("");
            setModalButton(true)
        }
        else if (modalStartTime < earliest) {
            setSentenceDays("Start time must be from 6am onwards.");
            setSentenceTime("");
            setModalButton(true)

        }
        else if (modalEndTime > latest) {
            setSentenceDays("Finish time must be 7pm or earlier.");
            setSentenceTime("");
            setModalButton(true)
        }
        else {
            setModalButton(false)
            let days = [];
            for (let i = 0; i < weekModalArray.length; i++) {
                let d = weekModalArray[i];
                if (d) {
                    if (i === 0)
                        days.push("Sunday");
                    if (i === 1)
                        days.push("Monday");
                    if (i === 2)
                        days.push("Tuesday");
                    if (i === 3)
                        days.push("Wednesday");
                    if (i === 4)
                        days.push("Thursday");
                    if (i === 5)
                        days.push("Friday");
                    if (i === 6)
                        days.push("Saturday");
                }
            }
            if (days.length === 0) {
                setSentenceTime("");
                setSentenceDays("");
                if (availModalOpen)
                    setModalButton(true)
            }
            else {
                if (days.length === 1)
                    setSentenceDays("You will not be booked for PT sessions on " + days[0])
                else if (days.length === 2)
                    setSentenceDays("You will not be booked for PT sessions on " + days[0] + " or " + days[1]);
                else {
                    let d = days[0];
                    for (let i = 1; i < days.length - 1; i++) {
                        d = d + ", " + days[i];
                    }
                    d = "You will not be booked for PT sessions on " + d + " or " + days[days.length - 1];
                    setSentenceDays(d);
                }
                let start = modalStartTime.toTimeString().substring(0, 5)
                let end = modalEndTime.toTimeString().substring(0, 5)
                setSentenceTime(" from " + start + " to " + end + ".")
            }
        }
    }, [weekModalArray, modalStartTime, modalEndTime]);
    const blockString = (u) => {
        let s = "";
        let days = [];

        if (u.sunday)
            days.push("Sunday");
        if (u.monday)
            days.push("Monday");
        if (u.tuesday)
            days.push("Tuesday");
        if (u.wednesday)
            days.push("Wednesday");
        if (u.thursday)
            days.push("Thursday");
        if (u.friday)
            days.push("Friday")
        if (u.saturday)
            days.push("Saturday");

        if (days.length === 1) {
            s = s + days[0];
        }
        else if (days.length === 2) {
            s = s + days[0] + " and " + days[1];
        }
        else {
            s = s + days[0];
            for (let i = 1; i < days.length - 1; i++) {
                s = s + ", " + days[i];
            }
            s = s + " and " + days[days.length - 1]
        }
        return s;
    }
    const timeString = (u) => {
        let s = "";
        let start = u.startT.substring(11, 16);
        let end = u.endT.substring(11, 16);

        s = "From " + start + " to " + end;
        return s;
    }
    const RemoveUnavail = async (i) => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/removeptrecurringunavailability", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "id": i.recurringUnavailabilityID
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                GetUnavailabilites();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const RemoveOneOffUnavail = async (i) => {
        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/removeptoneoffunavailability", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "id": i.staticUnavailabilityID
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                GetUnavailabilites();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const SubmitUnavailability = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/newptrecurringunavailability", {

            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "days": weekModalArray,
                "startTime": modalStartTime,
                "endTime": modalEndTime
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                setavailSent(true);
                GetUnavailabilites();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const SubmitOneOffUnavailability = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/newptoneoffunavailability", {

            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "date": date,
                "startTime": modalStartTime,
                "endTime": modalEndTime
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                setavailSent(true);
                GetUnavailabilites();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }


    const addDiscountClient = async () => {


        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/newdiscountedclient", {

            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "email": discountClientInput,
                "price": discountClientAmountInput
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {


                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }

                if (res.status === 500) {
                    setdiscountClientError("Account with that email does not exist")
                }
                else {
                    setdiscountClientError("")
                }
                GetDiscountedCLients();
            })
            .catch((error) => {
                console.error(error.message);
                GetDiscountedCLients();
            });
    }


    const RemoveDiscountClient = async (i) => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/removediscountedclient", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "email": i.email
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                GetDiscountedCLients();
            })
            .catch((error) => {
                console.error(error.message);
            });

    }


    const GetDiscountedCLients = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getdiscountedclients", {

            mode: "cors",
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
        })
            .then(async (res) => {

                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                setDiscountedClients(r.message);
            })
            .catch((error) => {
                console.error(error.message);
                GetDiscountedCLients();
            });
    }


    const GetAllUsers = async () => {


        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getallusers", {

            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                setAllUsers(r.message)
            })
            .catch((error) => {
                console.error(error.message);
            });

    }

    const CheckAccountType = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getaccounttype", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();

                if (r.isPT)
                    setIsPT(true);
                if (r.isManager)
                    setIsManager(true);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const GetUnavailabilites = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getptunavailabilities", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (res) => {
                if (res.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let r = await res.json();
                setUnavails(r.message);

                await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getptoneoffunavailabilities", {
                    mode: "cors",
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "jwt": cookies.get("JWT")
                    }),
                    dataType: "text",
                    contentType: "text/plain"
                })
                    .then((response) => response.json())
                    .then((res) => {
                        let r = res.message;
                        setOneOffUnavails(r);
                    })
                    .catch((error) => {
                        console.error(error.message);
                    });
            })
            .catch((error) => {
                console.error(error.message);
            });

    }
    const FillCalendar = () => {

        //6am 1970/2/1
        const TimeOffset = 2664000000;
        let tempArray = [
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
            [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
        ];

        for (let i = 0; i < unavails.length; i++) {
            let unavail = unavails[i];
            let start = Date.parse(unavail.startT) - TimeOffset;
            let end = Date.parse(unavail.endT) - TimeOffset;
            //convert from milliseconds to 30 min increments ==== blocks in calendar
            start = start / 1800000
            end = end / 1800000

            //Start = block y starting position starting at 0
            //end = block y ending position starting at 0 

            const loopdeloop = (day) => {
                for (let j = start; j < end; j++) {
                    tempArray[day][j].push(i);
                }
            }
            if (unavail.sunday) {
                loopdeloop(0);
            }
            if (unavail.monday) {
                loopdeloop(1);
            }
            if (unavail.tuesday) {
                loopdeloop(2);
            }
            if (unavail.wednesday) {
                loopdeloop(3);
            }
            if (unavail.thursday) {
                loopdeloop(4);
            }
            if (unavail.friday) {
                loopdeloop(5);
            }
            if (unavail.saturday) {
                loopdeloop(6);
            }
        }
        setavail3d(tempArray);
    }
    const HandleWeekModalArrayChange = (day) => {
        let arr = [];
        weekModalArray.forEach((e) => arr.push(e));
        if (arr[day])
            arr[day] = 0;
        else
            arr[day] = 1;

        if (!availSent)
            setWeekModalArray(arr);
    }
    const HandleHoverIn = (day) => {
        let arr = [0, 0, 0, 0, 0, 0, 0];
        arr[day] = 1;
        if (!availSent)
            setModalHover(arr);
    }
    const HandleHoverOut = () => {
        let arr = [0, 0, 0, 0, 0, 0, 0];
        if (!availSent)
            setModalHover(arr);
    }

    const HandleStartTimeChange = (e) => {
        var coeff = 1000 * 60 * 30;
        var rounded = new Date(Math.round(e.getTime() / coeff) * coeff)
        setModalStartTime(rounded);
    }
    const HandleEndTimeChange = (e) => {
        var coeff = 1000 * 60 * 30;
        var rounded = new Date(Math.round(e.getTime() / coeff) * coeff)
        setModalEndTime(rounded);
    }
    const handleFNameChange = event => {
        setfName(event.target.value);
    };
    const handleLNameChange = event => {
        setlName(event.target.value);
    };
    const handleMobileChange = event => {
        setMobile(event.target.value.replace(/\D/, ''));
    };
    const handleP1Change = event => {
        setP1(event.target.value);
    };
    const handleP2Change = event => {
        setP2(event.target.value);
    };
    const handleMessageChange = event => {
        setMessage(event);
    };
    const handleMessageColourChange = event => {
        setMessageColour(event);
    };

    const handleDiscountClientInput = event => {
        setdiscountClientInput(event.target.value);
    };

    const handleDiscountClientAmountInput = event => {
        setDiscountClientAmountInput(event.target.value);
    };
    const handleSubmit = async event => {
        event.preventDefault();

        if (fName === "" || lName === "" || fName === null || lName === null) {
            handleMessageChange("Fields cannot be blank");
            return;
        }

        if (p1 !== p2) {
            handleMessageChange("Your passwords don't match.");
            return;
        }
        if (p1 !== "") {
            if (p1.length < 8) {
                handleMessageChange("Your password is too short.");
                return;
            }
            if (!/\d/.test(p1)) {
                handleMessageChange("Your password must contain at least one number.");
                return;
            }
            if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(p1)) {
                handleMessageChange("Your password must contain at least one special character (!@#$%^&).");
                return;
            }
        }

        setLoading(true);

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/updateaccountdetails", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "firstName": fName + "",
                "lastName": lName + "",
                "email": email + "",
                "password": p1 + "",
                "mobile": mobile + ""
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then(async (data) => {
                setLoading(false);
                if (data.message === "invalid signature") {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                }
                else if (data.message === "Updated Successfully") {
                    handleMessageColourChange(Colors.green);
                    handleMessageChange(data.message);
                }
                else {
                    handleMessageColourChange(Colors.orange);
                    handleMessageChange(data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error.message);
            });

    };

    if (cookies.get("JWT")) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: "column"
                }}
            >
                {
                    isPT ?
                        (
                            <div>
                                <Modal
                                    isOpen={availModalOpen}
                                    onRequestClose={() => {
                                        setAvailModalOpen(false)
                                        setavailSent(false);
                                    }}
                                    onAfterOpen={() => {
                                        setWeekModalArray([0, 0, 0, 0, 0, 0, 0]);
                                        setavailSent(false)
                                    }}
                                    style={modalStyle}
                                >
                                    <h2>New Unavailability</h2>

                                    <label>Select Day(s)</label>

                                    <div
                                        style={{
                                            height: 25,
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "90%",
                                                height: 50,
                                                justifyContent: "space-between",
                                                alignSelf: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[0] ? "lightgray" : modalHover[0] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(0)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(0);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(0)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 3,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >Su
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[1] ? "lightgray" : modalHover[1] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(1)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(1);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(1)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 6,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >M
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[2] ? "lightgray" : modalHover[2] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(2)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(2);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(2)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 3,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >Tu
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[3] ? "lightgray" : modalHover[3] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(3)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(3);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(3)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 6,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >W
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[4] ? "lightgray" : modalHover[4] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(4)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(4);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(4)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 3,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >Th
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[5] ? "lightgray" : modalHover[5] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(5)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(5);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(5)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 14,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >F
                                                </label>
                                            </div>

                                            <div
                                                style={{
                                                    outline: "2px solid #434343",
                                                    width: 50,
                                                    cursor: "pointer",
                                                    backgroundColor: weekModalArray[6] ? "lightgray" : modalHover[6] ? hoverGrey : "white",
                                                    overflow: "hidden"
                                                }}
                                                onClick={() => HandleWeekModalArrayChange(6)}
                                                onMouseOver={() => {
                                                    HandleHoverIn(6);
                                                }}
                                                onMouseOut={() => {
                                                    HandleHoverOut(6)
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "250%",
                                                        position: "relative",
                                                        left: 4,
                                                        top: -3,
                                                        cursor: "pointer",
                                                    }}
                                                >Sa
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                height: 25
                                            }}
                                        />
                                    </div>


                                    <label>Select Timeslot</label>

                                    <div
                                        style={{
                                            height: 25,
                                        }}
                                    />



                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: "space-between"
                                            }}
                                        >

                                            <KeyboardTimePicker
                                                label="From:"
                                                value={modalStartTime}
                                                onChange={HandleStartTimeChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: 20
                                                }}
                                            />
                                            <KeyboardTimePicker
                                                label="To:"
                                                value={modalEndTime}
                                                onChange={HandleEndTimeChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>

                                    <div
                                        style={{
                                            height: 10,
                                        }}
                                    />
                                    <label>{availSent ? "Availability Updated" : sentenceDays + sentenceTime}</label>
                                    <div
                                        style={{
                                            height: 25,
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse"
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                setAvailModalOpen(false);
                                            }}
                                        >
                                            {availSent ? "Return" : "Cancel"}
                                        </button>
                                        <div
                                            style={{
                                                width: 10,
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                SubmitUnavailability();
                                            }}
                                            disabled={availSent ? true : modalbutton}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </Modal>
                                <Modal
                                    isOpen={oneOffAvailModalOpen}
                                    onRequestClose={() => {
                                        setOneOffAvailModalOpen(false)
                                        setavailSent(false);
                                    }}
                                    onAfterOpen={() => {
                                        setavailSent(false)
                                    }}
                                    style={modalStyle}
                                >
                                    <h2>New One-Off Unavailability</h2>

                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            setDate(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        className="react-datepicker-ignore-onclickoutside"
                                    />
                                    <div style={{ height: 150 }} />

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: "space-between"
                                            }}
                                        >

                                            <KeyboardTimePicker
                                                label="From:"
                                                value={modalStartTime}
                                                onChange={HandleStartTimeChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: 20
                                                }}
                                            />
                                            <KeyboardTimePicker
                                                label="To:"
                                                value={modalEndTime}
                                                onChange={HandleEndTimeChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>

                                    <div
                                        style={{
                                            height: 10,
                                        }}
                                    />
                                    <label>{availSent ? "Availability Updated" : sentenceDays + sentenceTime}</label>
                                    <div
                                        style={{
                                            height: 25,
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse"
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                setOneOffAvailModalOpen(false);
                                            }}
                                        >
                                            {availSent ? "Return" : "Cancel"}
                                        </button>
                                        <div
                                            style={{
                                                width: 10,
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                SubmitOneOffUnavailability();
                                            }}
                                            disabled={availSent ? true : modalbutton}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </Modal>

                            </div>
                        )
                        :
                        (
                            null
                        )
                }
                <div
                    style={{
                        width: "100vw",
                        minHeight: "93vh",
                        position: "absolute",
                        backgroundColor: Colors.offBlack,
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: "center",
                        color: "white"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginTop: "25vh",
                            flexDirection: "column",
                        }}
                    >

                        <h1
                            style={{
                                fontWeight: 200,
                                fontFamily: "bebas",
                                fontSize: DesktopSizes.t1,
                                marginTop: 0
                            }}
                        >
                            Edit Account Details Below
                        </h1>
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{
                                color: Colors.offwhite,
                                fontFamily: "RobotoSlab",
                            }}
                        >
                            <label>Email: {email}</label>

                            <br />
                            <br />

                            <label>First Name: </label>
                            <br />
                            <input
                                required
                                value={fName}
                                onChange={handleFNameChange}
                                style={{
                                    width: "50%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.55)",
                                }}
                            />
                            <br />
                            <br />

                            <label>Last Name: </label>
                            <br />
                            <input
                                required
                                value={lName}
                                onChange={handleLNameChange}
                                style={{
                                    width: "50%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.55)",
                                }}
                            />

                            <br />
                            <br />
                            <label>Mobile no. </label>
                            <br />
                            <input
                                required
                                value={mobile}
                                onChange={handleMobileChange}
                                style={{
                                    width: "50%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.55)",
                                }}
                            />
                            <br />
                            <br />
                            <label>Password: </label>
                            <br />
                            <input
                                required
                                value={p1}
                                type="password"
                                onChange={handleP1Change}
                                style={{
                                    width: "50%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.55)",
                                }}
                            />
                            <br />
                            <br />
                            <label>Retype password: </label>
                            <br />
                            <input
                                required
                                value={p2}
                                type="password"
                                onChange={handleP2Change}
                                style={{
                                    width: "50%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.55)",
                                }}
                            />
                            <br />
                            <br />
                            <button
                                type="submit"
                                style={{ alignSelf: "center" }}
                            >
                                Save
                            </button>
                            <label
                                style={{
                                    color: messageColour,
                                    marginLeft: "1vw"
                                }}
                            >
                                {message}
                            </label>

                        </form>
                    </div>

                    {
                        isPT ?
                            (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: props.deviceType === "desktop" ? "row" : "column",
                                    }}
                                >

                                    <div>
                                        <h1 style={{ marginBottom: 0 }}>Recurring Unavailability</h1>
                                        <br />
                                        <button
                                            onClick={() => {
                                                setAvailModalOpen(true);
                                            }}
                                        >
                                            Add Recurring Unavailability
                                        </button>
                                        <div
                                            style={{
                                                width: props.deviceType === "desktop" ? "25vw" : "50vw",
                                                marginTop: 25
                                            }}
                                        >
                                            {
                                                unavails.length ? (
                                                    unavails.map((i, ii) =>
                                                    (
                                                        <>
                                                            <div
                                                                style={{
                                                                    outline: "2px solid #e6e6e6",
                                                                    alignSelf: "center",
                                                                    width: "90%",
                                                                    position: "relative",
                                                                    left: 15,
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    backgroundColor: (unavailsHover[ii] ? Colors.pink : "white"),
                                                                    color: (unavailsHover[ii] ? "#ffffff" : "black")
                                                                }}
                                                                onMouseOver={() => {
                                                                    let arr = [];
                                                                    unavailsHover.forEach((e) => arr.push(e));
                                                                    arr[ii] = 1;
                                                                    setUnavailsHover(arr);
                                                                    setcurrentHover(ii)
                                                                }}
                                                                onMouseOut={() => {
                                                                    let arr = [];
                                                                    unavailsHover.forEach((e) => arr.push(e));
                                                                    arr[ii] = 0;
                                                                    setUnavailsHover(arr);
                                                                    setcurrentHover("");
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: 240,
                                                                        position: 'relative',
                                                                        left: 10,
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 5,
                                                                        }}
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            fontWeight: 550,
                                                                        }}
                                                                    > {blockString(i)} </label>
                                                                    <br />
                                                                    <label>{timeString(i)} </label>
                                                                    <div
                                                                        style={{
                                                                            height: 5
                                                                        }}
                                                                    />
                                                                </div>

                                                                <img
                                                                    src={cross}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        width: 20,
                                                                        height: 20,
                                                                        alignSelf: "center",
                                                                        objectFit: "cover",
                                                                        position: 'relative',
                                                                        right: 10
                                                                    }}
                                                                    onClick={() => RemoveUnavail(i)}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    height: 10
                                                                }}
                                                            />
                                                        </>
                                                    ))
                                                ) : (null)
                                            }
                                        </div>


                                        <div>
                                            <br />
                                            <h1 style={{ marginBottom: 0 }}>One-Off Unavailability</h1>
                                            <br />
                                            <button
                                                onClick={() => {
                                                    setOneOffAvailModalOpen(true);
                                                }}
                                            >
                                                Add One-Off Unavailability
                                            </button>

                                            {
                                                oneOffUnavails.map((i, ii) =>
                                                (
                                                    <>
                                                        <div
                                                            style={{
                                                                outline: "2px solid #e6e6e6",
                                                                alignSelf: "center",
                                                                position: "relative",
                                                                display: "flex",
                                                                marginTop: 15,
                                                                left: 15,
                                                                width: props.deviceType === "desktop" ? "20vw" : "50vw",
                                                                justifyContent: "space-between",
                                                                backgroundColor: "white",
                                                                color: "black"
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'relative',
                                                                    left: 10,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 5,
                                                                    }}
                                                                />
                                                                <label>
                                                                    {i.date.slice(8, 10) + "/" + i.date.slice(5, 7) + "/" + i.date.slice(0, 4)}
                                                                </label>
                                                                <br />
                                                                <label>
                                                                    From {i.startT.slice(11, 16) + " to " + i.endT.slice(11, 16)}
                                                                </label>
                                                            </div>

                                                            <img
                                                                src={cross}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    width: 20,
                                                                    height: 20,
                                                                    alignSelf: "center",
                                                                    objectFit: "cover",
                                                                    position: 'relative',
                                                                    right: 10
                                                                }}
                                                                onClick={() => RemoveOneOffUnavail(i)}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                height: 10
                                                            }}
                                                        />
                                                    </>
                                                ))}

                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "5vh",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        {
                                            dayslotarray.map((i, ii) =>
                                            (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <>
                                                        <div
                                                            style={{
                                                                width: ii === 0 ? props.deviceType === "desktop" ? "6vw" : props.deviceType === "mobile" ? "18vw" : "10vw" : props.deviceType === "desktop" ? "6vw" : props.deviceType === "mobile" ? "11vw" : "10vw",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignSelf: "center",
                                                                flexDirection: "column",
                                                                justifyContent: "space-evenly"
                                                            }}
                                                        >
                                                            {
                                                                timeslotarray.map((j, jj) =>
                                                                (
                                                                    <>
                                                                        {
                                                                            (jj === 0) ?
                                                                                (
                                                                                    <div
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: 30,
                                                                                            outline: "2px solid #e6e6e6",
                                                                                            display: "flex",
                                                                                            justifyContent: "center"
                                                                                        }}
                                                                                    >
                                                                                        <label
                                                                                            style={{
                                                                                                fontWeight: "600",
                                                                                                alignSelf: "center"
                                                                                            }}
                                                                                        >
                                                                                            {props.deviceType === "mobile" ? mobiledayslotarray[ii] : dayslotarray[ii]}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                ((ii === 0) ?
                                                                                    (
                                                                                        <div
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                height: 30,
                                                                                                outline: "2px solid #e6e6e6",
                                                                                                display: "flex",
                                                                                                justifyContent: "center"
                                                                                            }}
                                                                                        >
                                                                                            <label
                                                                                                style={{
                                                                                                    fontWeight: "600",
                                                                                                    alignSelf: "center"
                                                                                                }}
                                                                                            >
                                                                                                {timeslotarray[jj]}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        (avail3d[ii - 1][jj - 1].length) ?
                                                                                            ((avail3d[ii - 1][jj - 1].includes(currentHover)) ?
                                                                                                (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                            height: 30,
                                                                                                            outline: "2px solid #e6e6e6",
                                                                                                            backgroundColor: Colors.pink
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                            height: 30,
                                                                                                            outline: "2px solid #e6e6e6",
                                                                                                            backgroundColor: Colors.orange
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                <div
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: 30,
                                                                                                        outline: "2px solid #e6e6e6",
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                    )
                                                                                )
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </>

                                                </div>

                                            ))
                                        }

                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }



                    {
                        isManager ?
                            (
                                <div
                                    style={{
                                        marginBottom: "5vh",
                                        width: "50vw"
                                    }}
                                >
                                    <h1 style={{ marginBottom: 0 }}>All Users</h1>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: "space-evenly",
                                            marginBottom: "1vh"
                                        }}
                                    >
                                        <label
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            First Name
                                        </label>
                                        <label
                                            style={{
                                                flex: 1
                                            }}
                                        >
                                            Last Name
                                        </label>
                                        <label
                                            style={{
                                                flex: 1
                                            }}
                                        >
                                            Email
                                        </label>
                                        <label
                                            style={{
                                                flex: 1
                                            }}
                                        >
                                            Mobile
                                        </label>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: "space-evenly"
                                        }}
                                    >
                                        <div
                                            style={{ flex: 1 }}
                                        >
                                            {
                                                allUsers.map((i, ii) =>
                                                (
                                                    <div>
                                                        <label>{i.firstName}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div
                                            style={{ flex: 1 }}
                                        >
                                            {
                                                allUsers.map((i, ii) =>
                                                (
                                                    <div>
                                                        <label>{i.lastName}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div
                                            style={{ flex: 1 }}
                                        >
                                            {
                                                allUsers.map((i, ii) =>
                                                (
                                                    <div>
                                                        <label>{i.email}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div
                                            style={{ flex: 1 }}
                                        >
                                            {
                                                allUsers.map((i, ii) =>
                                                (
                                                    <div>
                                                        <label>{i.mobile}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>

                                    <h1 style={{ marginBottom: 0 }}>Clients on discounted rate</h1>
                                    <br />
                                    <div
                                        style={{
                                            width: "50vw",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <input
                                            value={discountClientInput}
                                            onChange={handleDiscountClientInput}
                                            placeholder="Client's Email"
                                            style={{
                                                width: "50%",
                                                height: "3vh",
                                                fontSize: "2vh",
                                                border: "none",
                                                borderRadius: "10px",
                                                backgroundColor: "rgba(255, 255, 255, 0.55)",
                                            }}
                                        />
                                        <input
                                            value={discountClientAmountInput}
                                            onChange={event => setDiscountClientAmountInput(event.target.value.replace(/\D/, ''))}
                                            placeholder="Discounted price (0 for free)"
                                            style={{
                                                height: "3vh",
                                                fontSize: "2vh",
                                                border: "none",
                                                borderRadius: "10px",
                                                backgroundColor: "rgba(255, 255, 255, 0.55)",
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                addDiscountClient(true);
                                            }}
                                        >
                                            Add Discounted Cient
                                        </button>
                                    </div>
                                    <label style={{ color: Colors.orange }}>{discountClientError}</label>


                                    {
                                        discountedClients.map((i, ii) =>
                                        (
                                            <>
                                                <div
                                                    style={{
                                                        outline: "2px solid #e6e6e6",
                                                        alignSelf: "center",
                                                        position: "relative",
                                                        display: "flex",
                                                        marginTop: 15,
                                                        left: 15,
                                                        width: props.deviceType === "desktop" ? "20vw" : "50vw",
                                                        justifyContent: "space-between",
                                                        backgroundColor: "white",
                                                        color: "black"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            left: 10,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 5,
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <label style={{ fontWeight: "bold" }}>
                                                                {i.firstName + " " + i.lastName}
                                                            </label>
                                                            <label style={{ fontWeight: "bold" }}>
                                                                {i.rate === 0 ? " - Free" : " - $" + i.rate}
                                                            </label>

                                                        </div>
                                                        <label>
                                                            {i.email}
                                                        </label>
                                                    </div>

                                                    <img
                                                        src={cross}
                                                        style={{
                                                            cursor: "pointer",
                                                            width: 20,
                                                            height: 20,
                                                            alignSelf: "center",
                                                            objectFit: "cover",
                                                            position: 'relative',
                                                            right: 10
                                                        }}
                                                        onClick={() => RemoveDiscountClient(i)}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        height: 10
                                                    }}
                                                />
                                            </>
                                        ))
                                    }
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }
                </div>
            </div >
        );
    }
    else {
        return (<FourOFour />)
    }
}

export default Settings;