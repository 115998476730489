import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Colors, DesktopSizes } from "../../styles/colors.js"
import { deviceType } from 'react-device-detect';

const Login = (props) => {

    const history = useHistory();
    const cookies = new Cookies();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [message, setMessage] = useState('');
    const [messageColour, setMessageColour] = useState("");
    const [loaderVisible, setLoaderVisible] = useState(false);



    const [t1Size, setT1Size] = useState("7vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [pSize, setPSize] = useState("1.25vw");

    useEffect(() => {

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setT1Size("14vw");
            setT2Size("5vw");
            setPSize("3vw")
        }

    }, [props.deviceType]);

    useEffect(() => {
        setMessageColour(Colors.offwhite);
    }, []);

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };
    const handleRememberMeChange = event => {
        setRememberMe(event.target.checked);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (email === "" || password === "" || email === null || password === null) {
            setMessage("Please complete all fields.")
            return;
        }
        setMessage("")
        setLoaderVisible(true);

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/login", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email + "",
                "password": password + "",
                "rememberMe": rememberMe
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then(async (data) => {

                setLoaderVisible(false);

                if (data.token) {

                    setMessageColour(Colors.green);
                    setMessage(data.message)

                    let expiry = new Date();
                    expiry.setSeconds(expiry.getSeconds() + data.expires_in);
                    cookies.set("JWT", data.token, {
                        path: "/",
                        expires: expiry,
                        secure: true,
                        sameSite: "strict"
                    })
                    await new Promise(() => {
                        history.push("/");
                        window.location.reload();
                    }, 1000);
                }
                else {
                    setMessageColour(Colors.orange);
                    setMessage(data.message)
                }
            })
            .catch((error) => {
                setLoaderVisible(false);
                console.error(error.message);
            });
    };

    return (
        <form
            noValidate
            onSubmit={handleSubmit}
            style={{
                width: "100vw",
                height: "93vh",
                display: "flex",
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                color: Colors.offwhite,
                backgroundColor: Colors.offBlack,
            }}
        >
            <div

                style={{
                    width: "25%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontFamily: "RobotoSlab",
                }}
            >
                <h1
                    style={{
                        fontWeight: 200,
                        fontFamily: "bebas",
                        fontSize: t1Size,
                        marginTop: 0
                    }}
                >
                    Login
                </h1>
                {
                    props.deviceType === "desktop" ?
                        (
                            <input
                                required
                                autoFocus
                                value={email}
                                placeholder={"🤨 Email"}
                                onChange={handleEmailChange}
                                style={{

                                    width: "100%",
                                    height: "3vh",
                                    fontSize: "2vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.85)",
                                }}
                            />
                        )
                        :
                        (
                            <input
                                required
                                autoFocus
                                value={email}
                                placeholder={"🤨 Email"}
                                onChange={handleEmailChange}
                                style={{
                                    width: "200%",
                                    height: "5vh",
                                    fontSize: "3vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(255, 255, 255, 0.85)",
                                }}
                            />
                        )
                }
                {
                    props.deviceType === "desktop" ?
                        (
                            <input
                                required
                                value={password}
                                type="password"
                                placeholder={"🔑 Password"}
                                onChange={handlePasswordChange}
                                style={{
                                    width: "100%",
                                    height: "3vh",
                                    border: "none",
                                    fontSize: "2vh",
                                    borderRadius: "10px",
                                    marginTop: "2vh",
                                    marginBottom: "2vh",
                                    backgroundColor: "rgba(255, 255, 255, 0.85)",
                                }}
                            />
                        )
                        :
                        (
                            <input
                                required
                                value={password}
                                type="password"
                                placeholder={"🔑 Password"}
                                onChange={handlePasswordChange}
                                style={{
                                    width: "200%",
                                    height: "5vh",
                                    fontSize: "3vh",
                                    border: "none",
                                    borderRadius: "10px",
                                    marginTop: "5vh",
                                    marginBottom: "5vh",
                                    backgroundColor: "rgba(255, 255, 255, 0.85)",
                                }}
                            />
                        )
                }
                <div
                    style={{
                        width: props.deviceType === "desktop" ? "100%" : "200%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <input
                            value={rememberMe}
                            onChange={handleRememberMeChange}
                            style={{
                                marginLeft: 0
                            }}
                            type="checkbox"
                        />
                        <label
                            style={{
                                marginLeft: 0,
                                fontSize: pSize,
                            }}
                        >
                            Remember Me
                        </label>
                    </div>

                    <a
                        className={"loginToRegisterLink"}
                        href={"/resetpassword"}
                        style={{
                            textDecoration: "none",
                            textAlign: 'end',
                            fontSize: pSize,
                            color: Colors.offwhite,
                            alignSelf: "center"
                        }}
                    >
                        Forgotten Password?
                    </a>

                </div>

                <div
                    style={{
                        display: 'flex',
                        marginTop: "2.5vw",
                        alignItems: "center"
                    }}
                >
                    <button
                        type="submit"
                        className="btn draw-border-login"
                        style={{
                            fontFamily: "bebas",
                            fontSize: pSize
                        }}
                    >
                        Let's Go
                    </button>

                    <Loader
                        visible={loaderVisible}
                        color={Colors.pink}
                        width={"3vh"}
                        height={"3vh"}
                        type="TailSpin"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            left: "53.5vw",
                            position: "absolute",
                        }}
                    />
                    {
                        deviceType === 'mobile' ? <div /> :
                            <label
                                style={{
                                    width: "9vw",
                                    fontSize: "0.85vw",
                                    position: "absolute",
                                    left: "53.5vw",
                                    color: messageColour
                                }}
                            >
                                {message}
                            </label>
                    }

                </div>
                <a
                    className={"loginToRegisterLink"}
                    href={"/register"}
                    style={{
                        fontSize: pSize,
                        marginTop: "2.5vw",
                        textDecoration: "none",
                        color: Colors.offwhite,
                    }}
                >
                    Don't have an account yet? <span style={{ color: Colors.pink }}>Sign up here.</span>
                </a>


                {
                    deviceType !== 'mobile' ? <div /> :
                        <label
                            style={{
                                width: "80vw",
                                fontSize: "5vw",
                                textAlign:'center',
                                marginTop:'5vh',
                                color: messageColour
                            }}
                        >
                            {message}
                        </label>
                }

            </div>
        </form >
    );
}

export default Login;