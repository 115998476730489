export const Colors = {
    pink: "#ff65c3",
    lightPink: "#fda6dc",
    offwhite: "#f7fbf9",
    offBlack: "#181a19",
    darkerOffBlack: "#181a19",
    grey:"#888",
    orange:"#ff6576",
    green:"#c3ff65",
    blue:"#65c3ff"
}

export const DesktopSizes = {
    t1:"5vw",
    t2:"2.5vw",
    t3:"1.25vw",
    p1:"1.25vw",
    p2:"1vw"
}
export const TabletSizes = {
    t1:"",
    t2:"",
    t3:"",
    p1:"",
    p2:""
}
export const MobileSizes = {
    t1:"",
    t2:"",
    t3:"",
    p1:"",
    p2:""
}