import React, { useEffect } from 'react';
import { Colors } from '../../styles/colors';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import '../../styles/schedule.css';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal from 'react-modal';

require('react-big-calendar/lib/css/react-big-calendar.css');
moment.locale('en-AU');
const localizer = momentLocalizer(moment)


class CustomEvent extends React.Component {
    render(props) {
        return (
            <div
                style={{
                    pointerEvents: 'none',
                }}
            >
                <label
                    style={{
                        pointerEvents: 'none',
                        whiteSpace: "normal"
                    }}
                >
                    {this.props.event.title}
                </label>

            </div>
        );
    }
}


const Schedule = (props) => {


    let emailNames = [];
    const history = useHistory();
    const cookies = new Cookies();
    const [isPT, setIsPT] = React.useState();
    const [lessons, setLessons] = React.useState([]);
    const [groupLessons, setGroupLessons] = React.useState([]);
    const [formattedLessons, setFormattedLessons] = React.useState([]);
    const [selectedLesson, setSelectedLesson] = React.useState(1);
    const [selectedLessonName, setSelectedLessonName] = React.useState("");
    const [rescheduleModalOpen, setrescheduleModalOpen] = React.useState(false);
    const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
    const [modalStartTime, setModalStartTime] = React.useState(Date.now());
    const [date, setChosenDate] = React.useState(Date.now());

    useEffect(() => {
        Modal.setAppElement('body');
        CheckAccountType();
    }, []);

    React.useEffect(() => {
        GetSessions();
    }, [isPT]);

    React.useEffect(() => {
        FormatLessons();
    }, [lessons]);


    const CheckAccountType = async () => {

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getaccounttype", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (response) => {
                if (response.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                }
                else {
                    let r = await response.json();
                    if (r.isPT) {
                        setIsPT(true);
                    }
                    else {
                        setIsPT(false)
                    }
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const GetSessions = async () => {

        let groupsessions = [];
        let pts = [];

        if (!isPT) {
            await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getusergroupsessions", {
                mode: "cors",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "jwt": cookies.get("JWT")
                }),
                dataType: "text",
                contentType: "text/plain"
            })
                .then(async (r) => {

                    if (r.status === 404) {
                        cookies.remove("JWT", { path: '/' });
                        history.push("/")
                        history.go(0);
                        return;
                    }
                    let res = await r.json();
                    setGroupLessons(res.message);
                })
                .catch((error) => {
                    console.error(error.message);
                });
        }
        else {
            await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getallgroupsessions", {
                mode: "cors",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "jwt": cookies.get("JWT")
                }),
                dataType: "text",
                contentType: "text/plain"
            })
                .then(async (r) => {

                    if (r.status === 404) {
                        cookies.remove("JWT", { path: '/' });
                        history.push("/")
                        history.go(0);
                        return;
                    }
                    let res = await r.json();
                    setGroupLessons(res.message);
                })
                .catch((error) => {
                    console.error(error.message);
                });
        }

        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getusersessions", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (r) => {

                if (r.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let res = await r.json();
                setLessons(res.message);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
    const GetNameFromEmail = async (name) => {

        for (let i = 0; i < emailNames.length; i++) {
            if (emailNames[i].email == name) {
                return emailNames[i].name;
            }
        }

        let x = await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/users/getnamefromemail", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'name': name,
                'jwt': cookies.get("JWT")
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (r) => {
                if (r.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                let res = await r.json();
                emailNames.push({
                    email: name,
                    name: res.message.firstName + ' ' + res.message.lastName
                });

                return (res.message.firstName + ' ' + res.message.lastName)
            })
            .catch((error) => {
                console.error(error.message);
            });

        return x;
    }

    const FormatLessons = async () => {
        let arr = [];
        try {
            for (let i = 0; i < lessons.length; i++) {
                let l = lessons[i];

                let recipient = '';
                if (isPT)
                    recipient = l.customer
                else
                    recipient = l.trainer

                let r = await GetNameFromEmail(recipient);
                let calendarformattedDate = new Date(l.startDate.slice(0, 4), l.startDate.slice(5, 7), l.startDate.slice(8, 10), l.startTime.slice(0, 2), l.startTime.slice(3, 5));
                let startDate = new Date(calendarformattedDate.setMonth(calendarformattedDate.getMonth() - 1));
                let endDate = new Date(calendarformattedDate.setHours(calendarformattedDate.getHours() + 1));


                let title = "";
                if (l.lessonTier === 0 && l.nutritionConsult) {
                    title = "Free "
                }
                else if (l.lessonTier === 1) {
                    title = "Totally Boss - "
                }
                else if (l.lessonTier == 2) {
                    title = "Body Balancing - "
                }
                else if (l.lessonTier == 3) {
                    title = "Getting you started - "
                }
                if (l.online) {
                    if (l.nutritionConsult) {
                        title = title + "Virtual Nutrition Consult "
                    }
                    else if (l.initialConsult) {
                        title = title + "Initial Virtual Consult "
                    }
                    else {
                        title = title + "Virtual session "
                    }
                }
                else {
                    if (l.nutritionConsult) {
                        title = title + "Nutrition Consult "
                    }
                    else if (l.initialConsult) {
                        title = title + "Initial Consult "
                    }
                    else {
                        title = title + "PT "
                    }
                }

                if (l.noPT) {
                    let name = await GetNameFromEmail(l.customer);
                    if (isPT) {
                        title = "Solo Session w/ " + name + " - PAID"
                        if (l.payingCash) {
                            title = "Solo Session w/ " + name + " - $10 DUE"
                        }
                    }
                    else {
                        title = "Solo Session w/ " + name + " - No PT"
                        if (l.payingCash) {
                            title = "Solo Session w/ " + name + " - No PT, $10 due"
                        }
                    }
                }

                const sessionLength = l.nutritionConsult ? '' : l.isHalfHourSession ? ' (30 minutes)' : ' (60 minutes)';


                if (!l.noPT && !l.initialConsult && !l.nutritionConsult && l.lessonTier === null) {
                    title = "PT "
                }
                if (l.isPT || !l.noPT)
                    title = title + "w/ " + r + sessionLength

                let f = {
                    'id': l.sessionID,
                    "title": title,
                    'allDay': false,
                    'start': startDate,
                    'end': endDate,
                    'date': l.startDate,
                    "time": l.startTime,
                    "trainer": l.trainer,
                    'customer': l.customer,
                    'lessonTier': l.lessonTier,
                    'trainerName': await GetNameFromEmail(l.trainer),
                    'customerName': await GetNameFromEmail(l.customer)
                }
                let now = new Date(Date.now());
                now.setHours(now.getHours() - 24)
                if (f.start > now)
                    arr.push(f);
            }

            if (isPT) {

                let alreadyGottem = [];
                let temp = [];

                for (let i = 0; i < groupLessons.length; i++) {
                    let l = groupLessons[i];

                    const dateTime = {
                        date: l.date,
                        time: l.time
                    }

                    if (alreadyGottem.includes(dateTime)) {

                        for (let i = 0; i < temp.length; i++) {
                            if (temp[i].date === l.date) {
                                temp[i].clients.push(l.payingCash ? await GetNameFromEmail(l.email) + " ($10 DUE)" : await GetNameFromEmail(l.email) + " (Paid)");
                            }
                        }
                    }
                    else {
                        alreadyGottem.push(dateTime)

                        let calendarformattedDate = new Date(l.date.slice(0, 4), l.date.slice(5, 7), l.date.slice(8, 10), new Date(l.date).getDay() === 5 ? "16" : "07", new Date(l.date).getDay() === 5 ? "30" : "00");
                        let startDate = new Date(calendarformattedDate.setMonth(calendarformattedDate.getMonth() - 1));
                        let endDate = new Date(calendarformattedDate.setHours(calendarformattedDate.getHours() + 1));
                        let title = 'Group Bootcamp';

                        if (l.time) {
                            if (l.time === 17) {
                                l.time = '5:00pm';
                            } else if (l.time === 17.5) {
                                l.time = '5:30pm';
                            }
                        } else {
                            l.time = '6:00am';
                        }

                        let f = {
                            "title": title,
                            'allDay': false,
                            'start': startDate,
                            'end': endDate,
                            'date': l.date,
                            "time": l.time,
                            "clients": l.payingCash ? [await GetNameFromEmail(l.email) + " ($10 DUE)"] : [await GetNameFromEmail(l.email) + " (Paid)"]
                        };

                        let now = new Date(Date.now());
                        now.setHours(now.getHours() - 24)
                        if (f.start > now)
                            temp.push(f);
                    }
                }


                temp.forEach(element => {
                    arr.push(element)
                });
            }
            else {

                for (let i = 0; i < groupLessons.length; i++) {
                    let l = groupLessons[i];

                    let calendarformattedDate = new Date(l.date.slice(0, 4), l.date.slice(5, 7), l.date.slice(8, 10), new Date(l.date).getDay() === 5 ? "16" : "07", new Date(l.date).getDay() === 5 ? "30" : "00");
                    let startDate = new Date(calendarformattedDate.setMonth(calendarformattedDate.getMonth() - 1));
                    let endDate = new Date(calendarformattedDate.setHours(calendarformattedDate.getHours() + 1));

                    let title = 'Group Bootcamp';
                    if (l.payingCash) {
                        title = 'Group Bootcamp ($10 due)';
                    }

                    if (l.time) {
                        if (l.time === 17) {
                            l.time = '5:00pm';
                        } else if (l.time === 17.5) {
                            l.time = '5:30pm';
                        }
                    } else {
                        l.time = '6:00am';
                    }


                    let f = {
                        "title": title,
                        'allDay': false,
                        'start': startDate,
                        'end': endDate,
                        'date': l.date,
                        "time": l.time
                    }

                    let now = new Date(Date.now());
                    now.setHours(now.getHours() - 24)
                    if (f.start > now)
                        arr.push(f);
                }
            }

            arr.sort(function (a, b) {
                return a.start - b.start
            })
            setFormattedLessons(arr);
        } catch (error) {
            console.error(error)
        }
    }




    const HandleReschedule = async (e) => {
        setSelectedLesson(e);
        setrescheduleModalOpen(true);
        setSelectedLessonName(await GetNameFromEmail(e.customer));
    }
    const HandleCancel = async (e) => {
        setSelectedLesson(e);
        setCancelModalOpen(true);
        setSelectedLessonName(await GetNameFromEmail(e.customer));
    }
    const HandleStartTimeChange = (e) => {
        var coeff = 1000 * 60 * 30;
        var rounded = new Date(Math.round(e.getTime() / coeff) * coeff)
        setModalStartTime(rounded);
    }
    const RescheduleLesson = async () => {


        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/reschedule", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "sessionID": selectedLesson.id,
                "oldDate": selectedLesson.date,
                "oldTime": selectedLesson.time,
                "newDate": date,
                "newTime": modalStartTime,
                "clientName": selectedLessonName,
                "clientEmail": selectedLesson.customer
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then((response) => response.json())
            .then((r) => {

                if (r.message === "invalid signature") {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                }
                GetSessions();
            })
            .catch((error) => {
                console.error(error.message);
            });


    }

    const CancelLesson = async () => {


        await fetch("https://" + process.env.REACT_APP_SERVER_IP + "/sessions/cancel", {
            mode: "cors",
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jwt": cookies.get("JWT"),
                "sessionID": selectedLesson.id,
                "oldDate": selectedLesson.date,
                "oldTime": selectedLesson.time,
                "clientName": selectedLessonName,
                "clientEmail": selectedLesson.customer
            }),
            dataType: "text",
            contentType: "text/plain"
        })
            .then(async (r) => {

                if (r.status === 404) {
                    cookies.remove("JWT", { path: '/' });
                    history.push("/")
                    history.go(0);
                    return;
                }
                GetSessions();
            })
            .catch((error) => {
                console.error(error.message);
            });


    }



    return (
        <div
            style={{
                width: '100vw',
                minHeight: '93vh',
                backgroundColor: Colors.offBlack,
                color: Colors.offwhite,
                display: 'flex',
                flexDirection: props.deviceType === "mobile" ? "column" : "row",
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >

            {
                isPT ?
                    (
                        <div>

                            <Modal
                                isOpen={rescheduleModalOpen}
                                onRequestClose={() => setrescheduleModalOpen(false)}
                                style={{
                                    content: {
                                        width: props.deviceType == "desktop" ? "40vw" : "75vw",
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                    }
                                }}
                            >
                                <h2>Reschedule lesson with {selectedLessonName}</h2>
                                <label style={{ fontWeight: "bold" }}>Original Date / time</label>
                                {
                                    (selectedLesson !== 1) ?
                                        (
                                            <div>
                                                <label>
                                                    {selectedLesson.date.slice(8, 10) + "/" + selectedLesson.date.slice(5, 7) + "/" + selectedLesson.date.slice(0, 4)}
                                                </label>
                                                <br />
                                                <label> {selectedLesson.time.slice(0, 5)} </label>
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                                <p style={{ fontWeight: "bold" }}>New date / time</p>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        label="Start:"
                                        value={modalStartTime}
                                        onChange={HandleStartTimeChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => {
                                        setChosenDate(date);
                                    }}
                                    style={{
                                        width: "50%"
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="react-datepicker-ignore-onclickoutside"
                                />
                                <p style={{ fontWeight: "bold" }}>{selectedLessonName} will receive an email immediately, informing them of the new time.</p>

                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: "1vh"
                                    }}
                                >
                                    <button onClick={() => RescheduleLesson()}>Confirm</button>
                                    <button onClick={() => setrescheduleModalOpen(false)}>Cancel</button>

                                </div>
                            </Modal>

                            <Modal
                                isOpen={cancelModalOpen}
                                onRequestClose={() => setCancelModalOpen(false)}
                                style={{
                                    content: {
                                        width: props.deviceType == "desktop" ? "40vw" : "75vw",
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                    }
                                }}
                            >
                                <h2>Cancel lesson with {selectedLessonName}</h2>

                                <label style={{ fontWeight: "bold" }}>Date / time</label>
                                {
                                    (selectedLesson !== 1) ?
                                        (
                                            <div>
                                                <label>
                                                    {selectedLesson.date.slice(8, 10) + "/" + selectedLesson.date.slice(5, 7) + "/" + selectedLesson.date.slice(0, 4)}
                                                </label>
                                                <br />
                                                <label> {selectedLesson.time.slice(0, 5)} </label>
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                                <p style={{ fontWeight: "bold" }}>{selectedLessonName} will receive an email informing them of the cancellation immediately. Careful! Cancelling an appointment cannot be undone.</p>

                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: "1vh",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <button onClick={() => CancelLesson()}>Cancel Session</button>
                                    <button onClick={() => setCancelModalOpen(false)}>Back</button>

                                </div>
                            </Modal>

                        </div>
                    )
                    :
                    (
                        null
                    )
            }


            <div
                style={{
                    outline: "2px solid #7a7a7a",
                    height: '67vh',
                    width: props.deviceType === "mobile" ? "60vw" : '20vw',
                    marginTop: '5vh'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        height: 50,
                        backgroundColor: "#7a7a7a"
                    }}
                >
                    <label
                        style={{
                            alignSelf: 'center',
                            fontWeight: 600,
                            fontSize: "140%",
                            position: "relative",
                            left: 10
                        }}
                    >
                        Upcoming Sessions
                    </label>
                </div>
                <div
                    style={{
                        height: "calc(67vh - 50px)",
                        overflow: "auto",
                    }}
                >
                    <div>
                        <div
                            style={{
                                height: 15,
                            }}
                        />
                        {
                            formattedLessons.length ?
                                (
                                    formattedLessons.map((i, ii) =>
                                    (
                                        <>
                                            <div
                                                style={{
                                                    outline: "2px solid #e6e6e6",
                                                    alignSelf: "center",
                                                    width: "90%",
                                                    position: "relative",
                                                    left: 15,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 240,
                                                        position: 'relative',
                                                        left: 10,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: 5,
                                                        }}
                                                    />
                                                    <label
                                                        style={{
                                                            fontWeight: 550,
                                                        }}
                                                    >
                                                        {i.title}
                                                    </label>
                                                    <br />
                                                    <label>
                                                        {i.date.slice(8, 10) + "/" + i.date.slice(5, 7) + "/" + i.date.slice(0, 4)}
                                                    </label>
                                                    <br />
                                                    <label> {i.time} </label>
                                                    {
                                                        isPT ?
                                                            (
                                                                i.title !== "Group Bootcamp" ?
                                                                    (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                marginBottom: "1vh",
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        >
                                                                            <button
                                                                                onClick={() => HandleReschedule(i)}
                                                                            >
                                                                                Reschedule
                                                                            </button>
                                                                            <button
                                                                                onClick={() => HandleCancel(i)}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        i.clients.map((i, ii) =>
                                                                        (
                                                                            <div
                                                                                style={{
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{ fontWeight: "bold" }}
                                                                                >
                                                                                    {i}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                null
                                                            )
                                                    }

                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    height: 10
                                                }}
                                            />
                                        </>
                                    ))
                                )
                                :
                                (
                                    null
                                )
                        }
                    </div>
                </div>
            </div>

            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                popup={true}
                events={formattedLessons}
                components={{
                    event: CustomEvent,
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                    return {
                        className: "rbc-event-content",

                    }
                }}
                style={{
                    alignSelf: 'center',
                    height: '67vh',
                    width: props.deviceType === "mobile" ? "90vw" : '60vw',
                    marginLeft: '5vw',
                    marginTop: '5vh'
                }}
            />

        </div >
    )
}

export default Schedule;