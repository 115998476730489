import React, { useEffect, useState } from 'react';
import { Colors } from '../styles/colors.js';
import Fade from 'react-reveal/Fade';
import ReactPlayer from 'react-player/lazy';
import vidThumb from "../assets/images/gymTour/thumb.jpg";
import { Fade as SlideFade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import slide1 from "../assets/images/gymTour/1.jpg";
import slide2 from "../assets/images/gymTour/2.jpg";
import slide3 from "../assets/images/gymTour/3.jpg";
import slide4 from "../assets/images/gymTour/4.jpg";
import slide5 from "../assets/images/gymTour/5.jpg";


const Gym = (props) => {

    const [t1Size, setT1Size] = useState("5vw");
    const [t2Size, setT2Size] = useState("2.5vw");
    const [pSize, setPSize] = useState("1.25vw");
    const [topOffset, setTopOffset] = useState("20vh");

    useEffect(() => {

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setT1Size("12vw");
            setT2Size("5vw");
            setPSize("3vw")
            setTopOffset("12vh")
        }

    }, [props.deviceType]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
                width: "100vw",
                minHeight: "93vh",
                backgroundColor: Colors.offBlack,
                color: Colors.offwhite,
                alignItems: "center",
                fontFamily: "bebas"
            }}
        >
            <div
                style={{
                    width: "75vw",
                    marginTop: topOffset,
                    display: "flex",
                    flexDirection: props.deviceType === "desktop" ? "row" : "column"
                }}
            >

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Fade left>
                        <h1
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: t1Size,
                                fontWeight: 200,
                            }}
                        >
                            Our Facility
                        </h1>
                    </Fade>

                    <Fade left>
                        <p style={{ fontFamily: "RobotoSlab", fontSize: pSize, }}>
                            Our boutique gym located north of Brisbane is fitted with commercial grade cardio equipment, strength training machines and the latest technology in body composition scanning. The training centre is perfect for 1:1 training sessions and small groups. Sound like something you're interested in? Book your free consultation and gym tour today using the form on our homepage.
                        </p>
                    </Fade>

                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >

                    <ReactPlayer
                        width={props.deviceType === "desktop" ? "40vw" : "100%"}
                        height={props.deviceType === "desktop" ? "22.5vw" : "calc(0.5625 * 75vw)"}
                        light={vidThumb}
                        controls={true}
                        playing={true}
                        fallback={vidThumb}
                        url='https://youtu.be/Of77QIk68-o'
                        style={{
                            color: Colors.offBlack
                        }}

                    />
                </div>

            </div>

            <div
                style={{
                    width: "100%",
                    height: "25vw",
                    marginTop: "5vh",
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center"
                }}
            >
                <SlideFade
                    pauseOnHover={false}
                    arrows={false}
                    autoplay={true}
                    duration={5000}
                    style={{
                        width: "6.125vw",
                        height: "6.125vw",
                        marginLeft: "1vw",
                        marginRight: "1vw"
                    }}
                >
                    <SlideImage img={slide4} />
                    <SlideImage img={slide5} />
                    <SlideImage img={slide1} />
                    <SlideImage img={slide2} />
                    <SlideImage img={slide3} />
                </SlideFade>
                <SlideFade
                    pauseOnHover={false}
                    arrows={false}
                    autoplay={true}
                    duration={5000}
                    style={{
                        width: "12.25vw",
                        height: "12.25vw",
                        marginLeft: "1vw",
                        marginRight: "1vw"
                    }}
                >
                    <SlideImage img={slide5} />
                    <SlideImage img={slide1} />
                    <SlideImage img={slide2} />
                    <SlideImage img={slide3} />
                    <SlideImage img={slide4} />
                </SlideFade>
                <SlideFade
                    pauseOnHover={false}
                    arrows={false}
                    autoplay={true}
                    duration={5000}
                    style={{
                        width: "25vw",
                        height: "25vw",
                        marginLeft: "1vw",
                        marginRight: "1vw"
                    }}
                >
                    <SlideImage img={slide1} />
                    <SlideImage img={slide2} />
                    <SlideImage img={slide3} />
                    <SlideImage img={slide4} />
                    <SlideImage img={slide5} />
                </SlideFade>
                <SlideFade
                    pauseOnHover={false}
                    arrows={false}
                    autoplay={true}
                    duration={5000}
                    style={{
                        width: "12.25vw",
                        height: "12.25vw",
                        marginLeft: "1vw",
                        marginRight: "1vw"
                    }}
                >
                    <SlideImage img={slide2} />
                    <SlideImage img={slide3} />
                    <SlideImage img={slide4} />
                    <SlideImage img={slide5} />
                    <SlideImage img={slide1} />
                </SlideFade>
                <SlideFade
                    pauseOnHover={false}
                    arrows={false}
                    autoplay={true}
                    duration={5000}
                    style={{
                        width: "6.125vw",
                        height: "6.125vw",
                        marginLeft: "1vw",
                        marginRight: "1vw"
                    }}
                >
                    <SlideImage img={slide3} />
                    <SlideImage img={slide4} />
                    <SlideImage img={slide5} />
                    <SlideImage img={slide1} />
                    <SlideImage img={slide2} />
                </SlideFade>
            </div>

        </div>
    );
}

const SlideImage = (props) => {

    return (
        <div
            className={"gymphotofade"}
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <div
                style={{
                    width: "95%",
                    height: "95%",
                    backgroundColor: Colors.pink,
                }}
            >
                <img
                    src={props.img}
                    alt={"SlideImage"}
                    style={{
                        position: "relative",
                        top: "5%",
                        left: "5%",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                />
            </div>
        </div>
    )
}

export default Gym;