import React from 'react';
import { Colors } from '../styles/colors';

const FourOFour = () => {
    return (
        <div
            style={{
                width: '100vw',
                height: '93vh',
                backgroundColor: Colors.offBlack,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'bebas',
                fontWeight: 200
            }}
        >
            <a
                href='/'
                style={{
                    color: Colors.offwhite,
                    textDecoration:'none',
                    fontSize:'3vw'
                }}
            >
                <h1>Home</h1>
            </a>

        </div>
    );
}

export default FourOFour;