import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// import { commerce } from './lib/commerce';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { deviceType, isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { Helmet } from "react-helmet";

import { Header } from "./components/header.js";
import { Footer } from "./components/footer.js";

import Home from './pages/home.js';
import FourOFour from './pages/404.js';
import About from './pages/about.js';
import Gym from './pages/gym.js';
import Training from './pages/training.js';
import Blog from './pages/blog.js';
import BlogArticle from './pages/blogArticle.js';
import Social from './pages/social.js';
import Login from './pages/account/login.js';
import Register from './pages/account/register.js';
import ResetPassword from "./pages/account/resetPassword"
import Settings from './pages/account/settings.js';
import Schedule from './pages/account/schedule.js';
import Challenge from './pages/2023/2023Challenge.js';
import BossSelf from './pages/2023/bossSelf.js';
import BossSelfCheckOut from './pages/2023/checkout.js';

import './index.css';


const App = () => {

    const [deviceType, setDeviceType] = useState();
    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        if (isDesktop) {
            setDeviceType("desktop");
        }
        else if (isTablet) {
            setDeviceType("tablet");
        }
        else if (isMobileOnly) {
            setDeviceType("mobile");
        }
        else {
            setDeviceType("desktop");
        }
        setCurrentPage(window.location.pathname)
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
            }}
        >
            <Helmet>
                <html lang='en' />
                <meta
                    charSet="utf-8"
                    name="description"
                    content="Homepage of the Boss Squad Training"
                />
                <title>Boss Squad Training</title>
                <link href="http://bosssquadtraining.com" />

                <script>
                    {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '221465067396004');
fbq('track', 'PageView');`}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=221465067396004&ev=PageView&noscript=1"/>`}
                </noscript>
            </Helmet>



            {
                (deviceType === "mobile" || deviceType === "tablet") ?
                    (
                        <Header
                            device={deviceType}
                        />
                    )
                    :
                    (
                        currentPage === "/" ?
                            (
                                null
                            )
                            :
                            (
                                <Header
                                    device={deviceType}
                                />
                            )
                    )
            }



            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "93vh"
                    }}
                >
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                render={() =>
                                (
                                    <Home
                                        deviceType={deviceType}
                                    />
                                )}
                            />
                            <Route path="/about" exact render={() => (<About deviceType={deviceType} />)} />
                            <Route path="/gym" exact render={() => (<Gym deviceType={deviceType} />)} />
                            <Route path="/training" exact render={() => (<Training deviceType={deviceType} />)} />
                            <Route path="/social" exact render={() => (<Social deviceType={deviceType} />)} />
                            <Route path="/blog" exact render={() => (<Blog deviceType={deviceType} />)} />
                            <Route path="/blog/:id" component={Article} />
                            {/* <Route path="/bubandme" exact render={() => (<Challenge deviceType={deviceType} />)} /> */}
                            {/* <Route path="/boss-self-challenge" exact render={() => (<BossSelf deviceType={deviceType} />)} /> */}
                            {/* <Route path="/boss-self-challenge/checkout" render={() => (<BossSelfCheckOut deviceType={deviceType} />)} /> */}
                            <Route path="/login" exact render={() => (<Login deviceType={deviceType} />)} />
                            <Route path="/register" exact render={() => (<Register deviceType={deviceType} />)} />
                            <Route path="/resetpassword" exact render={() => (<ResetPassword deviceType={deviceType} />)} />
                            <Route path="/account/settings" exact render={() => (<Settings deviceType={deviceType} />)} />
                            <Route path="/account/schedule" exact render={() => (<Schedule deviceType={deviceType} />)} />
                            {/* <Route path="/packages" render={() => (<SunnySignUp deviceType={deviceType}/>)} /> */}
                            <Route path="/" render={() => (<FourOFour />)} />
                        </Switch>
                    </BrowserRouter>
                </div>
                <Footer deviceType={deviceType} />
            </div>
        </div>
    )
}

const Article = (props) => {
    return (
        <BlogArticle deviceType={deviceType} match={props.match} />
    )
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: http://bit.ly/CRA-vitals
reportWebVitals();
