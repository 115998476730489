import React, { Component } from "react";
import axios from "axios";
import { Colors } from '../styles/colors';


class BlogArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: {}
        };
    }

    removeUnicode(string) {
        if (string.indexOf("&#8217;") >= 0) {
            return this.removeUnicode(string.replace("&#8217;", "'"));
        } else {
            return string;
        }
    }
    componentDidMount() {


        axios
            .get(
                process.env.REACT_APP_BLOG_URL + "posts/" + this.props.match.params.id
            )
            .then(res => {
                this.setState({ post: res.data });
                const container = document.querySelector(".content");
                let scr = container.querySelectorAll("script");
                scr.forEach(node => {
                    let parent = node.parentElement;
                    let d = document.createElement("script");
                    d.async = node.async;
                    d.src = node.src;
                    d.type = "text/javascript";
                    parent.insertBefore(d, node);
                    parent.removeChild(node);
                });
            })
            .catch(error => console.log(error));
    }

    render() {
        const date = this.state.post.date + "";
        const arr = date.split("-");
        const formattedDate = date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)

        if (this.state.post) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: "column",
                        backgroundColor: Colors.offBlack,
                        width: "100%",
                        minHeight: "93vh",
                        color: Colors.offwhite,
                        alignSelf: 'center',
                        fontFamily: "RobotSlab"
                    }}
                >
                    <div
                        style={{
                            marginTop: this.props.deviceType === "browser" ? "20vh" : "15vh",
                            display: "flex",
                            flexDirection: "column",
                            width: this.props.deviceType === "browser" ? "50vw" : "75vw",
                            alignSelf: "center",
                        }}
                    >
                        {
                            this.state.post.featured_image ?
                                (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "20vw",
                                            marginBottom: "1vh",
                                            backgroundColor: "green"
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            className="img-responsive webpic"
                                            alt="article header"
                                            src={this.state.post.featured_image}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                        {
                            this.state.post.title ?
                                (
                                    <h2
                                        style={{
                                            fontFamily: "bebas",
                                            fontSize: this.props.deviceType === "browser" ? "2vw" : this.props.deviceType === "tablet" ? "5vw" : "8vw",
                                            fontWeight: 200,
                                            marginBottom: 0,
                                            marginTop: 0
                                        }}
                                    >
                                        {this.removeUnicode(this.state.post.title)}
                                    </h2>
                                )
                                :
                                (
                                    null
                                )
                        }
                        <p
                            style={{
                                fontFamily: "bebas",
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: this.props.deviceType === "browser" ? "1.5vw" : this.props.deviceType === "tablet" ? "3vw" : "4vw",
                            }}
                        >
                            {formattedDate}
                        </p>

                        <div
                            className="content"
                            dangerouslySetInnerHTML={{ __html: this.state.post.content }}
                            style={{
                                fontSize: this.props.deviceType === "browser" ? "1vw" : this.props.deviceType === "tablet" ? "2vw" : "3vw",
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default BlogArticle;