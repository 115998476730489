
import { Colors } from '../styles/colors';
import React, { useState, useEffect, useRef, useCallback } from 'react';

export const Footer = (props) => {

    const [pSize, setPSize] = useState("0.75vw");
    const [deviceType, setDeviceType] = useState();


    useEffect(() => {
        setDeviceType(props.deviceType)

        const d = props.deviceType;

        if (d === "mobile" || d === "tablet") {
            setPSize("3vw")
        }

    }, [props.deviceType]);

    return (
        <div
            style={{
                zIndex: 100,
                height: "7vh",
                width: "100vw",
                display: "flex",
                color: Colors.grey,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.offBlack,
                marginTop: "auto"
            }}
        >
            <p >© Boss Squad Training 2021. All Rights Reserved.</p>
        </div>
    )
}